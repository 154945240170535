import React,{useState, useEffect} from 'react';
import { withRouter, Link } from 'react-router-dom';
import {getPendingAppointmentsCount} from '../../Actions/User.action';
import { appSocket } from '_Constants/socket.js';
import {toUTC} from '../../../_Constants/app.constants';
import moment from 'moment';

const SideMenu = ({history, userDetailObj}) => {
    //console.log(localStorage);
    const [penddingCount, setPenddingCount] = useState(0);

    {(!localStorage.getItem("jwtToken")) && (!localStorage.getItem("RecoverWebStatus")) &&
        history.push('/');
    }
    let pathname = history.location && history.location.pathname?history.location.pathname:'';

    const PendingAppointmentsCount=async()=>{	
        let datetime = toUTC(moment().format('YYYY-MM-DD HH:mm:ss'));
        let data = await getPendingAppointmentsCount({datetime:datetime});        
        setPenddingCount(data.count);	
        sessionStorage.penddingCount = data.count;
  	}
    
    useEffect(()=>{
        PendingAppointmentsCount();
        document.documentElement.scrollTop = 0;
       
        appSocket.socket.emit('addUser', {userId:localStorage.getItem('_id'), receiveId:0, 'status':'addUser'});

    },[]);
    
    //sockets
    /*appSocket.socket.on('session', function(data){
        console.log(data);
        if(data.status === 'pendingCount'){
            PendingAppointmentsCount();
        }
    });*/
    
    return (
		<div>
            <div className="sec-left-dash"> 
                <h4 className="user_name"><i className="fa fa-user"></i>
    <span>{localStorage.getItem("fullName")?localStorage.getItem("fullName"):''}</span>
                </h4>
                {localStorage.getItem("userType")==='PATIENT' &&
                    <ul>
                        <li className={pathname==='/profile'?'activeclas':''}>
                            <i className="fa fa-user" aria-hidden="true"></i><Link to="/profile">My Account Information</Link>
                        </li>
                        <li className={pathname==='/calendar'?'activeclas':''}>
                            <i className="fa fa-calendar" aria-hidden="true"></i><Link to="/calendar">Book An Appointment</Link>
                        </li>
                        <li className={pathname==='/pt-list'?'activeclas':''}>
                            <i className="fa fa-search" aria-hidden="true"></i><Link to="/pt-list">Search For Physical Therapist</Link>
                        </li>
                        <li className={pathname==='/future-appointments'?'activeclas':''}>
                            <i className="fa fa-user-md" aria-hidden="true"></i><Link to="/future-appointments">Future Appointments<span className="badge">{sessionStorage.penddingCount}</span></Link>
                        </li>
                        <li className={pathname==='/appointment-history'?'activeclas':''}>
                            <i className="fas fa-notes-medical" aria-hidden="true"></i><Link to="/appointment-history">Appointment/Payment History</Link>
                        </li>
                    </ul>
                } 
            	
                {localStorage.getItem("userType")==='PT' &&
                    <ul>
                        <li className={pathname==='/profile'?'activeclas':''}>
                            <i className="fa fa-user" aria-hidden="true"></i><Link to="/profile">My Account Information</Link>
                        </li>     
                        {localStorage.getItem("clinicId") &&                  
                            <li className={pathname==='/account-create'?'activeclas':''}>
                                <i className="fas fa-money-check-alt"></i><Link to="/account-create">Stripe Direct Deposit</Link>
                            </li>
                        }
                        <li className={pathname==='/calendar'?'activeclas':''}>
                            <i className="fa fa-calendar" aria-hidden="true"></i><Link to="/calendar">Calendar</Link>
                        </li>
                        <li className={pathname==='/my-availability'?'activeclas':''}>
                            <i className="fa fa-search" aria-hidden="true"></i><Link to="/my-availability">My Availability</Link>
                        </li>
                        <li className={pathname==='/future-appointments'?'activeclas':''}>
                            <i className="fa fa-user-md" aria-hidden="true"></i><Link to="/future-appointments">Future Appointments<span className="badge">{sessionStorage.penddingCount?sessionStorage.penddingCount:0}</span></Link>                            
                        </li>
                        <li className={pathname==='/appointment-history'?'activeclas':''}>
                            <i className="fas fa-notes-medical" aria-hidden="true"></i><Link to="/appointment-history">Appointment History</Link>
                        </li>
                        {localStorage.getItem("clinicId") &&
                            <li className={pathname==='/payment-history'?'activeclas':''}>
                                <i className="fa fa-credit-card" aria-hidden="true"></i><Link to="/payment-history">Payment History</Link>
                            </li>
                        }
                    </ul>
                } 

                {localStorage.getItem("userType")==='CLINIC' &&
                    <ul>
                        <li className={pathname==='/profile'?'activeclas':''}>
                            <i className="fa fa-user" aria-hidden="true"></i><Link to="/profile">My Account Information</Link>
                        </li>                           
                        <li className={pathname==='/account-create'?'activeclas':''}>
                            <i className="fas fa-money-check-alt"></i><Link to="/account-create">Stripe Direct Deposit</Link>
                        </li>                      
                        <li className={pathname==='/add-clinic-pt'?'activeclas':''}>
                            <i className="fa fa-plus" aria-hidden="true"></i><Link to="/add-clinic-pt">Create a PT account</Link>
                        </li>                        
                        <li className={pathname==='/clinic-pt'?'activeclas':''}>
                            <i className="fa fa-list" aria-hidden="true"></i><Link to="/clinic-pt">List of PT</Link>                     
                        </li>                        
                        <li className={pathname==='/future-appointments'?'activeclas':''}>
                            <i className="fa fa-user-md" aria-hidden="true"></i><Link to="/future-appointments">Future Appointments<span className="badge">{sessionStorage.penddingCount?sessionStorage.penddingCount:0}</span></Link>              
                        </li>                        
                        <li className={pathname==='/appointment-history'?'activeclas':''}>
                            <i className="fas fa-notes-medical" aria-hidden="true"></i><Link to="/appointment-history">Appointment History For CPT</Link>
                        </li>
                        <li className={pathname==='/payment-history'?'activeclas':''}>
                            <i className="fa fa-credit-card" aria-hidden="true"></i><Link to="/payment-history">Payment History</Link>
                        </li>
                    </ul>
                }
            </div>
	   	</div>
    );
}
export default withRouter(SideMenu);
