import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize, Translate } from 'react-localize-redux';
import SideMenu from '../DashboardPage/SideMenu';
import {Modal, ModalHeader, ModalBody, Card, CardBody, Col,Row ,Label, Form, Input, Button, FormGroup, Table } from 'reactstrap';
//import moment from 'moment';
import {availabilitySaveAction, getAvailabilityList, deleteAvailabilityAction} from '../../Actions/User.action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {appConstants, toUTC, getLocalDate, setTimeZone} from '../../../_Constants/app.constants';
import toastr from "../../../_Common/toaster";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import MetaTags from 'react-meta-tags';
const moment = require('moment-timezone');



const MyAvailability = (props) => {
  
  if(localStorage.getItem("userType")==='CLINIC' && props.location.state.timeZone && props.location.state.timeZone.utc[0] != localStorage.getItem("timeZone")){
          
      props.history.listen(event => {  
          localStorage.setItem("timeZone",localStorage.getItem("oldTimeZone"));
          localStorage.setItem("abbr",localStorage.getItem("oldAbbr"));
          window.location = event.pathname;          
      });
      localStorage.setItem("oldTimeZone",localStorage.getItem("timeZone"));
      localStorage.setItem("oldAbbr",localStorage.getItem("abbr"));
      setTimeZone(props.location.state);
      
  }
  let [availabilityList, setAvailabilityList] = useState([]);
  const [availabilityTime, setAvailabilityTime] = useState([]);
  const [toValue,setToValue] = useState(props.location.state && props.location.state.to?props.location.state.to:'');
  const [fromValue,setFromValue] = useState(props.location.state && props.location.state.from?props.location.state.from:'');
  const [modelView, setModelView] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [note, setNote] = useState('');
  const [id, setId] = useState('');
  const [ptId, setPTId] = useState((localStorage.getItem("userType")=='PT'?localStorage.getItem("_id"):(props.location.state.ptId?props.location.state.ptId:'')));
  const [fromList, setFromList] = useState([{ startTime: "", endTime: "" }]);
  const [error, setError] = useState('');
  const [spinner,setSpinner] = useState(false);

 const toggleModal = (row = {}) => {
    setModal(!modal);
    setModelView(row);      
  }

  const getAvailabilityListData = async(search='')=>{
    if(search == 'reset'){
      setFromValue('');
      setToValue('');
    }
    setSpinner(true);

    let dataList = await getAvailabilityList({to:toValue && search != 'reset'?toUTC(moment(getLocalDate(toValue),'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')):"",from:fromValue && search != 'reset'?toUTC(getLocalDate(fromValue)):toUTC(moment().format('YYYY-MM-DD')), ptId:ptId});   

    dataList = dataList.data.list;

    dataList.map(function(row){
        row.date = moment(row.time[0].startTime).format("YYYY-MM-DD");
        row.time.map((item)=>{
           item.startTime = moment(item.startTime).format("hh:mm A");
           item.endTime = moment(item.endTime).format("hh:mm A");
            return item;
        })
        
        return row;
                 
    });

    var days = 30;
    if(toValue != '' && fromValue != '' && search != 'reset'){
       var from = moment(getLocalDate(fromValue));
       var to = moment(getLocalDate(toValue)); 
       days = to.diff(from, 'days')+1;
    }
    
    let dateList = [];
    for(let i = 0;i<days;i++){
      
        if(fromValue != '' && search != 'reset'){
            var dd = moment(getLocalDate(fromValue), "YYYY-MM-DD").add(i, 'days').format(appConstants.DATEFORMAT);
        }else{
            var dd = moment().add(i, 'days').format(appConstants.DATEFORMAT);
        }
        let avDate = moment(dd, appConstants.DATEFORMAT).format("YYYY-MM-DD");
        let userType = localStorage.getItem("userType");

        if((userType === 'PT' && avDate <= moment(localStorage.getItem("insuranceExpDate")).format("YYYY-MM-DD") && avDate <= moment(localStorage.getItem("licenseExpDate")).format("YYYY-MM-DD")) || (userType==='CLINIC' && avDate <= moment(props.location.state.insuranceExpDate).format("YYYY-MM-DD") && avDate <= moment(props.location.state.licenseExpDate).format("YYYY-MM-DD"))){
        
            dateList[i] = {'date':dd};

            dataList.map(function(row){             
              
                if(dd == moment(row.date,'YYYY-MM-DD').format(appConstants.DATEFORMAT)){  
                    row.date = moment(row.date,'YYYY-MM-DD').format(appConstants.DATEFORMAT);
                    dateList[i] = row;
                }              
            });
        }
    }
    
    setAvailabilityList(dateList);
    setSpinner(false);
  }

   const getAvailabilityTime = async(row='')=>{

        var tt = "05:00 AM";
        if(row && row.date && row.date === moment().format(appConstants.DATEFORMAT)){
          if(row && row.time){
            tt = row.time[0].startTime
          }else{
            tt = moment().add(30, 'minutes').format("hh:mm A");
          }
        }
        let timeList = [];
        let today = moment().format("YYYY-MM-DD");
        for(let i = 10;i<43;i++){
            if(moment(today+' '+tt, "YYYY-MM-DD hh:mm A").format("x") <= moment(today+' 00:00:00', "YYYY-MM-DD hh:mm A").add(i*30, 'minutes').format("x")){
              timeList.push(moment(today+' 00:00:00', "YYYY-MM-DD hh:mm A").add(i*30, 'minutes').format("hh:mm A"));
            }          
        }      
        setAvailabilityTime(timeList);
  }

  const onAction=(index='',row='',)=>{
      setModal(!modal);
      setModelView(row);

      let timeList = [];

      if(row && row.time){

          for(let y in row.time){
              timeList.push({ startTime: row.time[y].startTime, endTime: row.time[y].endTime });
          }          
      }
      getAvailabilityTime(row);
      if(timeList.length>0){
          setFromList(timeList);
      }else{
          if(row && row.date && row.date === moment().format(appConstants.DATEFORMAT)){

          }else{
            setFromList([{ startTime: "", endTime: "" }]); 
          }                            
      }      
      
      setSelectedDate(row && row.date?row.date:"");
      setNote(row && row.note?row.note:"");
      setId(row && row._id?row._id:"");
      setError('');
  }

  const onDelete=async(index='',row='',)=>{
      confirmAlert({
      title: 'Reset Availability',
      message: 'Are you sure, you want to reset it?',
      buttons: [
        {
          label: 'Yes',
          onClick: async() => {
               
                let reponse = await deleteAvailabilityAction({_id:row._id});                
                if(reponse.success){               
                    getAvailabilityListData();
                    toastr.show({message: "Reset Successfully!",intent: "success"}); 
                }
            }
        },
        {
          label: 'No',
        }
      ]
    });
      
  }

  const handleSave = async(e)=>{
    let isOK = error!=''?0:1;
  
    let today = moment(selectedDate, appConstants.DATEFORMAT).format("YYYY-MM-DD");
    
    for(let k in fromList){
        if(fromList[k].startTime == '' || fromList[k].endTime == ''){
            setError('Select all required fields!');       
            isOK = 0;
        }
    }

    let bookingData = modelView.bookingData;
    let list = fromList;
    for(let k in bookingData){
        let booking = bookingData[k]; 
        let bookStatus = list.some(function(item){
          
          return (moment(today+" "+item.startTime, 'YYYY-MM-DD h:mm A').format("x") <= moment(booking.startTime).format("x") && moment(today+" "+item.endTime, 'YYYY-MM-DD h:mm A').format("x") >= moment(booking.endTime).format("x"));
        });
        if(!bookStatus){ 
          isOK = 0;
          setError('Please select booked time slot also.'); 
        }
    }
    
    if(isOK == 1){
        setSpinner(true);
       
        fromList.map((item,index)=>{
            
            let startDateTime = moment(selectedDate+' '+item.startTime, appConstants.DATEFORMAT+' h:mm a').format('YYYY-MM-DD HH:mm:ss');
            
            item.startTime = toUTC(startDateTime);
            
            let endDateTime = moment(selectedDate+' '+item.endTime, appConstants.DATEFORMAT+' h:mm a').format('YYYY-MM-DD HH:mm:ss');
            
            item.endTime = toUTC(endDateTime);         
            
        });

        let selectDate = fromList[0].startTime;
       
        let data = {date : selectDate, time : fromList, note : note,ptId:ptId};
        
     
        let msg = "Saved Successfully!";
        if(id != ''){
          data._id = id;
          msg = "Updated Successfully!";

        }
       
        let reponse = await availabilitySaveAction(data);

        if(reponse.success){
          setModal(false);
          getAvailabilityListData();
          toastr.show({message: msg,intent: "success"}); 
        }
        setSpinner(false);
    }
  }

  // handle input change
  const handleInputChange = (e, index) => {
   
    let today = moment(selectedDate, appConstants.DATEFORMAT).format("YYYY-MM-DD");
    
    const { name, value } = e.target;
    const list = [...fromList];

    list[index][name] = value;
    
    for(let j in list){
        if(list[j].startTime != '' && list[j].endTime != '' && moment(today+' '+list[j].startTime, 'YYYY-MM-DD h:mm A').format('x') >= moment(today+' '+list[j].endTime, 'YYYY-MM-DD h:mm A').format('x')){          
            list[j].endTime = ''; 
        }else if((j != 0 && moment(today+' '+list[j-1].endTime, 'YYYY-MM-DD h:mm A').format('x') >= moment(today+' '+list[j].startTime, 'YYYY-MM-DD h:mm A').format('x'))){
            list[j].startTime = '';  
        }
    }
    
    setFromList(list);
    if(error != ''){
        setError(''); 
        for(let k in list){
            if(list[k].startTime == '' || list[k].endTime == ''){
              setError('Select all required fields!');               
            }
        }
    }
    let bookingData = modelView.bookingData;

    for(let k in bookingData){
        let booking = bookingData[k]; 
        console.log(booking);
        let bookStatus = list.some(function(item){
           
            return (moment(today+" "+item.startTime, 'YYYY-MM-DD h:mm A').format("x") <= moment(booking.startTime).format("x") && moment(today+" "+item.endTime, 'YYYY-MM-DD h:mm A').format("x") >= moment(booking.endTime).format("x"));
        });
        console.log(bookStatus);
        if(!bookStatus){ 
            setError('Please select booked time slot also.'); 
        }
    }
  };

  const handleNoteChange = (e) => {
    e.preventDefault();
    setNote(e.target.value);
  };
   
  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...fromList];
    let today = moment().format('YYYY-MM-DD');
    list.splice(index, 1);
    for(let j in list){
        if(list[j].startTime != '' && list[j].endTime != '' && moment(today+' '+list[j].startTime, 'YYYY-MM-DD h:mm A').format('x') >= moment(today+' '+list[j].endTime, 'YYYY-MM-DD h:mm A').format('x')){
                     
            list[j].endTime = ''; 
        }else if((j != 0 && moment(today+' '+list[j-1].endTime, 'YYYY-MM-DD h:mm A').format('x') >= moment(today+' '+list[j].startTime, 'YYYY-MM-DD h:mm A').format('x'))){
            list[j].startTime = '';             
        }
    }
    setFromList(list);

  };
   
  // handle click event of the Add button
  const handleAddClick = () => {
      if(fromList.length<5){
         setFromList([...fromList, { startTime: "", endTime: "" }]);
      }
   
  };

  useEffect(()=>{
      getAvailabilityListData();
      getAvailabilityTime();
    },[]);


    return (

  <div>
    <MetaTags className="bg-rr">
      <title>My Availability</title>
      <meta name="description" content="Recover" />
      <meta name="keywords" content="Recover" />
    </MetaTags>

    <section className="dash_section pad-reggis my_availability_sec">
      
      {localStorage.getItem("userType") === 'CLINIC' ? 
          <BreadcrumbsComp bcNewItems={[
            { path: '/', breadcrumb: "Home" },{ path: '/clinic-pt', breadcrumb: 'List Of PT'},
            { path: '/my-availability', breadcrumb: 'My Availability'}]} />
        :
          <BreadcrumbsComp bcChanges={[
            {path: '/my-availability', breadcrumb: 'My Availability'}]} />
      }

      <div className="container">
          <div className="row">
            <div className="col-md-3 left_dashboard">
              <SideMenu/>
            </div>
            <div className="col-md-9 right_dashboard">
             <div className="content-setion ">
             <div className="section_title m-25">
                <h3>My Availability {localStorage.getItem("userType") === 'CLINIC' && 
                      <span className="pull-right">{props.location.state.name} ({props.location.state.email})</span>
                    }</h3>
             </div>
            <div className="search-availabilty gray_boxx">
            <div className="row">
            <div className="col-md-4">
              <label>From</label>
              <div className="to-from">
              <DatePicker
                onChange={date =>((date?setFromValue(date):setFromValue('')),(toValue!='' && toValue>=date)?'':setToValue(''))}    
                selected={fromValue}            
                dateFormat={appConstants.DateFormatNew}     
                placeholderText={'From date'}            
              />
              </div>
              </div>
              <div className="col-md-4">
              <label>To</label>
              <div className="to-from">
              <DatePicker              
                onChange={date =>(date?setToValue((fromValue?date:'')):setToValue(''))} 
                selected={(toValue>=fromValue?toValue:'')}              
                dateFormat={appConstants.DateFormatNew}
                minDate={fromValue?fromValue:new Date()}
                placeholderText={'To date'} 
              />
              </div>
              </div>
              <div className="col-md-4">
                  <div className="to-from">
                      <button type="button" className="btn btn-primary" onClick={e=>getAvailabilityListData()}>Search</button>
                  </div>
                  <div className="to-from">
                      <button type="button" className="btn btn-primary" onClick={e=>getAvailabilityListData('reset')}>Reset</button>
                  </div>
              </div>

              </div>
             
            </div>

              <Table responsive className="commontabl mapping_table">
                <thead>
                  <tr>                  
                    <th>Date</th>
                    <th>Time</th>
                    <th>Comments/Notes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody> 
                {availabilityList.map(function (item,index) {
                   
                    var timeList = item.time?item.time:[];
                    //console.log(moment().format("YYYY-MM-DD h:mm A"));
                    //console.log(moment(item.date+' 08:00 PM',"MMM, DD YYYY h:mm a").format("YYYY-MM-DD h:mm A"));
                    
                    return (<tr key={index}>                                    
                    <td>{item.date}
                        <input type="hidden" name={"date"+index} id={"date"+index} value={item.date} />
                        <input type="hidden" name={"id"+index} id={"id"+index} value={item._id?item._id:''} />
                    </td>
                    <td>    

                      { timeList.map(function (timeItem,s) {
                          
                          if(timeItem.endTime !=''){  
                            return (<div key={s}>{timeItem.startTime}-{timeItem.endTime}</div>);
                          }
                                                 
                       })    
                     }          
                      { timeList.length === 0 &&
                          <div>Mark Available Slots</div>
                      }              
                    </td>
                    <td className={item._id && item.note?'':'text-center'}>                   
                      {item._id && item.note?item.note:'-'}
                    </td>
                    <td>
                    {  item._id && moment().format("x") <= moment(item.date+' 08:00 PM', appConstants.DATEFORMAT+" h:mm a").format("x") &&
                        <div>
                          <button type="button" onClick={e=>onAction(index, item)} className="btn-fill btn-wd btn btn-info" title="Edit Availability"><i className="fa fa-edit" aria-hidden="true"></i></button>&nbsp;
                          { (!item.bookingData || (item.bookingData && item.bookingData.length === 0)) &&
                            <button type="button" onClick={e=>onDelete(index, item)} className="btn-fill btn-wd btn btn-info" title="Reset Availability"><i className="fa fa-sync-alt" aria-hidden="true" ></i></button>
                          }                          
                        </div>
                    }

                    {  !item._id && moment().format("x") <= moment(item.date+' 08:00 PM', appConstants.DATEFORMAT+" h:mm a").format("x") &&
                      <button type="button" onClick={e=>onAction(index, item)} className="btn-fill btn-wd btn btn-info" title="Add Availability"><img src="static/img/addicon.png" alt="img"/></button>
                    }

                    </td>
                  </tr>)
                })}
                </tbody>
              </Table>
   
              </div>
            </div>  
            <div>
                <Modal isOpen={modal} className="Open-x pt_availability_popup" size="lg" toggle={toggleModal}>
                    <ModalHeader  toggle={toggleModal} className="header-pop-unit">Add/Edit Availability</ModalHeader>
                    <ModalBody className="height-full" >
                        <container-fluid>
                            <Row>
                                                           
                                  <div className="col-sm-12 col-lg-4">
                                    <label>Availability Date*</label><br/>                                     
                                      <input type="text" className="form-control" name="selectedDate" value={selectedDate} readOnly />
                                  </div>
                                  <div className="col-sm-12 col-lg-8">

                                    <Row>
                                        <div className="col-xs-4 col-sm-4 col-lg-4 ">
                                          <label>Start Time*</label>
                                        </div>
                                        <div className="col-xs-4 col-sm-4 col-lg-4">
                                          <label>End Time*</label>
                                        </div>
                                        <div className="col-xs-4 col-sm-4 col-lg-4">
                                          <label>Action</label>
                                        </div>
                                    </Row>
                                  { fromList.map((x, i) => {
                                                                      
                                      return (<Row className="avail_selec-row" key={i}>
                                      <div className="col-xs-4 col-sm-4 col-lg-4">                                        
                                          <select className="form-control" onChange={e => handleInputChange(e, i)} name={"startTime"} value={x.startTime}>
                                            <option value={''}>Select</option>
                                           {availabilityTime.map(function(time,index2) {

                                                  if((i == 0 || (fromList[i-1] && fromList[i-1].endTime != '' && moment(selectedDate+' '+fromList[i-1].endTime, appConstants.DATEFORMAT+' h:mm a').format('x')< moment(selectedDate+' '+time, appConstants.DATEFORMAT+' h:mm a').format('x'))) && moment(selectedDate+' '+time, appConstants.DATEFORMAT+' h:mm a').format('x')< moment(selectedDate+' 09:00 PM', appConstants.DATEFORMAT+' h:mm a').format('x')){

                                                      return <option key={index2} value={time}>{time}</option>
                                                  }
                                              })}
                                          </select>
                                      </div>
                                      <div className="col-xs-4 col-sm-4 col-lg-4">

                                          <select className="form-control" onChange={e => handleInputChange(e, i)} name={"endTime"} value={x.endTime}>
                                              <option value={''}>Select</option>
                                              {availabilityTime.map(function(time,index2) {
                                               
                                                if(x.startTime != '' && moment(selectedDate+' '+x.startTime, appConstants.DATEFORMAT+' h:mm a').format('x') < moment(selectedDate+' '+time, appConstants.DATEFORMAT+' h:mm a').format('x')){
                                                 
                                                  return <option key={index2} value={time}>{time}</option>
                                                }
                                              })}
                                          </select>
                                      </div>
                                      <div className="col-xs-4 col-sm-4 col-lg-4">   
                                      {fromList.length !== 1 && <button type="button" onClick={() => handleRemoveClick(i)} className="btn-fill btn-wd btn btn-info"><i className="fa fa-minus" aria-hidden="true"></i></button>}
                                      {fromList.length - 1 === i && fromList.length < 5 && <button type="button" onClick={handleAddClick} className="btn-fill btn-wd btn btn-info"><i className="fa fa-plus" aria-hidden="true"></i></button>}                                   
                                        
                                      </div>
                                      </Row>
                                      );
                                    })}
                                    <label className="error">{error}</label>
                                  </div>
                                                         
                            </Row>
                            <FormGroup row>
                                <Col sm="12">
                                    <label>Note</label><br/>
                                    <textarea className={"form-control"} onChange={e => handleNoteChange(e)} name={"note"} defaultValue={note}></textarea>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm="12">
                                  <Button color="primary" onClick={() => handleSave()} className="pull-right">Save</Button>
                                </Col>
                            </FormGroup>
                        </container-fluid>
                    </ModalBody>
                </Modal>
            </div>
          </div>
      </div>
    </section>
    <div className="spinner" style={{display:spinner?'block':'none'}}>
        <img src="static/img/spinner.gif" alt='loader'/>
      </div>
  </div>
  );
}
export default withLocalize(withRouter(MyAvailability));
