import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { useSelector,useDispatch } from 'react-redux';
import { withLocalize } from 'react-localize-redux'
import {siteDetail} from '../../../_Constants/app.constants';
import './FooterPage.css';
import moment from 'moment';

const FooterPage = ({history}) => {

  return (
      <footer>
      <section className="contact-section bg-black">
        <div className="container">
          <div className="row">
            <div className="col-md-5 mb-3 mb-md-0">
             <img src="static/img/logo_color.png" className="mb-4" alt="" />
             <p>
              Recover is a telehealth platform connecting patients and physical
              therapists. Recover allows clinics and physical therapists to
              reduce the loss of revenue as cancellations occur in their own
              practice.
            </p>
            <h5>Stay Connected</h5>
            <div className="social d-flex justify-content-left">
              <a className="mx-2" target="_blank" href="https://www.facebook.com/RecoverPT20/"><i className="fab fa-facebook"></i></a>
              <a className="mx-2" target="_blank" href="https://twitter.com/Pt20Recover"><i className="fab fa-twitter"></i></a>
              <a className="mx-2" target="_blank" href="https://www.instagram.com/recoverpt20/"><i className="fab fa-instagram"></i></a>
            </div>
          </div>
          <div className="col-md-5 mb-3 mb-md-0 footer_contact_sec">
            <h4>Support</h4>
            <p>
              <span className="ficon">
                <img src="static/img/phone.svg" alt="" /></span>
                 <a href={'tel:+1'+siteDetail.phone}>{siteDetail.phone}</a>
            </p>
            <p>
              <span className="ficon">
                <img src="static/img/email.svg" alt="" /></span>
                <a href={'mailto:'+siteDetail.email}>{siteDetail.email}</a>
            </p>
             <p>
               <span className="ficon">
                <img src="static/img/address.svg" alt="" /></span>
                <span className="w-f-addred">{siteDetail.address}</span>
              </p>
           </div>
          <div className="col-md-2 mb-3 mb-md-0">
            <h4>Quick Links</h4>
            <ul>
              <li><Link to="/founding-stories">Founding Stories</Link></li>
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/faqs">Help & FAQs</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/terms-condition">Terms and Conditions</Link></li>
              <li><Link to="/informed-consent">Informed Consent</Link></li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col footer text-center">
                <hr />
                 <div className="row">
                  <div className="col-md-6 text-left">
                  <p>
                  Copyright © {moment().format('YYYY')} <span>Recover,</span> LLC All Right
                  Reserved.
                  </p>
                 </div>
                  <div className="col-md-6 text-right nonn">
                    <p> Designed and developed by <a href="https://synergytop.com/" target="_blank">SynergyTop LLC</a> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
       </footer>
    );
}
export default withLocalize(withRouter(FooterPage));
