import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {appConstants} from '../../../_Constants/app.constants';
import {getBlogListAction,getBlogDetailAction} from '../../Actions/User.action';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import moment from "moment";

const BlogDetailPage = ({history}) => {
  const [blogDetail,setBlogDetail] = useState({});
  const [blogList,setBlogList] = useState({});
  
  const getBlogListFunction = async()=>{
    let blogData = await getBlogListAction();   
    setBlogList(blogData);
  }

  const getBlogDetailFunction = async()=>{
    if(history && history.location && history.location.state && history.location.state.id!==''){
      let blogData = await getBlogDetailAction({_id:history.location.state.id});    
      setBlogDetail(blogData.data);   
    }else{
      history.push('blog');
    }
  }  

  const changeDetailFunction = async (e, id) =>{
    if(id && id!==''){
      let blogData = await getBlogDetailAction({_id:id});    
      setBlogDetail(blogData.data);   
    }else{
      history.push('blog');
    }
  }

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
    getBlogListFunction();
    getBlogDetailFunction();
  },[]);

  return (
    <div>
      <MetaTags>
        <title>Blog Detail</title>
        <meta name="description" content="We are working professionally - to put diversified professional’s extra mile ahead; to make e-learning more efficient, easy and accessible to everyone, anywhere and anytime" />
        <meta name="keywords" content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore" />
      </MetaTags>
      <section className="blog-d-page">
        <div className="img-wi-detail">
          <img
            src={`${process.env.REACT_APP_STATIC_S3_URL+blogDetail.blogImg}`}
            alt="example"
            onError={(e) => {
              e.target.src = "static/img/blog-img.jpg"
              e.target.style = ''
            }}
          />
        </div>

        <BreadcrumbsComp bcNewItems={[
          { path: '/', breadcrumb: "Home" },
          { path: '/blog', breadcrumb: 'Blog'},
          { path: '/blog-detail', breadcrumb: 'Blog Detail'},
        ]} />

        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="bol-portion top15">
                <div className="inline-sect">
                  <div className="calendar-sec">
                    <div className="blog-month">
                        {blogDetail.createdAt?moment(blogDetail.createdAt).format("MMMM"):'-'}
                    </div>
                    <div className="blog-date">
                        {blogDetail.createdAt?moment(blogDetail.createdAt).format("D"):'-'}
                    </div>
                  </div>
                  <div className="blog-part-text img-detailx">
                    <span className="r-secc"><i className="fas fa-calendar-alt"></i>&nbsp;
                      {blogDetail.createdAt?moment(blogDetail.createdAt).format(appConstants.DateFormatNew):'-'}
                    </span>
                    <span><i className="fas fa-clock"></i>&nbsp;
                      {blogDetail.createdAt?moment(blogDetail.createdAt).format(appConstants.TIMEFORMAT):'-'}
                    </span>
                    <h4>{blogDetail.name?blogDetail.name.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' '):'-'}</h4>
                    <p>{blogDetail.desc?blogDetail.desc.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' '):'-'}</p>
                  </div>
                </div>
              </div>
            </div> 
            <div className="col-md-4">
              <div className="right-sect-blog top15">
                <div className="recent-heading">
                  <h4>Recent Post</h4>
                </div>
                <div className="recent-b-lidt">
                  <ul>
                    {blogList && blogList.data && blogList.data.map((item, ind) => {
                      if (ind < 5) {
                        return (
                          <li key={ind}>
                            <Link to='#' onClick={e=>changeDetailFunction(e, item._id)}>
                              {item.name?item.name:'-'}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div> 
            </div>  
          </div>
        </div>
      </section>
    </div>
  );
}
export default withRouter(BlogDetailPage);
