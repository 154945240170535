import React,{useEffect} from 'react';
import { withLocalize } from 'react-localize-redux';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';

const HowRecoverWorksPage = ({history}) => {

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
  },[]);

  return (
    <div>
    <MetaTags>
      <title>How Recover Works</title>
      <meta name="description" content="To access e-INSCRIPTION’s services through a website or mobile app, you need an account on our platform. To create an account you have to provide accurate info" />
      <meta name="keywords" content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore " />
    </MetaTags>
    <section className="how_work_page pad-reggis inner_pagewrap">
      <BreadcrumbsComp bcChanges={[{path: '/how-recover-works', breadcrumb: 'How Recover Works'}]} />
      
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-left">
            <div className="how_work_wrap white_box">
              <div className="section_title page_ttl text-center">
                <h3>How Recover Works</h3>
                <div className="decor2"></div>
              </div>
              <h4>How Recover Works, Patient:</h4>
              <ol>  
                <li>Recover allows you to receive physical therapy virtually in the location your choice. The calendar tool allows you to set an availability most convenient for you. Recover works to match you with a licensed physical therapist specialist during the times you have allotted. You will also be provided the option to upload any medical documents that you feel will help the therapist better assess and treat you. Additionally, you will have easy access to your treatment notes and prescribed exercises after each visit.
                </li>
                <li>Pricing: The initial evaluation is 60 minutes and $155. Follow up appointments can be scheduled at 30 or 60 minutes. A 30 minutes follow up appointment is $80, while 60 minutes appointments are $130.</li>
              </ol>
              <h4>How Recover Works, Physical Therapists:</h4>
              <ol> 
                <li>Recover is hiring licensed qualified physical therapists to treat patients through our telehealth platform. If you are an independent contractor with a current California PT license, liability insurance, and access to a Med Bridge account, Recover will pay you per visit to treat patients. You can use the calendar interface to mark your availability and Recover will provide patients that match your openings. You can build your own practice using Recover!
                </li>
                <li>Requirements: California PT license, NPI, W9, Liability Insurance, Med Bridge Account, computer with a camera/sound, as well as a professional area in your house to provide telehealth treatments.
                </li>
                <li>Pricing: Recover offers $75 for 60 minutes PT evaluation, $62.97 for a 60 minutes follow up session, and $38.69 for a 30 minutes follow up PT session.</li>
              </ol>
              <h4>How Recover Works, Clinics:</h4>
              <ol> 
                <li>Recover offers a solution for clinics when they receive last minute cancellations from patients. Recover allows clinics to mark available openings using the calendar interface and will pair your therapist with a patient who is available during the same time. Recover aims to decrease loss of revenue for clinics who have last minute cancellations.</li>
                <li>Each clinic registered with Recover will be given a ‘clinic code’ to input when using the platform. Clinic administrators have access to their therapists’ schedules and can mark the therapist’s availability.
                </li>
                <li>Requirements: EIN, W9, Liability Insurance, Med Bridge account for physical therapists, computer with camera/sound
                </li>
                <li>Pricing: Clinic administrators will determine how much they will pay their therapists per visit. Recover will distribute the following amount to the clinics: $75 for 60 minutes PT evaluation, $62.97 for a 60 minutes follow up session, and $38.69 for a 30 minutes follow up PT session.</li>
              </ol>
              <h4>How Recover Works, Clinic Physical Therapists:</h4>
              <ol> 
                <li>Recover will help fill your cancellations during work hours and allows you to treat patients via telehealth. Your clinic administrator will provide a ‘clinic code’ for you to use to access your Recover account. Your clinic administrator can mark your availability and Recover will pair you with a patient who has an opening during your available time.
                </li>
                <li>If your clinic approves you to treat patients independently after clinic hours (or on the weekends), you have the option of doing so with Recover. Therapists that treat at clinics and independently are required to provide a copy of their California PT license, NPI, W9, proof of liability insurance, and have access to a Med Bridge account.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}
export default withLocalize(HowRecoverWorksPage);
