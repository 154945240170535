import React from 'react';
import  {Router, Route, Redirect }  from 'react-router-dom';
import { LocalizeProvider } from "react-localize-redux";
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { Provider } from 'react-redux'
import {routerMiddleware} from 'react-router-redux';
import history from './history';
import reducer from '../Reducers'
import HomePage from '../../FrontEnd/Components/HomePage/HomePage';
import HeaderPage from '../../FrontEnd/Components/HeaderPage/HeaderPage.js';
import FooterPage from '../../FrontEnd/Components/FooterPage/FooterPage.js';

/*--- Recover ---*/
import Register from '../../FrontEnd/Components/Register/index.js';
import ResetPassword from '../../FrontEnd/Components/Forgot/ResetPassword';
import ChangePassword from '../../FrontEnd/Components/DashboardPage/ChangePassword/index';
import Profile from '../../FrontEnd/Components/DashboardPage/Profile/index';

//--PT
import MyAvailability from '../../FrontEnd/Components/PT/MyAvailability';
import AvailabilityCalendar from '../../FrontEnd/Components/Calendar/AvailabilityCalendar';

//--Patient
import PTList from '../../FrontEnd/Components/Patient/PTList';
import FutureAppointmentList from '../../FrontEnd/views/appointment/FutureAppointmentList';
import AppointmentHistory from '../../FrontEnd/views/appointment/AppointmentHistory';
import PaymentHistory from '../../FrontEnd/views/appointment/PaymentHistory';
import Session from '../../FrontEnd/views/Session/Session';
import PreviousNotesDetail from '../../FrontEnd/views/Session/PreviousNotesDetail';

//--Clinic
import AddClinicPT from '../../FrontEnd/Components/Clinic/AddClinicPT';
import EditClinicPT from '../../FrontEnd/Components/Clinic/EditClinicPT';
import ClinicPT from '../../FrontEnd/Components/Clinic/ClinicPT';

import BlogPage from '../../FrontEnd/Components/SitePages/BlogPage';
import Blogdetail from '../../FrontEnd/Components/SitePages/Blogdetail';
import FeedbackDetail from '../../FrontEnd/Components/SitePages/FeedbackDetail';
import FAQSPage from '../../FrontEnd/Components/SitePages/FAQSPage';
import PrivacyPolicyPage from '../../FrontEnd/Components/SitePages/PrivacyPolicyPage';
import TermsandConditionPage from '../../FrontEnd/Components/SitePages/TermsandConditionPage';
import FoundingStoriesPage from '../../FrontEnd/Components/SitePages/FoundingStoriesPage';
import InformedConsentPage from '../../FrontEnd/Components/SitePages/InformedConsentPage';
import AboutUsPage from '../../FrontEnd/Components/SitePages/AboutUsPage';
import HowRecoverWorksPage from '../../FrontEnd/Components/SitePages/HowRecoverWorksPage';
import SessionPage from '../../FrontEnd/Components/SessionPages/index';
//import SessionPage from '../../FrontEnd/views/Session/NotesTemplate';

import Checkout from '../../FrontEnd/Components/Stripe/Checkout';
import Invoice from '../../FrontEnd/Components/Stripe/Invoice';
import AccountCreate from '../../FrontEnd/Components/Stripe/AccountCreate';
import NotFoundPage from '../../_Common/accessDenied';
  
 //let data = (localStorage.getItem("permissions"))?atob(localStorage.getItem("permissions")):'';
 //let permission = (data!=='' && data!=='undefined')?JSON.parse(data):[];
 export const makeMainRoutes = () => {
    const historyMiddleware = routerMiddleware(history);
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducer,
        composeEnhancers(
            applyMiddleware(historyMiddleware, thunk)
        )
    )

    return (
        <Provider store={store}>
            <Router history={history}>
                <div className="routesBox">
                    <LocalizeProvider>
                        <HeaderPage  />
                        <Route exact path="/" render={(props) => <HomePage  {...props} />} />
                        <Route exact path="/register" render={(props) => <Register  {...props} />} />
                        <Route exact path="/reset-password/" render={(props) => <ResetPassword  {...props} />} />
                        <Route exact path="/reset-password/:code" render={(props) => <ResetPassword  {...props} />} />

                        <Route exact path="/change-password" render={(props) => <ChangePassword  {...props} />} />
                        <Route exact path="/profile" render={(props) => <Profile  {...props} />} />
                        <Route exact path="/my-availability" render={(props) => <MyAvailability  {...props} />} />

                        <Route exact path="/calendar" render={(props) => <AvailabilityCalendar  {...props} />} />
                        
                        <Route exact path="/pt-list" render={(props) => <PTList  {...props} />} />
                        <Route exact path="/future-appointments" render={(props) => <FutureAppointmentList  {...props} />} />
                        <Route exact path="/appointment-history" render={(props) => <AppointmentHistory  {...props} />} />
                        <Route exact path="/payment-history" render={(props) => <PaymentHistory  {...props} />} />
                        <Route exact path="/session" render={(props) => <Session  {...props} />} />
                        <Route exact path="/notes-detail/:id" render={(props) => <PreviousNotesDetail  {...props} />} />

                        <Route exact path="/clinic-pt" render={(props) => <ClinicPT  {...props} />} />
                        <Route exact path="/add-clinic-pt" render={(props) => <AddClinicPT  {...props} />} />
                        <Route exact path="/edit-clinic-pt" render={(props) => <EditClinicPT  {...props} />} />

                        <Route exact path="/blog" render={(props) => <BlogPage  {...props} />} />
                        <Route exact path="/blog-detail" render={(props) => <Blogdetail  {...props} />} />
                        <Route exact path="/feedback-detail" render={(props) => <FeedbackDetail  {...props} />} />
                        <Route exact path="/faqs" render={(props) => <FAQSPage  {...props} />} />
                        <Route exact path="/privacy-policy" render={(props) => <PrivacyPolicyPage  {...props} />} />
                        <Route exact path="/terms-condition" render={(props) => <TermsandConditionPage  {...props} />} />
                        <Route exact path="/terms-condition/:id" render={(props) => <TermsandConditionPage  {...props} />} />
                        
                        <Route exact path="/founding-stories" render={(props) => <FoundingStoriesPage  {...props} />} />
                        <Route exact path="/informed-consent" render={(props) => <InformedConsentPage  {...props} />} />
                        <Route exact path="/about-us" render={(props) => <AboutUsPage  {...props} />} />
                        <Route exact path="/how-recover-works" render={(props) => <HowRecoverWorksPage  {...props} />} /> 
                        <Route exact path="/session-page" render={(props) => <SessionPage  {...props} />} /> 
                        


                        <Route exact path="/checkout" render={(props) => <Checkout  {...props} />} />
                        <Route exact path="/invoice" render={(props) => <Invoice  {...props} />} />
                        <Route exact path="/account-create" render={(props) => <AccountCreate  {...props} />} />
                    
                        {/*<Route path="/404" component={NotFoundPage} />
                        <Redirect to="/"/>*/}

                        <FooterPage />
                    </LocalizeProvider>
                </div>
            </Router>
        </Provider>
    );
}
