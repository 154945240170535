import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import {getSopNoteDataAction,resetSopNoteAction,addSopNote} from '../../Actions/User.action';
import toastr from "../../../_Common/toaster";
import $ from 'jquery';

// This component also use in admin section
var bodyPartArr = [];
var painHistoryArr = [];
const bodyPartList = [
  {id: 'Ankle', title: 'Ankle'},
  {id: 'C-spine', title: 'C-spine'},
  {id: 'Elbow', title: 'Elbow'},
  {id: 'Knee', title: 'Knee'},
  {id: 'Hip', title: 'Hip'},
  {id: 'T-spine', title: 'T-spine'},
  {id: 'L-spine', title: 'L-spine'},
  {id: 'Shoulder', title: 'Shoulder'},
  {id: 'Wrist', title: 'Wrist'},
]

const NoteTemplatePage = ({history, appId, status, getAppointmentList=false, fType=false}) => {
  
  if(fType === true && $('.session_note_wrap')){           
      $('.session_note_wrap input, select, textarea').prop('disabled', false);
      $('.disableBtn button').show();         
  } 
  const [sopNoteData,setSopNoteData] = useState({appointmentId: appId?appId:''});
  const [formData,setFormData] = useState({painHistory:[{area:''}], bodypart:[]});
  const [bodyValue,setBodyValue] = useState(false);
  const [spinner,setSpinner] = useState(false);

  useEffect(()=>{    
    
    getSopNoteDataFunction();
    $('#noteselect').prop('disabled', false);
  },[]);

  const getSopNoteDataFunction = async()=>{
    bodyPartArr = [];
    painHistoryArr = [];
    setFormData({});

    let res = await getSopNoteDataAction(sopNoteData);
    if(res.success && res.data){
      if(res.data.bodypart && res.data.bodypart.length > 0){
        for(let x in res.data.bodypart){
          bodyPartArr.push(res.data.bodypart[x].name);
        }
      }      
      if(res.data.painHistory && res.data.painHistory.length > 0){
        for(let x in res.data.painHistory){
          painHistoryArr.push({title: res.data.painHistory[x].area});
        }
      }
      
      setFormData(res.data);
      if(res.data && res.data.sopNoteSaveType && res.data.sopNoteSaveType==="SUBMIT"){
         
            $('.session_note_wrap input, select, textarea').prop('disabled', true);
            $('.disableBtn button').hide();          
          //$('#noteselect').prop('disabled', false);
                  
      }
    }else if(res.success === false && status==='detailPage'){
      history.push('/');
      toastr.show({message: "Invalid url",intent: "danger"});
    }
  }

  const inputHandler = (e,field)=>{
    let saveFormData = formData;
    saveFormData[e.target.name] = e.target.value;
    setFormData({...formData,...saveFormData});

    if(e.target.name==='templateOption'){
      let snData = sopNoteData;
      snData['templateOption'] = e.target.value;
      setSopNoteData({...sopNoteData,...snData});
      getSopNoteDataFunction();
    }
  }

  const appendInputHandler = (e,index,status)=>{
    status = status===1?'painHistory':'bodypart';
    let saveFormData = formData;
    saveFormData[status][index][e.target.name] = e.target.value;
    setFormData({...formData,...saveFormData});
    
    if(e.target.value!=='' && e.target.name==='area'){
      if(painHistoryArr.some((el, elInd) => elInd === index)){
        painHistoryArr[index]['title'] = e.target.value;
      }else{
        painHistoryArr.push({title: e.target.value});
      }
    }
  }

  const addArrayHandle = idx => () => {
    let saveFormData = formData;
    if(saveFormData.painHistory.length < bodyPartList.length){
      saveFormData['painHistory'] = saveFormData.painHistory.concat({area: "" });
      setFormData({...formData,...saveFormData});
    }
  };

  const removeArrayHandle = (idx, status) => () => {
    if(status===1){//--bodyPartArr remove option
      painHistoryArr.splice(idx, 1);
    }else{
      bodyPartArr.splice(idx,1);
    }
    status = status===1?'painHistory':'bodypart';
    let saveFormData = formData;
    saveFormData[status].splice(idx, 1);
    setFormData({...formData,...saveFormData});
  };

  const bodyPartHandler = (e)=>{
    if(bodyValue!==false){
      let saveFormData = formData;
      saveFormData['bodypart'] = saveFormData.bodypart.concat({name: bodyValue });
      setFormData({...formData,...saveFormData});

      let select_box = document.getElementById("bodypart");
      select_box.selectedIndex = 0;
      setBodyValue(false);

      //--bodyPartArr add option
      bodyPartArr.push(bodyValue);
    }
  } 

  const onSaveAction = async(e,type)=>{
    setSpinner(true);
    sopNoteData['saveType'] = type;
    sopNoteData['sopNoteData'] = formData;
    if(sopNoteData && sopNoteData !==undefined && sopNoteData!==''){
      let res = await addSopNote(sopNoteData);
      if(res.success){
        getSopNoteDataFunction();
        setSpinner(false);   

        if(type && type==='SUBMIT') {
          toastr.show({message: "Note submitted successfully",intent: "success"});
          var myId = document.getElementById("submitnotes");
          if(myId){
            document.getElementById('submitnotes').style.display = 'none';
          }
        }else{
          toastr.show({message: "Note saved successfully",intent: "success"});
        }

        if(getAppointmentList){
            getAppointmentList();
        }
      }else if(res.success===false){
        setSpinner(false);
        toastr.show({message: res.msg,intent: "danger"});
      }
    }
  }

  const onResetAction = async()=>{
    setFormData({});
    let res = await resetSopNoteAction(sopNoteData);
    if(res.success && res.data){
      getSopNoteDataFunction();
      toastr.show({message: "Note reset successfully",intent: "success"});
    }
  }

  return (
    <section className="">
    {/*<section className="pad-reggis session_page">
      <div className="container">
        <div className="row session_wrap">
          <div className="col-lg-12 session_left">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade active show" id="Submitnotes" role="tabpanel" aria-labelledby="Submitnotes-tab">*/}


    <div className="session_note_wrap">
      {formData && !formData.sopNoteSaveType &&
        <div className="note_dropdown">
          <label htmlFor="noteselect" className="notedropdesign">
            <select id="noteselect" name='templateOption' onChange={e=>inputHandler(e)} className="mdb-select md-form form-control">
              <option value="">Choose Note Template</option>
              <option value="1">Initial Evaluation</option>
              <option value="2">Progress Note</option>
              <option value="3">Discharge Note</option>
              <option value="4">Daily Note</option>
              <option value="5">No show Note</option>
            </select>
          </label>
        </div>
      }

      {formData.templateOption && (formData.templateOption==='1' || formData.templateOption==='2' ||formData.templateOption==='3') &&
        <div className="session_noteform_wrap">
          <div className="session_note_ttl">
            {formData.templateOption==='1'?'Initial evaluation':''}
            {formData.templateOption==='2'?'Progress Note':''}
            {formData.templateOption==='3'?'Discharge Note':''}
          </div>
          <div className="form-group">
            <label>Subjective</label>
            <textarea placeholder="Enter your Text Here" name='subject' onChange={e=>inputHandler(e)} className="form-control" defaultValue={formData && formData.subject?formData.subject:''}></textarea>
          </div>
          <div className="pain_history_sec">
            <label>Pain History</label>
            {formData && formData.painHistory && formData.painHistory.map((val, index)=>
              <div className="pain_history_single" key={index}>
                <div className="row">
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group">
                      <label>Area</label>
                      <label htmlFor={"area"+index} className="notedropdesign">
                        <select id={"area"+index} name="area" onChange={e=>appendInputHandler(e,index,1)} defaultValue={val && val.area} /*disabled={val && val.area?true:false}*/
                         className="mdb-select md-form form-control">
                          <option value="">Select</option>
                          {bodyPartList && bodyPartList.map((bdy,ind) => {
                              if(!painHistoryArr.some((el, elIndex) => el.title === bdy.title && elIndex !== index)){
                                return (<option value={bdy.id} key={ind}>{bdy.title}</option>)
                              }
                            })
                          }
                        </select>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group">
                      <label>Current</label>
                      <label htmlFor={"current"+index} className="notedropdesign">
                        <select id={"current"+index} name="current" onChange={e=>appendInputHandler(e,index,1)} className="mdb-select md-form form-control" defaultValue={val && val.current}>
                          <option value="">0/10</option>
                          <option value="1">1/10</option>
                          <option value="2">2/10</option>
                          <option value="3">3/10</option>
                          <option value="4">4/10</option>
                          <option value="5">5/10</option>
                          <option value="6">6/10</option>
                          <option value="7">7/10</option>
                          <option value="8">8/10</option>
                          <option value="9">9/10</option>
                          <option value="10">10/10</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group">
                      <label>Best</label>
                      <label htmlFor={"best"+index} className="notedropdesign">
                        <select id={"best"+index} name="best" onChange={e=>appendInputHandler(e,index,1)} className="mdb-select md-form form-control" defaultValue={val && val.best}>
                          <option value="">0/10</option>
                          <option value="1">1/10</option>
                          <option value="2">2/10</option>
                          <option value="3">3/10</option>
                          <option value="4">4/10</option>
                          <option value="5">5/10</option>
                          <option value="6">6/10</option>
                          <option value="7">7/10</option>
                          <option value="8">8/10</option>
                          <option value="9">9/10</option>
                          <option value="10">10/10</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group">
                      <label>Worst</label>
                      <label htmlFor={"worst"+index} className="notedropdesign">
                        <select id={"worst"+index} name="worst" onChange={e=>appendInputHandler(e,index,1)} className="mdb-select md-form form-control" defaultValue={val && val.worst}>
                          <option value="">0/10</option>
                          <option value="1">1/10</option>
                          <option value="2">2/10</option>
                          <option value="3">3/10</option>
                          <option value="4">4/10</option>
                          <option value="5">5/10</option>
                          <option value="6">6/10</option>
                          <option value="7">7/10</option>
                          <option value="8">8/10</option>
                          <option value="9">9/10</option>
                          <option value="10">10/10</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 disableBtn">
                    <div className="form-group">
                      {formData && formData.painHistory && formData.painHistory.length < 9 &&
                        <button onClick={addArrayHandle(index)} className="btn add_painhistroy">+</button>
                      }
                      {formData && formData.painHistory && formData.painHistory.length > 1 &&
                        <button onClick={removeArrayHandle(index,1)} className="btn remove_painhistroy">-</button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="form-group inline_field observation_field">
            <label className="label">Observation</label>
            <textarea name='observation' onChange={e=>inputHandler(e)} className="form-control" placeholder="Enter your Text Here" defaultValue={formData && formData.observation?formData.observation:''}></textarea>
          </div>

          <div className="form-group inline_field bodypart_field disableBtn">
            <label className="label">Body Parts</label>
            <label htmlFor="bodypart" className="notedropdesign">
              <select id="bodypart" name='bodypart' onChange={e=>setBodyValue(e.target.value)} className="mdb-select md-form form-control">
                <option value="">Choose Body Part</option>
                {bodyPartList && bodyPartList.map((bdy,ind) => {
                    if(!bodyPartArr.includes(bdy.id)){
                      return (
                        <option value={bdy.id} key={ind}>{bdy.title}</option>
                      )
                    }
                  })
                }
              </select>
            </label>
            {formData && formData.bodypart && formData.bodypart.length < 9 &&
              <button onClick={e=>bodyPartHandler(e)} className="btn noteform_btn">Add</button>
            }
          </div>

          {formData && formData.bodypart && formData.bodypart.map((val,index)=>
            <div className="arom_sec" key={index}> 
              <label className="label">{val.name}</label>
              <div className="arom_topsec">
                <table border="0">
                  {val.name && (val.name==='C-spine' || val.name==='T-spine' ||  val.name==='L-spine') ?
                    <tbody>
                      <tr>
                        <th>Arom</th>
                      </tr>
                      <tr>
                        <td>Flexion</td>
                        <td>
                          <select name='aromRight_1' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromRight_1}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Extension</td>
                        <td>
                          <select name='aromRight_2' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromRight_2}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                      </tr><br/>
                      <tr>
                        <th>Arom</th>
                        <th>Right Side</th>
                        <th>left Side</th>
                      </tr>                   
                    </tbody>
                  :
                    <tbody>
                      <tr>
                        <th>Arom</th>
                        <th>Right Side</th>
                        <th>left Side</th>
                      </tr>
                      <tr>
                        {val.name && val.name==='Ankle' &&
                          <td>Inversion</td>
                        }                                
                        {val.name && val.name!=='Ankle' &&
                          <td>Flexion</td>
                        }                                   
                        <td>
                          <select name='aromRight_1' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromRight_1}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                        <td>
                          <select name='aromLeft_1' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromLeft_1}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        {val.name && val.name==='Ankle' &&
                          <td>Eversion</td>
                        }
                        {val.name && (val.name==='C-spine' || val.name==='Elbow' || val.name==='Knee' || val.name==='Hip' || val.name==='T-spine' || val.name==='L-spine' || val.name==='Shoulder' || val.name==='Wrist')&&
                          <td>Extension</td>
                        }
                        <td>
                          <select name='aromRight_2' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromRight_2}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                        <td>
                          <select name='aromLeft_2' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromLeft_2}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  }
                  {val.name && (val.name !== 'Knee' && val.name !== 'Shoulder') &&
                    <tbody>
                      <tr>
                      {val.name && val.name==='Ankle' &&
                        <td>Plantar Flexion</td>
                      }
                      {val.name && val.name==='C-spine' &&
                        <td>Side bending</td>
                      }                                
                      {val.name && val.name==='Elbow' &&
                        <td>Pronation</td>
                      }                                   
                      {val.name && val.name==='Hip' &&
                        <td>Abduction</td>
                      } 
                      {val.name && (val.name==='T-spine' ||  val.name==='L-spine')&&
                        <td>Sidebending</td>
                      }                                   
                      {val.name && val.name==='Wrist' &&
                        <td>Radial Deviation</td>
                      } 
                      <td>
                        <select name='aromRight_3' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromRight_3}>
                          <option value=''>Select</option>
                          <option value='0'>0%</option>
                          <option value='25'>25%</option>
                          <option value='50'>50%</option>
                          <option value='75'>75%</option>
                          <option value='100'>100%</option>
                        </select>
                      </td>
                      <td>
                        <select name='aromLeft_3' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromLeft_3}>
                          <option value=''>Select</option>
                          <option value='0'>0%</option>
                          <option value='25'>25%</option>
                          <option value='50'>50%</option>
                          <option value='75'>75%</option>
                          <option value='100'>100%</option>
                        </select>
                      </td>
                      </tr>
                      <tr>
                        {val.name && val.name==='Ankle' &&
                          <td>Dorsi Flexion</td>
                        }
                        {val.name && val.name==='C-spine' &&
                          <td>Rotation</td>
                        }                                
                        {val.name && val.name==='Elbow' &&
                          <td>Supination</td>
                        }                                  
                        {val.name && val.name==='Hip' &&
                          <td>Adduction</td>
                        }
                        {val.name && (val.name==='T-spine' ||  val.name==='L-spine') &&
                          <td>Rotation</td>
                        }
                        {val.name && val.name==='Wrist' &&
                          <td>Ulnar Deviation</td>
                        }
                        <td>
                          <select name='aromRight_4' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromRight_4}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                        <td>
                          <select name='aromLeft_4' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromLeft_4}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  }
                  {val.name && (val.name==='Hip' || val.name==='Shoulder') &&
                    <tbody>
                      <tr>
                        <td>Internal Rotation</td>
                        <td>
                          <select name='aromRight_5' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromRight_5}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                        <td>
                          <select name='aromLeft_5' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromLeft_5}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>External Rotation</td>
                        <td>
                          <select name='aromRight_6' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromRight_6}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                        <td>
                          <select name='aromLeft_6' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromLeft_6}>
                            <option value=''>Select</option>
                            <option value='0'>0%</option>
                            <option value='25'>25%</option>
                            <option value='50'>50%</option>
                            <option value='75'>75%</option>
                            <option value='100'>100%</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  }
                  {val.name && val.name==='Shoulder' &&
                    <tr>
                      <td>Abduction</td>
                      <td>
                        <select name='aromRight_7' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromRight_7}>
                          <option value=''>Select</option>
                          <option value='0'>0%</option>
                          <option value='25'>25%</option>
                          <option value='50'>50%</option>
                          <option value='75'>75%</option>
                          <option value='100'>100%</option>
                        </select>
                      </td>
                      <td>
                        <select name='aromLeft_7' onChange={e=>appendInputHandler(e,index,2)} className="form-control" defaultValue={val && val.aromLeft_7}>
                          <option value=''>Select</option>
                          <option value='0'>0%</option>
                          <option value='25'>25%</option>
                          <option value='50'>50%</option>
                          <option value='75'>75%</option>
                          <option value='100'>100%</option>
                        </select>
                      </td>
                    </tr>
                  }
                </table>
              </div>
              <div className="arom_btmsec row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>Muscle Testing</label>
                    <textarea name='muscleTesting' onChange={e=>appendInputHandler(e,index,2)} className="form-control" placeholder="Enter your Text Here" defaultValue={val && val.muscleTesting}></textarea>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>Special Testing</label>
                    <textarea name='specialTesting' onChange={e=>appendInputHandler(e,index,2)} className="form-control" placeholder="Enter your Text Here" defaultValue={val && val.specialTesting}></textarea>
                  </div>
                </div>
              </div>
              <div className="arom_buttonsec text-right disableBtn">
                <button onClick={removeArrayHandle(index,2)} className="btn noteform_btn">Remove</button>
              </div>
            </div>
          )}

          {formData.templateOption && formData.templateOption!=='3' &&
            <div><div className="form-group">
              <label className="label">Assessment</label>
              <textarea rows={5} name='assessment' onChange={e=>inputHandler(e)} className="form-control" placeholder="Enter your Text Here" defaultValue={formData && formData.assessment?formData.assessment.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '\n'):''}></textarea>
            </div>
            <div className="form-group">
              <label className="label">Problem List</label>
              <textarea rows={4} name='problemList' onChange={e=>inputHandler(e)} className="form-control" placeholder="Enter your Text Here" defaultValue={formData && formData.problemList?formData.problemList.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '\n'):''}></textarea>
            </div></div>
          }

          <div className="form-group">
            <label className="label">Goals</label>
            <textarea rows={6} name='goals' onChange={e=>inputHandler(e)} className="form-control" placeholder="Enter your Text Here" defaultValue={formData && formData.goals?formData.goals.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '\n'):''}></textarea>
          </div>
          <div className="form-group">
            <label className="label">Treatment Plan</label>
            <textarea rows={5} name='treatmentPlan' onChange={e=>inputHandler(e)} className="form-control" placeholder="Enter your Text Here" defaultValue={formData && formData.treatmentPlan?formData.treatmentPlan.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '\n'):''}></textarea>
          </div>

          {formData.templateOption && formData.templateOption!=='3' ?
            <div className="form-group">
              <label className="label">
                {formData.templateOption==='1'?'Initial Treatment':'Treatment'}
              </label>
              <textarea rows={3} name='initialTreatment' onChange={e=>inputHandler(e)} className="form-control" placeholder="Enter your Text Here" defaultValue={formData && formData.initialTreatment?formData.initialTreatment.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '\n'):''}></textarea>
            </div>
          :
            <div className="form-group">
              <label className="label">Assessment</label>
              <textarea rows={5} name='assessment' onChange={e=>inputHandler(e)} className="form-control" placeholder="Enter your Text Here" defaultValue={formData && formData.assessment?formData.assessment.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '\n'):''}></textarea>
            </div>
          }

          {formData && (!formData.sopNoteSaveType || formData.sopNoteSaveType !== 'SUBMIT') &&
            <div><div className="form-group text-right edit_save_btns">
                <button className="btn noteform_btn" onClick={e=>onResetAction(e)}>Reset</button>
                <button className="btn noteform_btn" onClick={e=>onSaveAction(e,'SAVE')}>Save</button>
              </div>
              <div className="form-group text-right Submit_bnt">
                <button className="btn noteform_btn" onClick={e=>onSaveAction(e,'SUBMIT')}>Submit</button>
            </div></div>
          }
        </div>
      }      

      {formData.templateOption && formData.templateOption==='4' &&
        <div className="session_noteform_wrap">
          <div className="session_note_ttl">Daily Note</div>
          <div className="form-group">
            <label>Subjective</label>
            <textarea placeholder="Enter your Text Here" name='subject' onChange={e=>inputHandler(e)} className="form-control" defaultValue={formData && formData.subject?formData.subject:''}></textarea>
          </div>
          <div className="form-group">
            <label className="label">Objective</label>
            <textarea name='observation' onChange={e=>inputHandler(e)} className="form-control" placeholder="Enter your Text Here" defaultValue={formData && formData.observation?formData.observation:''}></textarea>
          </div>
          <div className="form-group">
            <label className="label">Assessment</label>
            <textarea rows={5} name='assessment' onChange={e=>inputHandler(e)} className="form-control" placeholder="Enter your Text Here" defaultValue={formData && formData.assessment?formData.assessment.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '\n'):''}></textarea>
          </div>
          <div className="form-group">
            <label className="label">Plan</label>
            <textarea rows={4} name='treatmentPlan' onChange={e=>inputHandler(e)} className="form-control" placeholder="Enter your Text Here" defaultValue={formData && formData.treatmentPlan?formData.treatmentPlan.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '\n'):''}></textarea>
          </div>
          {formData && (!formData.sopNoteSaveType || formData.sopNoteSaveType !== 'SUBMIT') &&
            <div><div className="form-group text-right edit_save_btns">
                <button className="btn noteform_btn" onClick={e=>onResetAction(e)}>Reset</button>
                <button className="btn noteform_btn" onClick={e=>onSaveAction(e,'SAVE')}>Save</button>
              </div>
              <div className="form-group text-right Submit_bnt">
                <button className="btn noteform_btn" onClick={e=>onSaveAction(e,'SUBMIT')}>Submit</button>
            </div></div>
          }
        </div>
      }      

      {formData.templateOption && formData.templateOption==='5' &&
        <div className="session_noteform_wrap">
          <div className="session_note_ttl">No Show Note</div>
          <div className="form-group">
            <textarea rows={5} name='assessment' onChange={e=>inputHandler(e)} className="form-control" placeholder="Enter your Text Here" defaultValue={formData && formData.assessment?formData.assessment.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '\n'):''}></textarea>
          </div>
          {formData && (!formData.sopNoteSaveType || formData.sopNoteSaveType !== 'SUBMIT') &&
            <div><div className="form-group text-right edit_save_btns">
                <button className="btn noteform_btn" onClick={e=>onResetAction(e)}>Reset</button>
                <button className="btn noteform_btn" onClick={e=>onSaveAction(e,'SAVE')}>Save</button>
              </div>
              <div className="form-group text-right Submit_bnt">
                <button className="btn noteform_btn" onClick={e=>onSaveAction(e,'SUBMIT')}>Submit</button>
            </div></div>
          }
        </div>
      }
    </div>
    </section>
  );
}
export default withLocalize(withRouter(NoteTemplatePage));
