import React,{useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { withLocalize } from 'react-localize-redux';
import {Row, FormGroup, Label, Input, Button} from 'reactstrap';
import {FormError,ShowFormErrors} from "../../../_Common/formError";
import toastr from "../../../_Common/toaster";
import {userRegistrationAction} from '../../Actions/User.action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {appConstants, setSessionValue,getLocalDate,toUTC} from '../../../_Constants/app.constants';
import {timezones} from '../../../_Constants/timezones';
import GeoAddressComponent from '../../../_Common/GeoAddressManage';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import $ from 'jquery';

const PatientPage = ({history}) => {
  const dispatch = useDispatch();
  const [signupForm,setSignupForm] = useState({});
  const [dobValue, setDobValue] = useState();
  const [signupErrors,setSignupErrors] = useState({});
  const [isGender, setIsGender] = useState(false);
  const { register, handleSubmit, setValue, errors, setError, reset } = useForm();
  const [spinner,setSpinner] = useState(false);
  const [valueReset,setValueReset] = useState(true);
  const [adsStatus,setAdsStatus] = useState(true);

  const inputHandler = (e,field)=>{
    let signupFormData = signupForm;
    if(field === 'phone' && e.target.value.replace(/[-]/g, '').trim().length === 10){
      $('input[name="phone"]').removeClass("requiredClass");
    }
    if(field == 'time_zone'){
      let timezoneValue = timezones.filter(
          function(timezones){return timezones.text == e.target.value}
      );      
      signupFormData['timeZone'] = JSON.stringify(timezoneValue[0]);
    }
    signupFormData[field] = e.target.value;
    setSignupForm({...signupForm,...signupFormData});
  }

  const checkboxHandler = (e,field)=>{
    let signupFormData = signupForm;
    signupFormData[field] = e.target.checked===true?true:false;
    setSignupForm(signupFormData);
  }

  const radioHandler = (e,field)=>{
    let signupFormData = signupForm;
    signupFormData[e.target.name] = field;
    setSignupForm(signupFormData);
    setIsGender(true);
  }

  const fileChangedHandler=(event, elename)=>{
    event.preventDefault();
    let signupFormData = signupForm;
    let file = event.target.files[0];
    if(!file && file===undefined){
      signupFormData[elename+'Preview'] = '';
      signupFormData[elename] = '';
      setSignupForm({...signupForm,...signupFormData});
      return false;
    }
    var fileName = (file && file.name?file.name:'');
    let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
    if (extensions == 'bmp' || extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg' || 
        extensions == 'pdf' || extensions == 'docx'|| extensions == 'doc') {
        signupFormData[elename+'Preview'] = URL.createObjectURL(event.target.files[0]);
        signupFormData[elename] = event.target.files[0];
        setSignupForm({...signupForm,...signupFormData});
    } else {
        let err = [{"msg": "Extensions doesn't match","param": elename}];
        setSignupErrors(FormError(err));
    }
  }

  const onFormSubmit = async () =>{
    setSpinner(true);
    signupForm['userType'] = 'PATIENT';
    signupForm['dob'] = dobValue?toUTC(getLocalDate(dobValue)):'';
    signupForm['phone'] = signupForm['phone'].replace(/\D/g,'');   
    signupForm['phoneCode'] = "+1"; 
    signupForm['timeZone'] = (signupForm['timeZone']);
    let res = {};
    let formErrors = {};
    res = await userRegistrationAction(signupForm);
    if(res.err && res.err.length > 0){
      formErrors = FormError(res.err);
    }
    setSignupErrors(formErrors);
    if(res.success){
      toastr.show({message: res.msg,intent: "success"});
      setSessionValue(res.data);
      setSpinner(false);
      window.location = "profile";
      //history.push('/profile');
    }else if(res.success===false){
      setSpinner(false);
      toastr.show({message: res.msg,intent: "danger"});
    }
  }

  const setResetForm = (e)=>{
    reset();
    setSignupForm({});
    setIsGender(false);
    setDobValue('');
    setValueReset(false);
    setTimeout(function() {
      setValueReset(true)
    }, 1000);
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12"> 
        <div className="form_modals register_patient_form">
          <form onSubmit={handleSubmit(onFormSubmit)} id='resetForm'>
            <div className="row register_patient_row1">
              <div className="col-md-12">
                <div className="col section_title text-center">
                  <h3>Patient Registration</h3>
                  <div className="decor"></div>
                 </div>
               </div>
           </div>
           <div className="row register_patient_row2">
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-6">
                    <label>First Name*</label>
                     <div className="form-group">
                      <input onChange={e=>inputHandler(e,'firstName')} name="firstName" type="text" 
                        className="form-control" placeholder="First Name"
                        ref={register({
                          required: 'Required'
                        })}
                      />
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"firstName")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Last Name*</label>
                    <div className="form-group">
                      <input onChange={e=>inputHandler(e,'lastName')} name="lastName" type="text" 
                        className="form-control" placeholder="Last Name"
                        ref={register({
                          required: 'Required'
                        })}
                      />
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"lastName")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Date of Birth*</label>
                    <div className="form-group">
                      <DatePicker
                        name='dob'
                        selected={dobValue}
                        maxDate={new Date()}
                        className="form-control"
                        dateFormat={appConstants.DateFormatNew}
                        placeholderText="Click to select a Date of Birth"
                        onChange={date => { setDobValue(date); setValue("dob", date);}}
                        ref={register({name: "dob"}, {required: 'Required'} )}
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={5}
                      />
                      {!dobValue &&
                        (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"dob")}</div>)
                      }
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Gender*</label>
                    <div className="radio-bb">
                      <FormGroup check>
                        <Label check htmlFor='forMale'>
                        <input type="radio" className="clas-radio" id="forMale" name="gender" onClick={e=>radioHandler(e,'male')}
                        ref={register({
                          required: isGender===false?'Required':'',
                        })}
                        /> Male </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check htmlFor='forFemale'><Input type="radio" className="clas-radio" id="forFemale" name="gender" onClick={e=>radioHandler(e,'female')}
                        ref={register({
                          required: isGender===false?'Required':'',
                        })}
                        /> Female </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check htmlFor='forOther'><Input type="radio" className="clas-radio" id="forOther" name="gender" onClick={e=>radioHandler(e,'other')}
                        ref={register({
                          required: isGender===false?'Required':'',
                        })}
                        /> Other </Label>
                      </FormGroup>
                      {isGender===false &&
                        (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"gender")}</div>)
                      }
                    </div>
                  </div>    
                  <div className="col-lg-6">
                    <label>Email*</label>
                    <div className="form-group">
                      <input onChange={e=>inputHandler(e,'email')} name="email" type="email" 
                        className="form-control" placeholder="Email"
                        ref={register({
                          required: 'Required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Please enter a valid email address."
                          }
                        })}
                      />
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"email")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Phone Number*</label>
                    <div className="form-group phone_field">
                      <select className="form-control" name="phoneCode">
                        <option value="+1" defaultValue>+1</option>
                      </select>
                      <NumberFormat
                        format="###-###-####"
                        name="phone"
                        //pattern="[0-9,-]{12}"
                        className="form-control"
                        placeholder="Phone Number Here"
                        value={signupForm.phone?signupForm.phone:''}
                        onChange={e =>{ inputHandler(e, 'phone'); setValue("phone", e.target.value);}}
                        ref={register(!signupForm.phone?{name: "phone"}:'', 
                          {required: 'Required',
                          pattern: {value: /^(?=.*\d)[\d-]{12}$/, message: "Phone number should be 10 digit number."}
                        } )}
                      />
                      {!signupForm.phone &&
                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"phone")}</div>
                      }
                      {signupForm.phone && signupForm.phone.replace(/[-]/g, '').trim().length!==10 && 
                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"phone")}</div>
                      }
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Password*</label>
                    <div className="form-group">
                      <input type="password" onChange={e=>inputHandler(e,'password')} name="password" 
                        className="form-control" placeholder="Password"
                        ref={register({
                          required: 'Required',
                          pattern: {
                            value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,15}$/,
                            message: "Minimum 6 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                          }
                        })}
                      />
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"password")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Confirm Password*</label>
                    <div className="form-group">
                      <input type="password" onChange={e=>inputHandler(e,'cPassword')} name="cPassword" 
                        className="form-control" placeholder="Confirm Password"
                        ref={register({ 
                          required: 'Required',
                          validate: (value) => value === signupForm.password || "Passwords don't match.",
                        })}
                      />
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"cPassword")}</div>
                    </div>
                  </div>
                  
                  <GeoAddressComponent
                      history={history}
                      register={register}
                      errors={errors}
                      formData={signupForm}
                      inputHandler={inputHandler}
                      signupErrors={signupErrors}
                      valueReset={valueReset}
                  />

                  <div className="col-md-12">
                    <div className="checkbbox">
                      <label htmlFor="termsConditions">
                        <input onChange={e=>checkboxHandler(e,'termsConditions')} id="termsConditions" type="checkbox" defaultChecked="" name='termsConditions'
                          ref={register({
                            required: 'Required'
                          })}
                        />
                        <p>Terms & Conditions (<a href="/terms-condition" target="_blank">Click here</a> to read)</p>
                      </label>
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"termsConditions")}</div>
                    </div>
                    <div className="checkbbox">
                      <label htmlFor="informedConsent">
                        <input onChange={e=>checkboxHandler(e,'informedConsent')} id="informedConsent" type="checkbox" defaultChecked="" name='informedConsent'
                          ref={register({
                            required: 'Required'
                          })}
                        />
                        <p>Informed Consent (<a href="/informed-consent" target="_blank">Click here </a> to read)</p>
                      </label>
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"informedConsent")}</div>
                    </div>
                    <div className="checkbbox">
                      <label htmlFor="privacyPolicy">
                        <input onChange={e=>checkboxHandler(e,'privacyPolicy')} id="privacyPolicy" type="checkbox" defaultChecked="" name='privacyPolicy'
                          ref={register({
                            required: 'Required'
                          })}
                        />
                        <p>Privacy Policy (<a href="/privacy-policy" target="_blank">Click here </a> to read)</p>
                      </label>
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"privacyPolicy")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6 t-pp">
                    <input type="reset" onClick={e=>setResetForm(e)} className="btn btn-primary rt" value="Clear" />
                    <input type="submit" className="btn btn-primary" value="Submit" />
                  </div>
                </div> 
              </div>
              <div className="col-lg-3 text-center">
                <div className="bg-cirpatient">
                  <div className="prof_section">
                    <label >
                      { signupForm.profileImgPreview && <img src={signupForm.profileImgPreview}/>}

                      { !signupForm.profileImg && <img src="static/img/icon-profiles.png" alt="img"/> }

                      <input type="file" name="profileImg" accept=".bmp,.jpg,.png,.jpeg" 
                        onChange={(e)=>fileChangedHandler(e, "profileImg")} style={{ display: 'none' }}
                        />
                    </label>
                    <br/><br/><div className="text-danger">{ShowFormErrors(errors,signupErrors,"profileImg")}</div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        </div>
      </div>
      <div className="spinner" style={{display:spinner?'block':'none'}}>
        <img src="static/img/spinner.gif" alt='loader'/>
      </div>
    </div>
  );
}
export default withLocalize(PatientPage);
