import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize, Translate } from 'react-localize-redux';
import SideMenu from '../../Components/DashboardPage/SideMenu';
import {Modal, ModalHeader, ModalBody, Card, CardBody, Col,Row ,Label, Form, Input, Button, FormGroup, Table } from 'reactstrap';
import moment from 'moment';
import {getAppointmentHistory, addSopNote} from '../../Actions/User.action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {appConstants, getLocalDate, toUTC} from '../../../_Constants/app.constants';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import {BootstrapTable,TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import PatientDocuments from '../../Components/DashboardPage/Profile/PatientDocuments';
import PatientMedicalHistory from '../../Components/DashboardPage/Profile/PatientMedicalHistory';
import NotesTemplatePage from '../Session/NotesTemplate';
import PreviousNotesPage from '../Session/PreviousNotes';
import toastr from "../../../_Common/toaster";

const AppointmentHistory = (props) => {
  const [dataList, setAppointmentList] = useState([]);
  const [toValue,setToValue] = useState('');
  const [fromValue,setFromValue] = useState('');
  const [name, setName] = useState('');
  const [toError, setToError] = useState('');
  const [userType, setUserType] = useState(localStorage.getItem("userType"));
  const [spinner,setSpinner] = useState(false);
  const [modelView, setModelView] = useState(false);
  const [modal, setModal] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  
  const toggleModal = (row = {}) => {
      row.sopView = true;
      let endd = moment(row.date,'YYYY-MM-DD').format('YYYY-MM-DD')+' '+row.endTime;
      
      let hours = row.email48ActiveStatus?96:48; 
     
      let endStr = moment(endd, 'YYYY-MM-DD hh:mm A').add(hours,'hours').format('x');
      if(endStr > moment().format('x')){
        row.sopView = false;
      }
      if(modal == true){
       // getAppointmentList();
      }
      setModal(!modal);
      setModelView(row);   
      
  }

  const getAppointmentList = async(search='', page='', limit='')=>{
    if(search == 'reset'){
      setFromValue('');
      setToValue('');
      setName('');
      setToError('');
    }
    if(fromValue && fromValue !== '' && toValue === ''){
      setToError('Select to date!');
    }else{
      setToError('');
      setSpinner(true);
      let datetime = moment().format('YYYY-MM-DD hh:mm A');
      let dataList = await getAppointmentHistory({startDate:fromValue && search != 'reset'?toUTC(getLocalDate(fromValue)):'',endDate:toValue && search != 'reset'?toUTC(moment(getLocalDate(toValue),'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')):'', name: search != 'reset'?name:'', datetime:datetime, page:(page!=''?page:currentPage)-1, limit:limit!=''?limit:perPage});        
      let list = dataList.data;

      setTotalSize(dataList.count);
 
      list.map(item=>{
          item.date = moment(item.startTime).format('YYYY-MM-DD');
          item.startTime = moment(item.startTime).format('hh:mm A');
          item.endTime = moment(item.endTime).format('hh:mm A');
          return item;
      })
      setAppointmentList(list);
            
      setSpinner(false);
    }
  }

  const nameDetails=(cell,row)=>{     
      let clinicName = (userType==='PATIENT' && row.clinicName && row.clinicName != '')?'('+row.clinicName+')':'';
  return (<span>{row.ptName}{ clinicName!=='' && <br></br>}{clinicName}</span>);
  }

  const dateTimeDetails=(cell,row)=>{   
    return (<span>{moment(row.date,"YYYY-MM-DD").format(appConstants.DATEFORMAT)},<br></br>{row.startTime+'-'}{row.endTime},<br></br>({row.duration}Min)</span>);
    }

    const paymentStatus=(cell,row)=>{
      let status = row.ptTransferStatus;
      if(status === 'true' || row.status === 'FINISHED' || (row.status === 'cancelByPA' && row.cancelAmountStatus == 'true')){
        status = 'Succeed'; 
      }else if(row.status === 'cancelByPA' || row.status === 'cancelByPT' || row.status === 'REJECT'){
        status = 'Cancelled';
      }else{
        status = ''; 
      }
      return status;        
  }

  const priceFormatter=(cell, row)=>{
    if((row.status === 'cancelByPA' && row.cancelAmountStatus == 'false') || row.status === 'cancelByPT' || row.status === 'REJECT'){
      return '$0';
    }else{
      return `$${row.cancelAmountStatus == 'true'?row.cancelAmount:row.amount}`; 
    }
    
  }

  const onProfilePic=(cell,row, enumObject, index)=>{
    
    const intials = userType==='PATIENT'?row.ptName.split(' ').map(name => name[0]).join('').toUpperCase():row.paName.split(' ').map(name => name[0]).join('').toUpperCase();
    if(row.ptImg && row.ptImg!='' && userType==='PATIENT'){
        return (<img className="profilepic" src={`${process.env.REACT_APP_STATIC_S3_URL+row.ptImg}`} alt={intials}/>);
    }else if(row.paImg && row.paImg!='' && userType!=='PATIENT'){
        return (<img className="profilepic" src={`${process.env.REACT_APP_STATIC_S3_URL+row.paImg}`} alt={intials}/>);
    }else{
        return (<div className="profilepic"> {intials} </div>);
    }
  } 

  const action=(cell,row)=>{

    let status = row.status;
    let userActionData = row.userAction;
    let startStatus = userActionData.some(function(item){
      return item.userAction == 'start';
    });
    if(status === 'cancelByPA' || status === 'cancelByPT'){ 

        let clinicStatus = userActionData.some(function(item){
          return item.userAction == 'cancelByPT' && item.userType == 'CLINIC';
        });
        status = row.noShowStatus && row.noShowStatus == 'true'?'No Show by PT':'Cancelled by '+(status === 'cancelByPA'?'Patient':(clinicStatus == true?'Clinic':'Therapist'));

        status = status+(row.cancelAmountStatus == 'true'?' (<24)':'');

    }else if((status === 'REJECT') || (status === 'ACCEPTED' && startStatus === false) || (status === 'PENDING')){
        status = 'REJECTED';
    }else if(status === 'FINISHED' || (status === 'ACCEPTED' && startStatus === true)){
        status = row.noShowStatus && row.noShowStatus == 'true'?'No Show by Patient':'COMPLETED';
        if(userType!=='PATIENT'){
            let endd = moment(row.date,'YYYY-MM-DD').format('YYYY-MM-DD')+' '+row.endTime;
            //let endStr = moment(endd, 'YYYY-MM-DD hh:mm A').add(24,'hours').format('x');
            
            let hours = row.email48ActiveStatus==='true'?96:48; 
            
            let endStr = moment(endd, 'YYYY-MM-DD hh:mm A').add(hours,'hours').format('x');
            let endStr2 = moment(endd, 'YYYY-MM-DD hh:mm A').add(96,'hours').format('x');
            if(endStr > moment().format('x')){
              
                status = <span>{status} <br/><a type="button" className="notesBtn" onClick={e=>toggleModal(row)}>{row.sopNoteSaveType && row.sopNoteSaveType==='SUBMIT'?'View Notes':'Submit Notes'}</a></span>;
           
            }else if(hours==48 && endStr2 > moment().format('x') && row.sopNoteSaveType && row.sopNoteSaveType != 'SUBMIT'){
              
                status = <span>{status} <br/><a type="button" className="notesBtn" onClick={e=>{toastr.show({message: "Please contact the administrator to submit Therapy Notes.",intent: "warning"}); }}>{'Submit Notes'}</a></span>;
            }else if(row.sopNoteSaveType && row.sopNoteSaveType === 'SUBMIT'){
              
                status = <span>{status} <br/><a type="button" className="notesBtn" onClick={e=>toggleModal(row)}>View Notes</a></span>;                 
            }           
        //}else if(userType==='PATIENT' && row.sopNoteDesc && row.sopNoteDesc != ''){
        }else if(userType==='PATIENT' ){
            status = <span>{status} <br/><a type="button" className="notesBtn" onClick={e=>toggleModal(row)}>View Notes</a></span>;                       
      }
    }
    return status;
  }

  const onPageChange = (page, sizePerPage)=>{ 
      setPerPage(sizePerPage);
      setCurrentPage(page);
      getAppointmentList('', page, sizePerPage);
  }
  
  useEffect(()=>{
    getAppointmentList();
   
  },[]);

  return (
  <div>
    
    <MetaTags className="bg-rr">
      <title>Appointment History</title>
      <meta name="description" content="Recover" />
      <meta name="keywords" content="Recover" />
    </MetaTags> 
    <section className="dash_section pad-reggis future_appoinment_page appoiment_history">
      <BreadcrumbsComp bcChanges={[{path: '/appointment-history', breadcrumb: 'Appointment History'}]} />
      
      <div className="container">
        <div className="row">
          <div className="col-md-3 left_dashboard">
            <SideMenu/>
          </div>
          <div className="col-md-9 right_dashboard ">
            <div className="content-setion">
              <div className="section_title m-25"><h3>Appointment History</h3></div>
              <div className="search-availabilty gray_boxx">
                <div className="row">
                  <div className="col-md-3">
                    <label>Search</label>
                    <div className="to-from">
                      <input type="text" name="name" onChange={e =>setName(e.target.value)} placeholder="Type text..." value={name} />
                    </div>
                  </div>
                  <div className="col-md-3">              
                    <label>From</label>
                    <div className="to-from">
                      <DatePicker
                        //minDate={new Date()}
                        onChange={date =>(setFromValue(date?date:''),setToValue(toValue>=date?toValue:''))}    
                        selected={fromValue}     
                        placeholderText={'From date'}       
                        dateFormat={appConstants.DateFormatNew}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>To</label>
                    <div className="to-from">
                        <DatePicker
                          minDate={fromValue?fromValue:new Date()}           
                          onChange={date =>setToValue((fromValue?(date?date:''):''),setToError(''))} 
                          selected={toValue>=fromValue?toValue:''} 
                          placeholderText={'To date'}               
                          dateFormat={appConstants.DateFormatNew}
                        />
                    </div>
                    <small className="btn_sec text-center error">{toValue==''?toError:''}</small>
                  </div>
                  <div className="col-md-3">
                    <div className="to-from">
                      <button type="button" className="btn btn-primary" onClick={e=>getAppointmentList()}>Search</button>
                    </div>
                    <div className="to-from">
                      <button type="button" className="btn btn-primary" onClick={e=>getAppointmentList('reset')}>reset</button>
                    </div>
                  </div>
                </div>
              </div>
              <BootstrapTable data={ dataList } remote={ true } pagination={ true }
                      fetchInfo={ { dataTotalSize: totalSize } }
                      options={ { sizePerPage: perPage,
                                  onPageChange: onPageChange,
                                  sizePerPageList: [ 5, 10, 20, 50, 100 ],
                                  page: currentPage
                                  } }>
                <TableHeaderColumn dataField='_id' hidden={true} isKey>Id</TableHeaderColumn>
                
                {userType!=='CLINIC' && 
                <TableHeaderColumn width='100' dataField='' dataFormat={onProfilePic}>Profile Pic</TableHeaderColumn>
                }

                <TableHeaderColumn dataField='orderId' thStyle={{ width: '120px' }} tdStyle={{ width: '120px' }}>Appointment<br></br>ID</TableHeaderColumn>

                <TableHeaderColumn dataField='' dataFormat={dateTimeDetails} thStyle={{ width: '125px' }} tdStyle={{ width: '125px' }}>Appointment<br></br>Date & Time</TableHeaderColumn>

                {userType==='CLINIC' && 
                <TableHeaderColumn width='100' dataField='' dataFormat={onProfilePic}>Patient’s<br></br> Pic</TableHeaderColumn>
                }

                {userType!=='PATIENT' && 
                  <TableHeaderColumn dataField='paName' thStyle={{ width: '170px' }} tdStyle={{ width: '170px' }}>Patient Name</TableHeaderColumn>
                }
                              
                {userType!=='PT' && 
                  <TableHeaderColumn dataField='' dataFormat={nameDetails} thStyle={{ width: '170px' }} tdStyle={{ width: '170px' }}>PT Name <br></br>{userType==='PATIENT'?'(Clinic Name)':''}</TableHeaderColumn>
                }
                
                {userType==='PATIENT' && 
                  <TableHeaderColumn dataField='' dataFormat={paymentStatus} thStyle={{ width: '100px' }} tdStyle={{ width: '100px' }}>Payment<br></br>Status</TableHeaderColumn>
                }
            
                { userType === 'PATIENT' &&
                  
                  <TableHeaderColumn dataField="" dataFormat={priceFormatter} thStyle={{ width: '80px' }} tdStyle={{ width: '80px' }}>Amount</TableHeaderColumn>
                }
                <TableHeaderColumn dataField='' dataFormat={action}  thStyle={{ width: '130px' }} tdStyle={{ width: '130px' }}>Action</TableHeaderColumn>
              </BootstrapTable>              
            </div>
          </div>              
        </div>
      </div>
      
      <Modal isOpen={modal} className="Open-x note_popup" size="lg" toggle={toggleModal}>
        <ModalHeader  toggle={toggleModal} className="header-pop-unit">{modelView.sopView === false?'Submit Note':'View Notes'}</ModalHeader>
        <ModalBody className="height-full" >
          <container-fluid>
            <Row className="session_wrap">
              <div className="col-lg-12 session_left">
                <div className="session_topinner row">
                  <div className="col-lg-4">
                      <label>{userType=='PT'?'Patient Name':'PT Name'}</label>
                      <p id="name">{userType=='PT'?modelView.paName:modelView.ptName}</p>
                  </div>
                  <div className="col-lg-4">
                      <label>Appoinment Date</label>
                      <p id="appointmentDate">{ moment(modelView.date,"YYYY-MM-DD").format(appConstants.DATEFORMAT)}</p>
                  </div>
                  <div className="col-lg-4">
                      <label>Session Duration</label>
                      <p id="slot">{modelView.startTime+" - "+modelView.endTime}</p>
                  </div>                            
                </div>
                
                <div className="videotabs">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {userType!=='PATIENT' &&
                      <li className="nav-item waves-effect waves-light">
                        <a className={userType!=='PATIENT'?'nav-link active':'nav-link'} id="patientdocuments-tab" data-toggle="tab" href="#patientdocuments" role="tab" aria-controls="patientdocuments" aria-selected="false">Patient Documents</a>
                      </li>
                    }                 

                    {modelView.sopNoteSaveType !== 'SUBMIT' && modelView.sopView === false &&
                      <li className="nav-item waves-effect waves-light">
                        <a className="nav-link" id="ptnotes-tab" data-toggle="tab" href="#ptnotes" role="tab" aria-controls="ptnotes" aria-selected="false">Previous Therapist Notes</a>
                      </li>
                    }

                    {userType!=='PATIENT' && modelView.sopView === false &&
                      <li className="nav-item waves-effect waves-light">
                        <a className="nav-link" id="Submitnotes-tab" data-toggle="tab" href="#Submitnotes" role="tab" aria-controls="Submitnotes" aria-selected="true">Submit Notes</a>
                      </li>
                    }                    

                    {userType==='PATIENT' && 
                      <li className="nav-item waves-effect waves-light">
                        <a className='nav-link active' id="Submitnotes-tab" data-toggle="tab" href="#Submitnotes" role="tab" aria-controls="Submitnotes" aria-selected="true">Physical Therapy Notes</a>
                      </li>
                    }
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    {userType!=='PATIENT' &&
                      <div className={userType!=='PATIENT'?'tab-pane fade active show':'tab-pane fade'}  id="patientdocuments" role="tabpanel" aria-labelledby="patientdocuments-tab">
                        <div className="row document_sec">
                          <h5> Patient Documents </h5>
                          <PatientDocuments history={props.history} paDocs={modelView.paDocs?modelView.paDocs:''} />
                        </div>
                        <div className="row medicalhistory">
                          <h5> Medical History</h5>
                          <PatientMedicalHistory history={props.history} patId={modelView.paId} 
                          patFirstName={modelView.paFirstName?modelView.paFirstName:''} />
                        </div>
                      </div>
                    }

                    {modelView.sopNoteSaveType !== 'SUBMIT' && modelView.sopView === false &&
                      <div className="tab-pane fade" id="ptnotes" role="tabpanel" aria-labelledby="ptnotes-tab">
                          <PreviousNotesPage history={props.history} paId={modelView.paId} />
                      </div>
                    }

                    {userType!=='PATIENT' && modelView.sopView === false &&
                      <div className="tab-pane fade" id="Submitnotes" role="tabpanel" aria-labelledby="Submitnotes-tab">
                        <div className="session_note">                          
                          <NotesTemplatePage history={props.history} appId={modelView._id} getAppointmentList={getAppointmentList} fType={false}/>
                        </div>
                      </div>
                    }                    

                    {userType==='PATIENT' && 
                      <div className='tab-pane fade active show' id="Submitnotes" role="tabpanel" aria-labelledby="Submitnotes-tab">
                        <div className="session_note">                          
                          {modelView.sopNoteSaveType === 'SUBMIT' ?
                              <NotesTemplatePage history={props.history} appId={modelView._id} />
                            :
                            ' No Therapy Notes available!'
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Row>
          </container-fluid>
        </ModalBody>
      </Modal>
    </section>
    <div className="spinner" style={{display:spinner?'block':'none'}}>
      <img src="static/img/spinner.gif" alt='loader'/>
    </div>
  </div>
  );
}
export default withLocalize(withRouter(AppointmentHistory));
