import React,{useEffect} from 'react';
import { withLocalize } from 'react-localize-redux';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import {siteDetail} from '../../../_Constants/app.constants';

const FAQSPage = ({history}) => {

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
  },[]);

  return (
    <div>
    <MetaTags>
      <title>Help & FAQs</title>
      <meta name="description" content="To access e-INSCRIPTION’s services through a website or mobile app, you need an account on our platform. To create an account you have to provide accurate info" />
      <meta name="keywords" content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore " />
    </MetaTags>
    <section className="faq_page pad-reggis inner_pagewrap">
      <BreadcrumbsComp bcChanges={[{path: '/faqs', breadcrumb: 'Help & FAQs'}]} />
      
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-left">
            <div className="faq_wrap white_box">
              <div className="section_title page_ttl text-center">
                <h3>Help & FAQs</h3>
                <div className="decor2"></div>
              </div>
              <div id="accordion" className="accordion">
                <div className="card mb-0">
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapsefour">
                    <a className="card-title">
                    Q1) What does Recover do?
                    </a>
                  </div>
                  <div id="collapsefour" className="collapse" data-parent="#accordion" >
                    <div className="card-body">
                      <p><strong>Ans.</strong> Recover providers you with access to physical therapists in your place of choice. </p>
                    </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapsefive">
                    <a className="card-title">
                    Q2) What type of device will I need to use?
                    </a>
                  </div>
                  <div id="collapsefive" className="collapse" data-parent="#accordion" >
                    <div className="card-body">
                      <p><strong>Ans.</strong> You can use any electronic device allowing you to access the website Recover. Healthcare including laptop, PC, tablet, iPad, smart phone.</p>
                    </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapsesix">
                    <a className="card-title">
                    Q3) Who are the Recover physical therapists? 
                    </a>
                  </div>
                  <div id="collapsesix" className="collapse" data-parent="#accordion" >
                    <div className="card-body">
                      <p><strong>Ans.</strong> Recover physical therapists are licensed providers in the State of California. Each physical therapist is independently contracted with Recover.</p>
                    </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseseven">
                    <a className="card-title">
                    Q4) Can my family use my Recover account? 
                    </a>
                  </div>
                  <div id="collapseseven" className="collapse" data-parent="#accordion" >
                    <div className="card-body">
                      <p><strong>Ans.</strong> No.  Each user should have a separate account and log in information. Minors under the age of 18, must be accompanied by an adult.</p>
                    </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapseeight">
                    <a className="card-title">
                    Q5) Can I use Recover outside of California? 
                    </a>
                  </div>
                  <div id="collapseeight" className="collapse" data-parent="#accordion" >
                    <div className="card-body">
                      <p><strong>Ans.</strong> Each user must be a resident of California.  Each physical therapist is licensed in the State of California.  If a user travels outside of California, he or she may use Recover as long as the primary residence remains in California.</p>
                    </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsenine">
                     <a className="card-title">
                     Q6) How do I set up an account?  
                     </a>
                  </div>
                  <div id="collapsenine" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong> On the Recover home page, you must click “Register” on the header menu or the “Register Now” button on home screen.   Once you are registered, you may login using your email address and password you create during registration process.</p>
                     </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapseten">
                     <a className="card-title">
                     Q7) Do I need a referral to use Recover?
                     </a>
                  </div>
                  <div id="collapseten" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong> No.  As of January 1st 2014,  California law  under AB1000 allows licensed physical therapists to provide direct physical therapy evaluation and treatment services for a period of up to 45 calendar days or 12 visits, whichever occurs first, after which time a physical therapist may continue providing you with physical therapy treatment services only after receiving, from a person holding a physician or surgeon’s certificate issued by the Medical Board of California or by the Osteopathic Medical Board of California, or from a person holding a certificate to practice podiatric medicine from the California Board of Podiatric Medicine and acting within his or her scope of practice, a dated signature on the physical therapist’s plan of care indicating approval of the physical therapist’s plan of care and that an in-person patient examination and evaluation was conducted by the physician and surgeon or podiatrist.</p>
                     </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapseeleven">
                     <a className="card-title">
                     Q8) What is the benefit of Direct Access?
                     </a>
                  </div>
                  <div id="collapseeleven" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong> Direct Access benefits our patients in that it allows you to expedite treatment, saving time and money. Eliminate delays that can result in decreased functional outcomes and frustration Allows for earlier return to work and an earlier return to daily activities Reduces the need for long-term care by providing early intervention Studies show that immediate care for most injuries can reduce the healing time We can refer you to a licensed MD, DPM, or other medical professional for conditions/symptoms outside our scope of practice.</p>
                     </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwel">
                     <a className="card-title">
                     Q9) If I have a referral/prescription.  Can I upload it to Recover so my therapist can see the doctor’s recommendation?
                     </a>
                  </div>
                  <div id="collapsetwel" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong> Yes,  Recover allows users to upload any supporting documents.  Physical therapists will be able to view your uploaded documents.</p>
                     </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsethirteen">
                     <a className="card-title">
                     Q10) If I have imaging results or an op report can I upload it to Recover so the therapist can see the documents?  
                     </a>
                  </div>
                  <div id="collapsethirteen" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong> Yes,  Recover allows users to upload any supporting documents.  Physical therapists will be able to view your uploaded documents.</p>
                     </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsefourteen">
                     <a className="card-title">
                     Q11) If I have past physical therapy notes I would like my Recover physical therapist to review, can I share these with my Recover physical therapist?  
                     </a>
                  </div>
                  <div id="collapsefourteen" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong> Yes,  Recover allows users to upload any supporting documents.  Physical therapists will be able to view your uploaded documents.</p>
                     </div>
                  </div>
                   <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsefiftin">
                     <a className="card-title">
                     Q12) How much does it cost?  
                     </a>
                  </div>
                  <div id="collapsefiftin" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong> The initial evaluation (first visit per case) is 60 minutes, and the price is $155.  Follow up appointments can be scheduled for 30 or 60 minutes.   30 minute follow up appointments are $80, and 60 minute follow up appointments are $130.</p>
                     </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsesisteen">
                     <a className="card-title">
                     Q13) Is there a time limit on visits?
                     </a>
                  </div>
                  <div id="collapsesisteen" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong> Yes, visits will be scheduled in 60 minute or 30 minute time slots.  Recover users will receive a notification near the end of the scheduled visit that time is running out.  If the patient has a 30 minute visit scheduled and both the therapist and the patient have the availability to continue for an additional 30 minutes, Recover will allow the time extension.  </p>
                     </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapseseventeen">
                     <a className="card-title">
                     Q14) Does Recover bill insurance companies?
                     </a>
                  </div>
                  <div id="collapseseventeen" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong> No, Recover collects payment from the patient based on the type of visit and length of visit.  Patients may request a copy of their notes to inquire with their insurance company about submitting for reimbursement.</p>
                     </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapseeighteen">
                     <a className="card-title">
                     Q15) What is your patient cancelation policy?
                     </a>
                  </div>
                  <div id="collapseeighteen" className="collapse" data-parent="#accordion">
                     <div className="card-body">
                        <p><strong>Ans.</strong> Each patient gets a “free” cancellation, but the next time the patient cancels less than 24 hours in advance and does not reschedule within 5 days of the original appointment, the patient will be charged 50% of the booking fee.  </p>
                     </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsenineteen">
                     <a className="card-title">
                     Q16) How does Recover protect my privacy? 
                     </a>
                  </div>
                  <div id="collapsenineteen" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong> Recover is committed to protecting the privacy of every individual who uses our services.  We comply with the relevant state, national and international laws and regulations, including the US Health Insurance Portability and Accountability Act of 1996, known as HIPAA.  Unless permitted or required by law, your medical information will not be shared without your consent.  On occasion, we may use de-identified information to help us improve our clinical programs.  </p>
                     </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwenty">
                     <a className="card-title">
                     Q17) Where is my health information stored?   
                     </a>
                  </div>
                  <div id="collapsetwenty" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong> If you choose to share your medical information with Recover, we encrypt and store your data on our HIPAA compliant secure servers.  Reference our <a href="/privacy-policy">Privacy Policy</a>.</p>
                     </div>
                  </div>
                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwntyone">
                     <a className="card-title">
                     Q18) How do I contact Recover?   
                     </a>
                  </div>
                  <div id="collapsetwntyone" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong>
                        Email: <a href={'mailto:'+siteDetail.email}>{siteDetail.email}</a>, 
                        phone: <a href={'tel:+1'+siteDetail.phone}>{siteDetail.phone}</a>, 
                        Address: {siteDetail.address}</p>
                     </div>
                  </div>

                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwntytwo">
                     <a className="card-title">
                     Q19) How do I start my video visit? 
                     </a>
                  </div>
                  <div id="collapsetwntytwo" className="collapse" data-parent="#accordion" >
                     <div className="card-body">
                        <p><strong>Ans.</strong>
                        a.  Patient: log into your Recover account and select the Future Appointments tab in the far left column.  Scroll down to turquoise box then scroll right to action column.  Click the Join Session button.  You will receive a message saying “please wait.” Once your therapist starts the meeting at the scheduled appointment time, you will be able to join the session.<br/><br/>
                        
                        b.  Physical Therapist: log into your Recover account and select the Future Appointments tab in the far left column.  Scroll down to turquoise box then scroll right to action column.  Click  the Start Session button.  You will receive a message saying “please wait.”  Once your patient has entered your waiting room, and it is the scheduled appointment time, the “Start Session” button will become available again.  Click Start Session. 

                        </p>
                     </div>
                  </div>

                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwntyfour">
                     <a className="card-title">
                     Q20) What types of notification will I receive for my visit? 
                     </a>
                  </div>
                  <div id="collapsetwntyfour" className="collapse" data-parent="#accordion">
                     <div className="card-body">
                        <p><strong>Ans.</strong>
                          Once a patient books an appointment with a physical therapist, the physical therapist will receive an email and a text notification asking the physical therapist to approve the request.  Once approved, both the patient and the physical therapist will receive an email and text confirming the scheduled appointment.
                        </p>
                     </div>
                  </div>

                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwntyfive">
                     <a className="card-title">
                     Q21) How will I know if my microphone and camera are working correctly? 
                     </a>
                  </div>
                  <div id="collapsetwntyfive" className="collapse" data-parent="#accordion">
                     <div className="card-body">
                      <p><strong>For a better video session experience please use Chrome/Firefox browsers on Android/Windows OS, and Chrome/Safari browsers on iOS devices/Mac Machine.</strong></p>
                         <p> <strong>Ans.</strong> a. Confirm the hardware is correctly attached and ensure the correct
                          microphone and speakers are selected in software settings.</p>
                      
                         <p>b. Check to ensure the microphone is not muted (some have a hardware mute 
                          button) and the speaker volume is turned up.</p>
                  
                          <p>c. When the user enters the session page, the website will ask for allowing 
                          permission for the camera and microphone, and the user will need to be allowed to
                          get into the video session.</p>

                          <p><strong>If the video/audio is not working even after permission, the user can check the points given below,</strong></p>
                           <p>a. User can click on the video icon sign-in URL or mobile notification and check if the proper audio/video option is selected for camera and Microphone and permission is allowed</p>
                           <p>b. User must go to browser settings > Site setting/permission > Camera/microphone and check option must be enabled to "Allow to Ask" (Check the appropriate camera and microphone device is selected, and the site URL should not be blocked for both options.)
                            </p>
                             <p>c. The user can go to the device settings (Apps or Apps Permission) and check camera and microphone options for specific browsers. The camera and microphone must be allowed by the mobile browsers to access the site.</p>
                       </div>
                  </div>

                 <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwntysix">
                     <a className="card-title">
                     Q22) How do I submit a physical therapy note?
                     </a>
                  </div>
                  <div id="collapsetwntysix" className="collapse" data-parent="#accordion">
                     <div className="card-body">
                        <p><strong>Ans.</strong>
                         During session, click the “Submit Notes” button under the video box. 
                         You will be able to select the type of note: Initial Evaluation, 
                         Progress Note, Daily Note, Discharge Note, or No Show.  
                         You will be able to enter data and save the note to submit later, 
                         or you will be able to submit the note during your session.  
                         If you choose to save the note and submit the note later, 
                         you will have 48 hours after the appointment to submit the note.  
                         You will receive a notification 24 hours after the appointment 
                         reminding you to submit the note.  If the note has not been submitted 
                         at that time, you will receive another notification at the 47 hour mark 
                         reminding you to submit the note.  Payment will be released to your account 
                         after the note is submitted.  If you are not able to submit the note within 
                         48 hours, you must email administration at <a href={'mailto:'+siteDetail.email}>{siteDetail.email}</a> to activate the note submission. 
                        </p>
                     </div>
                  </div>


                 <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwntyseven">
                     <a className="card-title">
                     Q23) How do I view Past Notes?  
                     </a>
                  </div>
                  <div id="collapsetwntyseven" className="collapse" data-parent="#accordion">
                     <div className="card-body">
                        <p><strong>Ans.</strong>
                         a. Patient: Log into your Recover account and click Account Information in the top header.  Next, click Appointment/Payment History in the far-left column. Scroll right to the Action tab and you will be able to click View Notes per appointment.  You will also have access to view any past notes during your PT session.  To view the notes during session, click the Previous Therapist Notes button under the video window.<br/><br/> 
                         b. Physical Therapist: Log into your Recover account and click Account information in the top header.  Next, click Appointment History in the far-left column. Scroll right to the Action tab and you will be able to click View Notes per appointment.  You will also have access to view any past notes on the patient during your PT session.  To view the notes during session, click the Previous Therapist Notes button under the video window.
                        </p>
                     </div>
                  </div>

                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwntyeight">
                     <a className="card-title">
                     Q24) How do I obtain a copy of my note as a patient?  
                     </a>
                  </div>
                  <div id="collapsetwntyeight" className="collapse" data-parent="#accordion">
                     <div className="card-body">
                        <p><strong>Ans.</strong>
                         You will be able to view past notes in  Appointment/Payment History.
                          You may use your default print feature (Ctl +P) to print.  
                          You may also email <a href={'mailto:'+siteDetail.email}>{siteDetail.email}</a> to obtain a copy of your note.
                        </p>
                     </div>
                  </div>

                   <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwntynine">
                     <a className="card-title">
                     Q25) What should I do if I need to cancel a scheduled visit?   
                     </a>
                  </div>
                  <div id="collapsetwntynine" className="collapse" data-parent="#accordion">
                     <div className="card-body">
                        <p><strong>Ans.</strong>
                        Log into your Recover account and click Account Information in the top header.  Next, click on the Future Appointments tab in the far-left menu.  You will see a list of your scheduled appointments. 
                        In the Action tab, you will be able to cancel your appointment.  
                        </p>
                     </div>
                  </div>

                  <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwntytyty">
                     <a className="card-title">
                     Q26) How do I change the payment method on file? 
                     </a>
                  </div>
                  <div id="collapsetwntytyty" className="collapse" data-parent="#accordion">
                     <div className="card-body">
                        <p><strong>Ans.</strong>
                        Log in to your Recover account and click Account Information in the top header.  Next click My Account Information in the far-left menu.  Scroll down and click on Card Information.  You will be able to add or delete cards.  If there are multiple cards on the account, you will be able to select which card to use as the primary card.  
                        </p>
                     </div>
                  </div>

                   <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                     href="#collapsetwntytyone">
                     <a className="card-title">
                     Q27) How do I complete my medical history? 
                     </a>
                  </div>
                  <div id="collapsetwntytyone" className="collapse" data-parent="#accordion">
                     <div className="card-body">
                        <p><strong>Ans.</strong>
                         Log into your Recover account and click on My Account Information in the far-left menu.  Scroll down and click on Medical History.  Next you will need to click on the Edit button to add or change your medical history information.  Click Save when edits have been made. 
                        </p>
                     </div>
                  </div>

                </div>
               </div>
 {/*             <h4>Questions to Be Answered in the Future</h4>
              <ol>
                <li>How do I start my video visit?</li>
                <li>What types of notification will I receive for my visit?</li>
                <li>How will I know if my microphone and camera are working correctly?</li>
                <li>What should I do if I need to cancel a scheduled visit?</li>
                <li>What will happen after I schedule a visit?</li>
                <li>How do I change the payment method on file?</li>
                <li>How do I complete my medical history?</li>
              </ol>*/}
            </div>
          </div>
        </div>
      </div>
    </section> 
    </div>
  );
}
export default withLocalize(FAQSPage);
