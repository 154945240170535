import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { Dialog } from "@blueprintjs/core";
import './HeaderPage.css';
import moment from 'moment';

const AutoLogOff = (props) => {
 
  localStorage.setItem("currentUrl", props.location.pathname);

  const [autoLogout,setAutoLogout] = useState(false);

  const onLogout = async () =>{

    setAutoLogout(false);
    props.onLogout();
   
  }

  const setOutTime = async (time='') =>{   
     
      if(localStorage.getItem("currentUrl") != '/session'){
         
          if(localStorage.getItem("isPopOpen") == 'true' && time>300){
              
                time = 299;
          }
          localStorage.setItem("sessionOutTime", (time!=''?time:1200));
          if(localStorage.getItem("isActive") == 'true'){
            
              localStorage.setItem("lastActiveTime", moment().format('YYYY-MM-DD H:m:s'));
          }
      }
  }

  const checkSession = ()=>{ 
  		if(localStorage.getItem("currentUrl") != '/session' && localStorage.getItem("lastRemain") && localStorage.getItem("lastRemain")>0){

	        let b = moment(localStorage.getItem("lastActiveTime"),'YYYY-MM-DD H:m:s').format('x');
	           
	        let a = moment().format('x');     
	           
	        var s = (a-b)/1000;
	           
	        let lastRemain = localStorage.getItem("lastRemain");
	        let r = lastRemain - s;

	        localStorage.setItem("isActive", 'true');      
	        localStorage.setItem("lastActiveTime", moment().format('YYYY-MM-DD H:m:s'));          	        

	        if(s >= lastRemain){	              
	            onLogout();
	        }else if(r <= 300){
                localStorage.setItem("lastRemain", r);
	            openAutoLogout();
	            setOutTime(r);
	        }else{
                localStorage.setItem("lastRemain", r);
	            setOutTime(r);
	        }
	    }
  }

if(localStorage.getItem("RecoverWebStatus") && localStorage.getItem("currentUrl") != '/session'){

    window.addEventListener("mouseover", () => {
      
        if(localStorage.getItem("isPopOpen") == 'false'){
            setOutTime();
        }        
    });

    window.addEventListener("click", () => {    
        if(localStorage.getItem("isPopOpen") == 'false'){
            setOutTime();
            
        } 
    });   
    window.addEventListener("focus", () => {  

      	checkSession();        
    });  

     
    window.addEventListener("blur", () => {  

      if(localStorage.getItem("currentUrl") != '/session'){
          localStorage.setItem("lastActiveTime", moment().format('YYYY-MM-DD H:m:s'));          
          localStorage.setItem("lastRemain", localStorage.getItem("sessionOutTime"));
          localStorage.setItem("isActive", 'false');
      }
    });  

    window.addEventListener("beforeunload", () => {  

      if(localStorage.getItem("currentUrl") != '/session'){
      	  localStorage.setItem("sleepMode", 'true');   
      	  localStorage.setItem("lastActiveTime", moment().format('YYYY-MM-DD H:m:s'));          
          localStorage.setItem("lastRemain", localStorage.getItem("sessionOutTime"));
          localStorage.setItem("isActive", 'false');         
      }
    });  
}

const openAutoLogout = async (str='') =>{
  if(str == 'stay'){
        localStorage.setItem("isPopOpen", 'false');
        setOutTime();
        setAutoLogout(false);
  }else{
      console.log('Bhim');
	    localStorage.setItem("isPopOpen", 'true');
	    setAutoLogout(true);
  }    
}

  useEffect(()=>{
    
    if(localStorage.getItem("RecoverWebStatus") && localStorage.getItem("currentUrl") != '/session'){

        setOutTime();        
        if(localStorage.getItem("sleepMode") == 'true'){
        	checkSession(); 
        }else{
        	
        	localStorage.setItem("isPopOpen", 'false');
	        localStorage.setItem("lastActiveTime", moment().format('YYYY-MM-DD H:m:s'));
	        localStorage.setItem("isActive", 'true');
	        localStorage.setItem("sleepMode", 'false'); 
        }
        
        setInterval(() => {
            if(localStorage.getItem("currentUrl") != '/session'){
                let outTime = localStorage.getItem("sessionOutTime")-1;
                setOutTime(outTime);
                if(document.getElementById("remainTime")){
                    document.getElementById("remainTime").innerHTML = moment.utc(outTime*1000).format('mm:ss'); 
                }
                if(outTime <= 300 && outTime >= 299){
                    openAutoLogout();
                }
                if(outTime <= 1){
                   onLogout();                    
                }
            }
        },1000);
    }

  },[]);


  return (
    <Dialog
        className="form_designpage auto-logout-box" onClose={e=>openAutoLogout('stay')}
        title="" canOutsideClickClose={false} isOpen={autoLogout}>
           <div className="text-center">
                  <span id="remainTime"></span> Minutes!<br></br>
                  Due to inactivity, your session will be timed out. Please click below to stay logged in.
                  <div className="hader_btns"> 
                  <ul>
                      <li className="login_link">
                          <Link to="#" className="hbtn nav-link js-scroll-trigger" onClick={e=>openAutoLogout('stay')}><span>Stay Logged in</span></Link>
                      </li>
                      <li className="logout_link">
                        <Link to="#" className="hbtn nav-link js-scroll-trigger" onClick={e=>onLogout()}><span>Log Off</span></Link>
                      </li>
                  </ul>
              </div>
           </div>
           
      </Dialog>
  );
}
export default withLocalize(withRouter(AutoLogOff));