import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import SideMenu from '../../Components/DashboardPage/SideMenu';
import moment from 'moment';
import {getPaymentTransactionsList } from '../../Actions/User.action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {appConstants, getLocalDate, toUTC} from '../../../_Constants/app.constants';
import {BootstrapTable,TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import MetaTags from 'react-meta-tags';

const PaymentHistory = (props) => {
  const [dataList, setAppointmentList] = useState([]);
  const [toValue,setToValue] = useState('');
  const [fromValue,setFromValue] = useState('');
  const [name, setName] = useState('');
  const [toError, setToError] = useState('');
  const [spinner,setSpinner] = useState(false);
  const [totalAmount,setTotalAmount] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

 const getAppointmentList = async(search='', page='', limit='')=>{
        if(search == 'reset'){
          setFromValue('');
          setToValue('');
          setName('');
          setToError('');
        }

        if(fromValue && fromValue !== '' && toValue === ''){
          setToError('Select to date!');
        }else{
            setToError('');
            setSpinner(true);
            let dataList = await getPaymentTransactionsList({startDate:fromValue && search != 'reset'?toUTC(getLocalDate(fromValue)):'',endDate:toValue && search != 'reset'?toUTC(moment(getLocalDate(toValue),'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')):'', name: search != 'reset'?name:'', page:(page!=''?page:currentPage)-1, limit:limit!=''?limit:perPage});     
            
            let list = dataList.data;
            
            setTotalSize(dataList.count);
            list.map(item=>{
              item.date = moment(item.startTime).format('YYYY-MM-DD');
              item.startTime = moment(item.startTime).format('hh:mm A');
              item.endTime = moment(item.endTime).format('hh:mm A');
              return item;
          })
            setAppointmentList(list);            
            setTotalAmount(dataList.totalAmount.toFixed(2));
            setSpinner(false);
        }
    }

    const dateTimeDetails=(cell,row)=>{   
    return (<span>{moment(row.date, 'YYYY-MM-DD').format(appConstants.DATEFORMAT)},<br></br>{row.startTime+'-'}{row.endTime}, ({row.duration}Min)</span>);
    }

    const priceFormatter=(cell, row)=>{
      if(row.ptTransferStatus === 'true'){
        return `$${row.ptTransferAmount?row.ptTransferAmount:''}`;
      }else{
        return '-';
      }
    }

    const appointmentStatus=(cell,row)=>{
      
      let status = row.status;
      if(status === 'cancelByPA' || status === 'cancelByPT'){      
          status = row.noShowStatus && row.noShowStatus == 'true'?'No Show by PT':'Cancelled by '+(status === 'cancelByPA'?'Patient':'Therapist');
          status = status+(row.cancelAmountStatus == 'true'?' (<24)':'');
      }else if(status === 'REJECT'){
          status = 'REJECTED';
      }else if(status === 'FINISHED'){
          status = row.noShowStatus && row.noShowStatus == 'true'?'No Show by Patient':'COMPLETED';            
      }
      return status;
  }
  const paymentStatus=(cell,row)=>{
      let status = row.ptTransferStatus;
      if(status === 'true'){
        status = 'Succeed'; 
      }else{
        status = 'Pending'; 
      }
      return status;        
  }

const onPageChange = (page, sizePerPage)=>{ 
    setPerPage(sizePerPage);
    setCurrentPage(page);
    getAppointmentList('', page, sizePerPage);
}

    useEffect(()=>{
      getAppointmentList();
    },[]);

    return (

  <div>
    <MetaTags className="bg-rr">
      <title>Payment History</title>
      <meta name="description" content="Recover" />
      <meta name="keywords" content="Recover" />
    </MetaTags> 
    <section className="dash_section pad-reggis future_appoinment_page">
      <BreadcrumbsComp bcChanges={[{path: '/payment-history', breadcrumb: 'Payment History'}]} />

      <div className="container">
          <div className="row">
            <div className="col-md-3 left_dashboard">
              <SideMenu/>
            </div>
            <div className="col-md-9 right_dashboard ">
             <div className="content-setion ">
             <div className="section_title m-25"><h3>Payment History</h3></div>
            <div className="search-availabilty gray_boxx">
            <div className="row">
            <div className="col-md-3">
                <label>Search</label>
                <div className="to-from">
                    <input type="text" name="name" onChange={e =>setName(e.target.value)} placeholder="Type text..." value={name} />
                </div>
            </div>
            <div className="col-md-3">              
                <label>From</label>
                <div className="to-from">
                  <DatePicker
                    //minDate={new Date()}
                    onChange={date =>setFromValue(date?date:'')}
                    selected={fromValue}     
                    placeholderText={'From date'}       
                    dateFormat={appConstants.DateFormatNew}
                  />
                </div>
              </div>
              <div className="col-md-3">
                  <label>To</label>
                  <div className="to-from">
                      <DatePicker
                        minDate={fromValue?fromValue:new Date()}           
                        onChange={date =>setToValue((fromValue?(date?date:''):''),setToError(''))} 
                        selected={toValue>=fromValue?toValue:''} 
                        placeholderText={'To date'}               
                        dateFormat={appConstants.DateFormatNew}
                      />
                  </div>
                  <small className="btn_sec text-center error">{toValue==''?toError:''}</small>
              </div>
              <div className="col-md-3">
                  <div className="to-from">
                      <button type="button" className="btn btn-primary" onClick={e=>getAppointmentList()}>Search</button>
                  </div>
                  <div className="to-from">
                      <button type="button" className="btn btn-primary" onClick={e=>getAppointmentList('reset')}>reset</button>
                  </div>
              </div>
              </div>
            </div>
            <div className="pull-right total-amount"><b>Total Amount - $ {totalAmount}</b></div>
            <BootstrapTable data={ dataList } remote={ true } pagination={ true }
                      fetchInfo={ { dataTotalSize: totalSize } }
                      options={ { sizePerPage: perPage,
                                  onPageChange: onPageChange,
                                  sizePerPageList: [ 5, 10, 20, 50, 100 ],
                                  page: currentPage
                                  } }>
                  <TableHeaderColumn dataField='_id' hidden={true} isKey>Id</TableHeaderColumn>                 

                  <TableHeaderColumn dataField='orderId' thStyle={{ width: '120px' }} tdStyle={{ width: '120px' }}>Appointment<br></br>ID</TableHeaderColumn>
                  
                  <TableHeaderColumn dataField='' dataFormat={dateTimeDetails} thStyle={{ width: '125px' }} tdStyle={{ width: '125px' }}>Appointment<br></br>Date & Time</TableHeaderColumn>
                
                  {/*<TableHeaderColumn dataField="" dataFormat={timeDetails}>Time</TableHeaderColumn>*/}
                  {localStorage.getItem("userType")!=='PT' && 
                    <TableHeaderColumn dataField='ptName'>PT<br></br>Name</TableHeaderColumn>
                  }
                  <TableHeaderColumn dataField='paName'>Patient<br></br>Name</TableHeaderColumn>

                  <TableHeaderColumn dataField='' dataFormat={appointmentStatus}>Appointment<br></br>Status</TableHeaderColumn>
                  <TableHeaderColumn dataField='' dataFormat={paymentStatus}>Payment<br></br>Status</TableHeaderColumn>

                  <TableHeaderColumn dataField="" dataFormat={priceFormatter}>Amount</TableHeaderColumn>

                </BootstrapTable>              
              </div>
            </div>              
          </div>
      </div>
    </section>
    <div className="spinner" style={{display:spinner?'block':'none'}}>
        <img src="static/img/spinner.gif" alt='loader'/>
      </div>
  </div>
  );
}
export default withLocalize(withRouter(PaymentHistory));
