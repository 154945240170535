import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {getBlogListAction} from '../../Actions/User.action';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import moment from "moment";

const BlogPage = ({history}) => {
  const [blogList,setBlogList] = useState({});

  const getBlogListFunction = async()=>{
    let blogData = await getBlogListAction();   
    setBlogList(blogData);
  }

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
    getBlogListFunction();
  },[]);

  return (
    <div>
      <MetaTags>
        <title>Blog</title>
        <meta name="description" content="We are working professionally - to put diversified professional’s extra mile ahead; to make e-learning more efficient, easy and accessible to everyone, anywhere and anytime" />
        <meta name="keywords" content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore" />
      </MetaTags>

      <section className="pad-reggis">
        <BreadcrumbsComp />

        <div className="container">
          <div className="row">
            <div  className="col-md-12">
              <div className="col section_title text-center">
                <h3>Blog</h3>
                <div className="decor"></div>
              </div>
            </div>

            <div className="col-md-8">
              {blogList && blogList.data && blogList.data.map((item, index)=>(
                <div className="bol-portion" key={index}>
                  <div className="img-wi">
                    <img
                     src={`${process.env.REACT_APP_STATIC_S3_URL+item.blogImg}`}
                     alt="example"
                     onError={(e) => {
                        e.target.src = "static/img/blog-img.jpg"
                        e.target.style = ''
                     }}
                    />
                  </div>
                  <div className="inline-sect">
                    <div className="calendar-sec">
                      <div className="blog-month">
                        {item.createdAt?moment(item.createdAt).format("MMMM"):'-'}
                      </div>
                      <div className="blog-date">{item.createdAt?moment(item.createdAt).format("D"):'-'}</div>
                    </div>
                    <div className="blog-part-text">
                      <h4>{item.name?item.name:'-'}</h4>
                      <p>
                        {item.desc && item.desc.length > 200 ?(item.desc.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ')).substring(0, 200)+'....':''}
                        {item.desc && item.desc.length < 200 ?item.desc.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' '):'-'}
                      </p>
                      <Link to={{pathname:"blog-detail", state:{id:item._id} }} className="btn btn-primary js-scroll-trigger">Read More</Link>
                    </div>
                  </div>
                </div>
              ))}
            </div> 

            <div className="col-md-4">
              <div className="right-sect-blog">
                <div className="recent-heading">
                  <h4>Recent Post</h4>
                </div>
                <div className="recent-b-lidt">
                  <ul>
                    {blogList && blogList.data && blogList.data.map((item, ind) => {
                      if (ind < 5) {
                        return (
                          <li key={ind}>
                            <Link to={{pathname:"blog-detail", state:{id:item._id} }}>
                              {item.name?item.name:'-'}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div> 
            </div>  
          </div>
        </div>
      </section>
    </div>
  );
}
export default withRouter(BlogPage);
