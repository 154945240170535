import React,{useState,useEffect} from 'react';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import toastr from "../../../_Common/toaster";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {appConstants} from '../../../_Constants/app.constants';
import LocationSearchInput from '../../../_Common/GeoAddress';
import {FormError,ShowFormErrors} from "../../../_Common/formError";
import { getAccountListAction, addAccountAction, merchantCategoryCodeListAction, updateAccountAction, payoutFileUploadsAction, deleteAccountAction } from '../../Actions/User.action';
import $ from 'jquery';
import moment from "moment";
import SideMenu from '../DashboardPage/SideMenu';
import NumberFormat from 'react-number-format';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import { confirmAlert } from 'react-confirm-alert'; 
import GeoAddressComponent from '../../../_Common/GeoAddressManage';

const AccountCreatePage = ({history}) => {
  const [formData,setFormData] = useState({});
  const [formDataErrors,setFormDataErrors] = useState({});
  const {register, handleSubmit, setValue, errors, setError, reset } = useForm();
  const [accountStatus,setAccountStatus] = useState(false);
  const [accountDetail,setAccountDetail] = useState(false);
  const [userDetailStatus,setUserDetailStatus] = useState(false);
  const [mccList,setMccList] = useState(false);
  const [mccValue,setMccValue] = useState();
  const [dobValue, setDobValue] = useState();
  const [spinner,setSpinner] = useState(false);
  const [valueReset,setValueReset] = useState(true);
  const [adsStatus,setAdsStatus] = useState(true);

  useEffect(()=>{
    mccListFunction();
    getAccountListFunction();
    document.documentElement.scrollTop = 0;
  },[]);

  const mccListFunction = async()=>{
    let res = await merchantCategoryCodeListAction();
    if(res.success && res.data && res.data.length > 0){
      setMccList(res.data);
    }
  }  
  
  const getAccountListFunction = async()=>{
    let res = await getAccountListAction();
    if(res.success && res.data && res.data.length > 0){
      setAccountStatus(true);
      setAccountDetail(res.data[0]);
      if(res.data[0] && res.data[0].userInfo && res.data[0].userInfo!==''){
        setUserDetailStatus(true);
        setFormData(res.data[0].userInfo)

        let dob_day = res.data[0].userInfo.dob_day?res.data[0].userInfo.dob_day:'-';
        let dob_month = res.data[0].userInfo.dob_month?res.data[0].userInfo.dob_month:'-';
        let dob_year = res.data[0].userInfo.dob_year?res.data[0].userInfo.dob_year:'-';
        let dobDateValue = dob_year + '-' + dob_month + '-' + dob_day;
        dobDateValue = dobDateValue?new Date(moment(dobDateValue, 'YYYY-MM-DD')):'-';
        setDobValue(dobDateValue);
        $('input, select').prop('disabled', true);
      }else{
        formData['first_name'] = localStorage.getItem("firstName")?localStorage.getItem("firstName"):'';
        formData['last_name'] = localStorage.getItem("lastName")?localStorage.getItem("lastName"):'';
        formData['phone_number'] = localStorage.getItem("phone")?localStorage.getItem("phone"):'';
        formData['email'] = localStorage.getItem("email")?localStorage.getItem("email"):'';
        setMccValue("8099");//For default industry->Medical service
        if(localStorage.getItem("dob") && localStorage.getItem("dob")!==undefined){
          setDobValue(new Date(localStorage.getItem("dob")));
        }
        setFormData({...formData});
      }
    }
  }

  const inputHandler = (e)=>{
    if(e.target.name === 'industry' ){
      setMccValue(e.target.value);
    }else{
      let formDataNew = formData;
      if(e.target.name === 'phone_number' && e.target.value.replace(/[-]/g, '').trim().length === 10){
        $('input[name="phone_number"]').removeClass("requiredClass");
      }
      formDataNew[e.target.name] = e.target.value;
      setFormData({...formData,...formDataNew});
    }
  }

  const fileChangedHandler=(event, elename)=>{
    event.preventDefault();
    let formDataNew = formData;
    let file = event.target.files[0];
    if(!file && file===undefined){
      formDataNew[elename+'Preview'] = '';
      formDataNew[elename] = '';
      setFormData({...formData,...formDataNew});
      return false;
    }
    var fileName = (file && file.name?file.name:'');
    let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
    if (extensions == 'bmp' || extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg'){
        formDataNew[elename+'Preview'] = URL.createObjectURL(event.target.files[0]);
        formDataNew[elename] = event.target.files[0];
        setFormData({...formData,...formDataNew});
    }else if(extensions == 'docx'|| extensions == 'doc'){
        formDataNew[elename+'Preview'] = '/static/img/docIcon.png';
        formDataNew[elename] = event.target.files[0];
        setFormData({...formData,...formDataNew});
    }else if(extensions == 'pdf'){
        formDataNew[elename+'Preview'] = '/static/img/pdfIcon.png';
        formDataNew[elename] = event.target.files[0];
        setFormData({...formData,...formDataNew});
    }else {
      let err = [{"msg": "Extensions doesn't match","param": elename}];
      setFormDataErrors(FormError(err));
    }
  }

  const onAccountCreate = async () =>{
    setSpinner(true);   
    let res = await addAccountAction(formData);
    if(res.success){
      getAccountListFunction();
      setSpinner(false);
      setAccountStatus(true)
      setUserDetailStatus(false)
      toastr.show({message: "Bank Account Added Successfully",intent: "success"});
    }else if(res.success===false){
      setSpinner(false);
      toastr.show({message: res.msg,intent: "danger"});
    }
  }

  const onAccountUpdate = async () =>{
    setSpinner(true);
    formData['phoneCode'] = "+1";
    formData['dob_day'] = dobValue?new Date(dobValue).getDate():'';
    formData['dob_month'] = dobValue?new Date(dobValue).getMonth() + 1:'';
    formData['dob_year'] = dobValue?new Date(dobValue).getFullYear():'';
    formData['phone_number'] = formData['phone_number']?formData['phone_number'].replace(/\D/g,''):'';
    formData['acct_id'] = accountDetail.accId?accountDetail.accId:'';
    formData['address1'] = formData && formData.address?formData.address:'';
    formData['postal_code'] = formData && formData.zipCode?formData.zipCode:'';
    formData['industry'] = mccValue && mccValue?mccValue:'';
    
    let frontImg = '';
    let backImg = '';
    if(formData.photoFront && formData.photoFront!==''){
      let resFront = await payoutFileUploadsAction(formData.photoFront);
      if(resFront.success){
        frontImg = resFront.data.id?resFront.data.id:'';
      }
    }
    if(formData.photoBack && formData.photoBack!==''){
      let resBack = await payoutFileUploadsAction(formData.photoBack);
      if(resBack.success){
        backImg = resBack.data.id?resBack.data.id:'';
      }
    }
    if(frontImg && frontImg!==''){
      formData['front'] = frontImg;
      formData['back'] = backImg;
      let res = await updateAccountAction(formData);
      if(res.success){
        setSpinner(false);
        getAccountListFunction();
        toastr.show({message: "Account Added Successfully",intent: "success"});
      }else if(res.success===false){
        setSpinner(false);
        toastr.show({message: res.msg,intent: "danger"});
      }
    }
  }

  const deletePayoutAccount =(e)=>{
    if(accountDetail && accountDetail._id!=='' && accountDetail._id!==undefined){
      confirmAlert({
        title: 'Confirm to Remove',
        message: 'Are you sure you want to delete the existing banking account?',
        buttons: [{
            label: 'Yes',
            onClick: async() => {       
              let res = await deleteAccountAction({_id :accountDetail._id});  
              if(res.success){    
                setAccountStatus(false);
                setFormData({});
                toastr.show({message: res.msg,intent: "success"}); 
              }
            }
          },
          {label: 'No',}
        ]
      });
    }
  }

  const setResetForm = (e)=>{
    window.location.reload();
	/*reset();
	setFormData({});
    setDobValue('');
    setValueReset(false);
    setTimeout(function() {
      setValueReset(true)
      $("#industryId").val(mccValue);
    }, 1000);*/
  }

  return (
    <div>
      <MetaTags className="bg-rr">
          <title>Add Account</title>
          <meta name="description" content="Recover" />
          <meta name="keywords" content="Recover" />
      </MetaTags>    
      <section className="dash_section pad-reggis add_clinic_pt_page">
        <BreadcrumbsComp bcChanges={[{path: '/account-create', breadcrumb: 'Add Account'}]} />
        
        <div className="container">
          <div className="row">
            <div className="col-md-3 left_dashboard">
              <SideMenu/>
            </div>
            <div className="col-md-9 right_dashboard">
              <div className="content-setion">
                <div className="section_title m-25">
                  <h3>Add Account</h3>
                  <p className="accountstatus">Status: 
                    {accountDetail.status === true ? (
                      <span> Complete <i className="fa fa-check" aria-hidden="true"></i></span>
                    ) : (
                      <span> Pending <i className="fa fa-ban" aria-hidden="true"></i></span>
                    )} 
                  </p>
                </div>
                {!accountStatus &&
                  <div className="add_account_form">
                    <form onSubmit={handleSubmit(onAccountCreate)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <label>Routing Number*</label>
                          <div className="form-group">
                            <input type="number" name="routing_number" className="form-control" placeholder="Routing Number" onChange={e=>inputHandler(e)} 
                              ref={register({
                                required: 'Required',
                                pattern: {
                                  value:  /^\d{9}$/,
                                  message: "Routing Number should be 9 digit number."
                                }
                              })}
                            />
                            <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"routing_number")}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label>Account Number*</label>
                          <div className="form-group">
                            <input name="account_number" type="number" className="form-control" placeholder="Account Number" onChange={e=>inputHandler(e)} ref={register({ required: 'Required' })} />
                            <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"account_number")}</div>
                          </div>
                        </div>
                        <div className="col-lg-12 ">
                          <input type="reset" className="btn btn-primary rt" value="Clear" />
                          <input type="submit" className="btn btn-primary" value="Submit" />
                        </div>
                      </div>
                    </form>
                  </div>
                }
                {accountStatus && 
                  <div className="add_account_form">
                    <form onSubmit={handleSubmit(onAccountUpdate)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <label>Routing Number</label>
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Routing Number" defaultValue={accountDetail.account_number?accountDetail.account_number:''} disabled={true}/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label>Account Number</label>
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Routing Number" defaultValue={accountDetail.routing_number?accountDetail.routing_number:''} disabled={true}/>
                          </div>
                        </div>
                        
                        <div className="col-lg-6">
                          <label>First Name*</label>
                          <div className="form-group">
                            <input type="text" name="first_name" className="form-control" placeholder="First Name" onChange={e=>inputHandler(e)} defaultValue={formData && formData.first_name?formData.first_name:''} ref={register({ required: 'Required' })} />
                            <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"first_name")}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label>Last Name*</label>
                          <div className="form-group">
                            <input type="text" name="last_name" className="form-control" placeholder="Last Name" onChange={e=>inputHandler(e)} defaultValue={formData.last_name?formData.last_name:''} ref={register({ required: 'Required' })} />
                            <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"last_name")}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label>Email*</label>
                          <div className="form-group">
                            <input type="email" name="email" onChange={e=>inputHandler(e)} className="form-control" placeholder="Email" defaultValue={formData.email?formData.email:''} ref={register({ required: 'Required',
                                pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Please enter a valid email address." }
                              })}
                            />
                            <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"email")}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label>Phone Number*</label>
                          <div className="form-group phone_field">
                            <select className="form-control" name="phoneCode">
                              <option defaultValue="+1">+1</option>
                            </select>
                            <NumberFormat
                              format="###-###-####"
                              name="phone_number"
                              //pattern="[0-9,-]{12}"
                              placeholder="Phone Number Here"
                              className="form-control"
                              value={formData.phone_number?formData.phone_number:''}
                              onChange={e =>{ inputHandler(e, 'phone_number'); setValue("phone_number", e.target.value);}}
                              ref={register(formData.phone_number===''?{name: "phone_number"}:'', 
                                {required: formData.phone_number===''?'Required':'',
                                pattern: {value: /^(?=.*\d)[\d-]{12}$/, message: "Phone number should be 10 digit number."}
                              } )}
                            />
                            {!formData.phone_number &&
                              <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"phone_number")}</div>
                            }
                            {formData.phone_number && formData.phone_number.replace(/[-]/g, '').trim().length!==10 && 
                              <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"phone_number")}</div>
                            }
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label>Date of Birth*</label>
                          <div className="form-group">
                            <DatePicker
                              name='dob'
                              selected={dobValue}
                              maxDate={new Date()}
                              className="form-control"
                              dateFormat={appConstants.DateFormatNew}
                              placeholderText="Click to select a Date of Birth"
                              onChange={date => { setDobValue(date); setValue("dob", date);}}
                              ref={register({name: "dob"}, {required: dobValue?'':'Required'} )}
                              showMonthDropdown
                              useShortMonthInDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={5}
                            />
                            {!dobValue &&
                              (<div className="text-danger">{ShowFormErrors(errors,formDataErrors,"dob")}</div>)
                            }
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label>SSN*</label>
                          <div className="form-group">
                            <input type="test" name="personal_id_number" className="form-control" placeholder="SSN" maxLength="9" onChange={e=>inputHandler(e)} ref={register({ required: 'Required', pattern: {value: /^(?=.*\d)[\d]{9}$/, message: "SSN number should be 9 digit number."}})} />
                            <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"personal_id_number")}</div>
                          </div>
                        </div>
                        <div className="col-lg-6 uploadfilesec">
                          <label>Photo ID-Front*</label>
                          <div className="form-group">
                            <input type="file" name="photoFront" accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc" onChange={(e)=>fileChangedHandler(e, "photoFront")} ref={register({required: 'Required'})} />
                            <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"photoFront")}</div>
                          </div>
                          {!userDetailStatus && formData.photoFrontPreview && 
                            <div className="fileDocs"><img src={formData.photoFrontPreview} alt="img"/></div>
                          }
                        </div>                             
                        <div className="col-lg-6 uploadfilesec">
                          <label>Photo ID-Back*</label>
                          <div className="form-group">
                            <input type="file" name="photoBack" accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc" onChange={(e)=>fileChangedHandler(e, "photoBack")} ref={register({required: 'Required'})} />
                            <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"photoBack")}</div>
                          </div>
                          {!userDetailStatus && formData.photoBackPreview && 
                            <div className="fileDocs"><img src={formData.photoBackPreview} alt="img"/></div>
                          }
                        </div>
                        <div className="col-lg-12">
                          <label>Industry*</label>
                          <div className="form-group">
                            <select className="form-control" id='industryId' name="industry" onChange={(e)=>inputHandler(e)} ref={register({ required: 'Required' })} disabled={true}>
                              <option value="">Search..</option>
                              {mccList && mccList.map((val, int)=>{
                                return(<option value={val.id} 
                                  selected={mccValue && mccValue===JSON.stringify(val.id)?true:false} key={int}>{val.category}</option>)
                              })}
                            </select>
                            <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"industry")}</div>
                          </div>
                        </div>

                        <GeoAddressComponent
                          history={history}
                          register={register}
                          errors={errors}
                          formData={formData}
                          inputHandler={inputHandler}
                          signupErrors={formDataErrors}
                          valueReset={valueReset}
                          tzStatus='NO'
                        />
                        <div className="col-lg-12 t-pp">
                          <p>Must be a U.S. address. PO Boxes are not allowed.</p>
                        </div>
                        {!userDetailStatus &&
                          <div className="col-lg-12 t-pp">
                            <input type="reset" onClick={e=>setResetForm(e)} className="btn btn-primary rt" value="Clear"/>
                            <input type="submit" className="btn btn-primary" value="Save"/>
                          </div>
                        }
                        {userDetailStatus && 
                          <div className="col-lg-12 t-pp">
                            <Link to="#" type="button" onClick={e=>deletePayoutAccount(e)} className="btn btn-primary">Remove Account</Link>
                          </div>
                        }
                      </div>
                    </form>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="spinner" style={{display:spinner?'block':'none'}}>
        <img src="static/img/spinner.gif" alt='loader'/>
      </div>
    </div>
  );
}
export default withLocalize(AccountCreatePage);
