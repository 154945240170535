import React,{useState, useEffect} from 'react';
import { withRouter, Link } from 'react-router-dom';
import {checkExtFunction} from '../../../../_Constants/app.constants';
import renderHTML from 'react-render-html';

const PatientDocumentsPage = ({history, paDocs}) => {

  return (
    <div className="col-lg-12">
      <div className="row">
          {paDocs && paDocs.length > 0&& paDocs.map((doc, ind)=>{
            return(
              <div className="col-lg-2" key={ind}>
                <div className="document_single">
                  <div className="document_single_img">
                    {doc.imgPath!=='' &&
                      (<div>{doc.imgPath?renderHTML(checkExtFunction(doc.imgPath)):''}</div>)
                    }
                  </div>
                  <label>{doc.title?doc.title:'-'}</label>
                </div>
              </div>
            )
          })}
          {(!paDocs || paDocs.length === 0) &&
              <p>No documents saved</p>
          }
      </div>
    </div>
  );
}
export default withRouter(PatientDocumentsPage);