import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import {getClinicListAction, userLoginAction} from '../../../Actions/User.action';
import { useForm } from "react-hook-form";
import {FormError, ShowFormErrors} from '../../../../_Common/formError';
import { Dialog } from "@blueprintjs/core";
import ForgotPageComponent from '../../Forgot/ForgotPage';
import SignUp from '../../SignUp/SignUp';
import toastr from "../../../../_Common/toaster";
import {appConstants,setSessionValue, getCookie, setCookie} from '../../../../_Constants/app.constants';
import Select from 'react-select';
import $ from 'jquery';

const LoginPage = ({history}) => {
  const [formData, setFormData] = useState({});
  const [formErrors,setFormErrors] = useState({});
  const { register, handleSubmit, setValue, errors } = useForm();
  const [modalForgot,setModalForgot] = useState(false);
  const [isSignupForm,setIsSignupForm] = useState(false);
  const [loginType,setloginType] = useState(false);
  const [clinicListData, setClinicListData] = useState([{ value: '', label: 'Select Clinic Code'}]);
  const [spinner,setSpinner] = useState(false);

  const getClinicList = async()=>{
    let dataList = await getClinicListAction();
    if(dataList.data){
      dataList.data.map((item) => {        
        return clinicListData.push({value: item.clinicNumberName, label: item.clinicNumberName})
      });
      setClinicListData(clinicListData);
    }
  }

  const inputHandler = (e,field)=>{
    let loginFormData = formData;
    if(field === 'clinicId'){
      if(e === null){
          loginFormData[field] = clinicListData[0].value;
      }else{
        let code = e && e.value?e.value:'';
        loginFormData[field] = code;
      }
    }else{
      loginFormData[e.target.name] = e.target.value;
    }
    setFormData({...formData,...loginFormData});
  }

  const signInFormSubmit = async () =>{
    setSpinner(true);
    let lastUrl = getCookie('lastUrl');
    let redirectUrl = lastUrl && lastUrl != ''?lastUrl:"profile";
    localStorage.clear();
    formData['loginType'] = loginType?'PT':'CLINIC';

    let res = await userLoginAction(formData);
    if(res.success){  
      document.cookie = "lastUrl=;";
      
      setSessionValue(res.data);
      setSpinner(false);      
      toastr.show({message: res.msg,intent: "success"});
      //history.push('/profile');  
      
      window.location = redirectUrl;
      
    } else{
      setSpinner(false);
      if(res.err && res.err.length > 0){
        setFormErrors(FormError(res.err));
      }else if(res.msg){
        toastr.show({message: res.msg,intent: "danger"});
      }
    }
  }

  useEffect(()=>{
    getClinicList();
  },[]);

  return (
    <div className="login-form text-left">
      <div className="text-center">
        <h4>Login</h4>
      </div>
      <form onSubmit={handleSubmit(signInFormSubmit)}>
        <div className="radio-lonn">
          <div className="form-group">
            <label htmlFor="typePC"><input type="radio" id="typePC" name="loginStatus" 
              checked={!loginType?true:false}
              onChange={e=>{setloginType(false); setValue("clinicId", 0);}} className="clas-radio"/> Patient/PT/Clinic Admin</label>
          </div>
          <div className="form-group">
            <label htmlFor="typePT"><input type="radio" id="typePT" name="loginStatus" 
            onChange={e=>{setloginType(true); setValue("clinicId", '');}} className="clas-radio" /> Clinic Physical Therapist </label>
          </div>
        </div>  
        {loginType &&
          <div className="form-group">
            <label htmlFor="clinicCode">Clinic Code*</label>
            <Select
              id='clinicId'
              defaultValue={formData.clinicId}
              className="basic-single"
              placeholder="Search & Select Clinic Code"
              onChange={e=> {inputHandler(e,"clinicId"); setValue("clinicId", e && e.value?e.value:''); }}
              name="clinicId"
              options={clinicListData}
              isClearable={true}
              ref={register({name: "clinicId"}, {required: formData.clinicId?'':'Required'} )}
            />
            {/*<select className="form-control" name="clinicId" onChange={e=>inputHandler(e,"clinicCode")}
              ref={register({
                required: 'Required'
              })} >
              <option value=""> Select </option>
              {clinicListData && 
                clinicListData.map((value, index) => {
                  return (<option value={value.clinicNumberName}> {value.clinicNumberName} </option>) 
                })
              }
            </select>*/}
            <div className="text-danger">{ShowFormErrors(errors,formErrors,"clinicId")}</div>
          </div>
        }
        <div className="form-group">
          <label htmlFor="email">Email Address*</label>
          <input onChange={e=>inputHandler(e,'email')} name="email" type="email" 
            className="form-control" placeholder="Enter Email Address e.g. name@example.com"
            ref={register({
              required: 'Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address"
              }
            })}
          />
          <div className="text-danger">{ShowFormErrors(errors,formErrors,"email")}</div>
        </div>
        <div className="form-group">
          <label htmlFor="pass">Password*</label>
          <div className="form-group">
            <input type="password" onChange={e=>inputHandler(e,'password')} name="password" 
              className="form-control" placeholder="Password"
              ref={register({
                required: 'Required'
              })}
            />
            <div className="text-danger">{ShowFormErrors(errors,formErrors,"password")}</div>
          </div>
        </div>

        <div className="btn_container text-center">
          <input type="submit" className="btn btn-primary" value="Login" />
          <br></br>
          <Link to="#" onClick={e=>setModalForgot(true)}>Forgot Password</Link><br/>
           <Link to="#" onClick={e=>setIsSignupForm(true)}>Register</Link>
        </div>
      </form>

      <Dialog
        className="form_designpage" onClose={e=>setModalForgot(false)}
        title="" canOutsideClickClose={false} isOpen={modalForgot}>
          <ForgotPageComponent setModalForgot={setModalForgot} />
      </Dialog>
      <Dialog
        className="form_designpage" onClose={e=>setIsSignupForm(false)}
        title="" canOutsideClickClose={false} isOpen={isSignupForm}>
          <SignUp setIsSignupForm={setIsSignupForm} />
      </Dialog>

      <div className="spinner" style={{display:spinner?'block':'none'}}>
          <img src="static/img/spinner.gif" alt='loader'/>
      </div>
    </div>
  );
}
export default withLocalize(withRouter(LoginPage));
