import React,{useState} from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from "react-hook-form";
import {changePasswordAction} from '../../../Actions/User.action';
import {FormError, ShowFormErrors} from "../../../../_Common/formError";
import toastr from "../../../../_Common/toaster";

const ChangePassword = ({history, setIsChangePassword, cptId}) => {
	const [formData, setformData] = useState({});
	const [formErrors,setFormErrors] = useState({});
	const { register, handleSubmit, errors } = useForm();
  	const [spinner,setSpinner] = useState(false);
  	
	const inputHandler = (e,field)=>{
		let formDataNew = formData;
		formDataNew[field] = e.target.value;
		setformData(formDataNew);
	}

	const onFormSubmit = async () =>{
		setSpinner(true);
		let formErrors = {};
		if(cptId && cptId!==''){ //This condition Clinic password change to pt
			formData['userId'] = cptId;
		}
		let res = await changePasswordAction(formData);
		if(res.err && res.err.length > 0){
			formErrors = FormError(res.err);
		}
		setFormErrors(formErrors);
		if(res.success){
			setSpinner(false);
			setIsChangePassword(false);
			toastr.show({message: "Password changed successfully",intent: "success"});
		}else if(res.success===false){
			setSpinner(false);
			toastr.show({message: res.msg,intent: "danger"});
		}
	}

	return (
		<div className="form_modals changepassmodel">
			<h5 className="modal-title">Change Password</h5>
	        <div className="row col-md-12">
	            <div className="content-setion">
      				<form onSubmit={handleSubmit(onFormSubmit)} >
	                 	<div className="row">
			              	<div className="col-md-12">
	                       		<label>Old Password*</label>
		                        <div className="form-group">
			                      	<input type="text" name="oldPassword" className="form-control" 
				                      	onChange={e=>inputHandler(e,'oldPassword')} placeholder="Old Password" 
				                        ref={register({
				                          required: 'Required',
				                        })}
			                      	/>
			                      	<div className="text-danger">{ShowFormErrors(errors,formErrors,"oldPassword")}</div>
		                        </div>
		                    </div>
	                       	<div className="col-md-12">
		                       	<label>New Password*</label>
		                        <div className="form-group">
			                      	<input type="password" name="newPassword" className="form-control" 
				                      	onChange={e=>inputHandler(e,'newPassword')} placeholder="New Password" 
				                        ref={register({
				                          	required: 'Required',
					                        pattern: {
                  								value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,15}$/,
	                            				message: "Minimum 6 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
					                        }
				                        })}
			                      	/>
			                      	<div className="text-danger">{ShowFormErrors(errors,formErrors,"newPassword")}</div>
		                        </div>
	                       	</div>
							<div className="col-md-12">
								<label>Confirm Password*</label>
								<div className="form-group">
			                      	<input type="password" name="cPassword" className="form-control" 
				                      	onChange={e=>inputHandler(e,'cPassword')} placeholder="Confirm Password" 
									  	ref={register({ 
									  		required: 'Required',
									  		validate: (value) => value === formData.newPassword || "Passwords don't match.",
									  	})}
			                      	/>
			                  		<div className="text-danger">{ShowFormErrors(errors,formErrors,"cPassword")}</div>
								</div>
							</div>
							<div className="col-lg-12 t-pp">
		                      	<input type="reset" onClick={e=>setformData({})} className="btn btn-primary rt" value="Clear" />
		                      	<input type="submit" className="btn btn-primary" value="Save" />
		                    </div>
                     	</div>
                    </form>
                </div>
	        </div>
	        <div className="spinner" style={{display:spinner?'block':'none'}}>
	        	<img src="static/img/spinner.gif" alt='loader'/>
	      	</div>
		</div>
	);
}
export default withRouter(ChangePassword);
