import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize, Translate } from 'react-localize-redux';
import SideMenu from '../DashboardPage/SideMenu';
import {Modal, ModalHeader, ModalBody, Col, Row, Button, FormGroup, Table } from 'reactstrap';
import moment from 'moment';
import {getPTAvailabilityList, saveBookingAction, getAppointmentPrices} from '../../Actions/User.action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from 'react-calendar';
import {appConstants, toUTC, getLocalDate} from '../../../_Constants/app.constants';
import toastr from "../../../_Common/toaster";
import { confirmAlert } from 'react-confirm-alert';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';

const PTList = (props) => {
 // console.log(props);

  const [availabilityList, setAvailabilityList] = useState([]);
  const [availabilityDates, setAvailabilityDates] = useState([]);
  const [availabilityTime, setAvailabilityTime] = useState([]);
  const [bookedList, setbookedList] = useState([]);
  const [fromValue,setFromValue] = useState(props.location.state && props.location.state.from?props.location.state.from:'');
  const [toValue,setToValue] = useState(props.location.state && props.location.state.to?props.location.state.to:'');
  const [name, setName] = useState('');
  const [modelView, setModelView] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [slot, setSlot] = useState(30);
  const [id, setId] = useState('');
  const [ptId, setPTId] = useState('');
  const [error, setError] = useState('');
  const [toError, setToError] = useState('');
  const [prices, setPrices] = useState('');
  const [spinner,setSpinner] = useState(false);

  const toggleModal = (row = {}) => {
    setModal(!modal);
    setModelView(row);
  }

  const getAvailabilityListData = async(search='', price=[])=>{
      let srchData = {startDate:'', endDate:''};
      if(search == 'reset'){
          setFromValue('');
          setToValue('');
          setName('');
          setToError('');
      }else{
        srchData = {startDate:fromValue, endDate:toValue};
      }
      
      if(search === 'search' && fromValue && fromValue !== '' && toValue === ''){
        setToError('Select to date!');
      }else{
          setToError('');
          setSpinner(true);
          let searchData = {};
        
          searchData['startDate'] = fromValue && search !='reset'?toUTC(moment(getLocalDate(fromValue)).add(-1, 'days').format("YYYY-MM-DD")):toUTC(moment().add(-1, 'days').format("YYYY-MM-DD"));
          searchData['endDate'] =  toValue && search !='reset'?toUTC(moment(getLocalDate(toValue)).add(1, 'days').format("YYYY-MM-DD")):fromValue && search !='reset'?toUTC(moment(getLocalDate(fromValue)).add(1, 'days').format("YYYY-MM-DD")):'';
          searchData['name'] =  search !='reset'?name:'';
          if(localStorage.getItem("latitude") && localStorage.getItem("longitude")){
            searchData['latitude'] = localStorage.getItem("latitude");
            searchData['longitude'] = localStorage.getItem("longitude");
          }
          const dataList = await getPTAvailabilityList(searchData);  
          
          if(dataList.success === true){

              let ptDatalist = dataList.data;

              ptDatalist.map(item=>{
                  return availableDatesDisplay(item, price, srchData);                               
              })
              
              //console.log(ptDatalist);
              setAvailabilityList(ptDatalist);
          }
          setSpinner(false);
      }
      //}
  }

  const getAvailabilityTime = async(date='', slotD=30, dates=[], booking=[])=>{
    
    if(date != ''){
      if(dates.length == 0){
        dates = availabilityDates;
      }

      if(booking.length == 0){
        booking = bookedList;
      }
      
      date = moment(date, appConstants.DATEFORMAT).format('YYYY-MM-DD');
      
      for(let j in dates){  
        
        if(dates[j].date == date){  
            let timeArr = dates[j].time;
            
            let today = moment().format('YYYY-MM-DD');
            let timeList = [];

            for(let i = 0;i<48;i++){
                let inA = 0;
                
                let tt = moment(date+' 00:00 AM', 'YYYY-MM-DD hh:mm A').add(i*30, 'minutes').format("hh:mm A");
                let timeStr = moment(date+' 00:00 AM', 'YYYY-MM-DD hh:mm A').add(i*30, 'minutes').format("x");
               
                if((today != date) || (today == date && moment().add(15, 'minutes').format("x") < timeStr)){
                    
                    for(let k in timeArr){
                       
                      if(moment(timeArr[k].startTime).format('x') <= moment(date+' 00:00 AM', 'YYYY-MM-DD hh:mm A').add(i*30, 'minutes').format("x") && moment(timeArr[k].endTime).format('x') > moment(date+' 00:00 AM', 'YYYY-MM-DD hh:mm A').add((i*30)+(slotD-30), 'minutes').format("x")){
                          
                          inA = 1;
                      }
                    }

                    if(inA == 1){
                        
                        for(let k in booking){
                          
                          let bStart = moment(booking[k].startTime).format('x');
                          let bEnd = moment(booking[k].endTime).format('x');
                          let bbStart = moment(booking[k].startTime).add(-30, 'minutes').format('x');

                          if(bStart == timeStr || (bStart < timeStr && bEnd > timeStr) || (slotD == 60 && bbStart == timeStr)){
                              
                              inA = 0;
                          }
                        }
                    } 
                  
                    if(inA == 1){
                     
                      timeList.push(tt);
                    }  
                }
            }  
           
            
            if(slotD == 60 && startTime && endTime && moment(selectedDate, appConstants.DATEFORMAT).format('YYYY-MM-DD') == date){
          
                if(timeList.indexOf(startTime)>=0){
                    //setEndTime(enTime);  
                }else{
                    
                    setStartTime(''); 
                    setEndTime('');                                  
                    setError('This slot not available for 1 hour!');
                }
            }
            
            setAvailabilityTime(timeList);
            
        }
      }
    }
  }

  const onChangeHandle = async(e, field="")=>{
    setError('');

    let today = moment(selectedDate, appConstants.DATEFORMAT).format("YYYY-MM-DD");
    if(field == 'date'){
      setStartTime(''); 
      setEndTime('');  
       let date = getLocalDate(e);
       date = moment(date).format(appConstants.DATEFORMAT);
       setSelectedDate(date);   
       getAvailabilityTime(date, slot);      
    }else{
      if(e.target.name == 'slot'){ 
        setSlot(e.target.value);              
        if(startTime){   
                  
            setEndTime(moment(today+' '+startTime, 'YYYY-MM-DD hh:mm A').add(e.target.value, 'minutes').format("hh:mm A"));                      
        }
        getAvailabilityTime(selectedDate, e.target.value);               

      }else if(e.target.name == 'startTime'){  
       
        setStartTime(e.target.value);  
        if(slot != ''){
            
            setEndTime(moment(today+' '+e.target.value, 'YYYY-MM-DD hh:mm A').add(slot, 'minutes').format("hh:mm A"));  
        }            
      }

      if(((e.target.name == 'slot' && e.target.value == 60) || (slot == 60 && e.target.name == 'startTime')) && bookedList.length>0){
          let srtTime = e.target.name == 'slot'?startTime:e.target.value;
          let sllot =  e.target.name == 'slot'?e.target.value:slot;
          let edTime = moment(today+' '+srtTime, 'YYYY-MM-DD hh:mm A').add(sllot, 'minutes').format("x");
          for(let k in bookedList){
            let bEnd = moment(bookedList[k].endTime).format('x');
            if(bEnd == edTime){
                setEndTime('');    
                console.log('errin1');            
                setError('This slot not available for 1 hour!');
              }
          }
      }
    }      
  }

  const onAction=(index='',row='')=>{
    
    if(localStorage.getItem("userType")=='PATIENT' && row.allfull===false){
              
        /* let dateArr = row.dateArr.map((item, i)=>{
              if(item.full === false){
                return item;
              }          
        });*/
        
        let dateArr = row.dateArr;
        dateArr = dateArr.filter(function(item){ return item.full === false });
        
        setbookedList(row.bookingList);

        setAvailabilityDates(dateArr);
        setModal(!modal);
        setModelView(row);
        let dSlot = prices['30MinutePrice']?30:60;
        setSlot(dSlot);
        setSelectedDate('');   
        let slctDate = moment(dateArr[0].date).format(appConstants.DATEFORMAT);
        setSelectedDate(slctDate);
                
        getAvailabilityTime(slctDate, dSlot, dateArr, row.bookingList);
                  
        setStartTime('');
        setEndTime('');
        
        setPTId(row._id);      
    }else{
      if(row.allfull === true){
        toastr.show({message: "All slots are booked, please search for another physical therapist to book the appointment",intent: "warning"}); 
      }else{
        toastr.show({message: "Please login first or create an account to book the appointment.",intent: "warning"}); 
      }
      
    }
  }

  const handleSave = async(e)=>{
    let isOK = 1;
    if(selectedDate == ''){
      isOK = 0;
    }
    if(startTime == ''){
      isOK = 0;
    }
    if(endTime == ''){
      isOK = 0;
    }
    if(isOK == 1){
      
      let sDateTime = toUTC(moment(selectedDate+' '+startTime, appConstants.DATEFORMAT+' hh:mm A').format('YYYY-MM-DD HH:mm:ss'));
      let eDateTime = toUTC(moment(selectedDate+' '+endTime, appConstants.DATEFORMAT+' hh:mm A').format('YYYY-MM-DD HH:mm:ss'));
      
      let data = {date:sDateTime, startTime:sDateTime, endTime:eDateTime, ptId:ptId, duration:slot}
        
      if(id != ''){
        data._id = id;
      }
      
      //console.log(data);
      //console.log(moment(sDateTime).format('hh:mm A'));
      //console.log(moment(eDateTime).format('hh:mm A'));
      if(data){
        props.history.push('/checkout', { bookingArr: data })
      }
       
      /*let reponse = await saveBookingAction(data);
      if(reponse.success){
        setModal(false);
        //getAvailabilityListData();
        toastr.show({message: "Success",intent: "success"}); 
      }
      props.history.push('/checkout'); //--ST*/
    }else{
      if(error==''){
        setError('Please select all required fields.');
      }
      
    }
  }

  const addEventClass = (date, view)=>{
    var classT = '';
    date = getLocalDate(date);
    //date = moment(date, 'ddd MMM DD YYYY').format("YYYY-MM-DD");
    
    for(let i in availabilityDates){
      if(moment(availabilityDates[i].date).format("YYYY-MM-DD") == date){
        
          classT =  'available';
      }
    }      
    return classT;
  }

  const tileDisabled = (date)=>{
    var classT = true;
  
    date = getLocalDate(date);
    
   /// date = moment(date).format("YYYY-MM-DD");
    for(let i in availabilityDates){

      if(moment(availabilityDates[i].date).format("YYYY-MM-DD") == date){
          classT =  false;
      }
    }      
    return classT;
  }

  const getAppointmentPricesDetail = async()=>{
    let reponse = await getAppointmentPrices();
      setPrices(reponse.data); 
      
      getAvailabilityListData('',reponse.data);         
  }

  const availableDatesDisplay = (item, price=[], searchData)=>{
    
      let startDates = searchData['startDate'];
      let endDates = searchData['endDate']
      let dates = item.dateArr;
      let dSlot = ((price &&price['30MinutePrice'])?30:(prices['30MinutePrice']?30:60));
      
      let timeList = [];
      let bookList = [];
      for(let j in dates){  
        
          timeList = [...timeList, ...dates[j].time];
          if(dates[j].bookingData){
              bookList = [...bookList, ...dates[j].bookingData];
          }        
      }

      let dateList = [];

      for(let x in timeList){
        
            let startDate = moment(timeList[x].startTime).format('YYYY-MM-DD');
            let endDate = moment(timeList[x].endTime).format('YYYY-MM-DD HH:mm');

            if(!dateList[startDate]){
                dateList[startDate] = {time:[], full:false};
            } 
          
            if( moment(startDate+' 00:00', 'YYYY-MM-DD HH:mm').add(1,'days').format('x') < moment(endDate, 'YYYY-MM-DD HH:mm').format('x')){
              
                dateList[startDate].time.push({startTime:timeList[x].startTime, endTime:toUTC(moment(startDate+' 00:00 AM', 'YYYY-MM-DD hh:mm A').add(1, 'days').format('YYYY-MM-DD HH:mm:ss'))});

                dateList[moment(timeList[x].endTime).format('YYYY-MM-DD')] = {time:[], full:false};
                
                dateList[moment(timeList[x].endTime).format('YYYY-MM-DD')].time.push({startTime:toUTC(moment(startDate+' 00:00 AM', 'YYYY-MM-DD hh:mm A').add(1, 'days').format('YYYY-MM-DD HH:mm:ss')), endTime:timeList[x].endTime});

            }else{
            
              dateList[startDate].time.push({startTime:timeList[x].startTime, endTime:timeList[x].endTime});
              
            }

      }
      let c = 0;
   
      for(let dDate in dateList){

          if((endDates == '' && startDates !='' && getLocalDate(startDates) == dDate) || (endDates == '' && startDates =='' && moment().format('YYYY-MM-DD') <= dDate ) || (startDates !='' && getLocalDate(startDates) == dDate) || (endDates != '' && getLocalDate(endDates) == dDate) || (endDates != '' && startDates !='' && getLocalDate(startDates) < dDate && getLocalDate(endDates) > dDate)){
          
            
            let timeList = [];
            let timeArr = dateList[dDate].time;
            for(let i = 0;i<48;i++){
                let inA = 0;
                let today = moment().format('YYYY-MM-DD');
                let tt = moment(dDate+' 00:00:00', 'YYYY-MM-DD hh:mm A').add(i*30, 'minutes').format("hh:mm A");
                let timeStr = moment(dDate+' 00:00:00', 'YYYY-MM-DD hh:mm A').add(i*30, 'minutes').format("x");
              
                if((today != dDate) || (today == dDate && moment().add(15, 'minutes').format("x") < timeStr)){ 
                //console.log('in1');
                
                    for(let k in timeArr){                   
                      
                        if(moment(timeArr[k].startTime).format('x') <= moment(dDate+' 00:00:00', 'YYYY-MM-DD hh:mm A').add(i*30, 'minutes').format("x") && moment(timeArr[k].endTime).format('x') > moment(dDate+' 00:00:00', 'YYYY-MM-DD hh:mm A').add(((i*30)+(dSlot-30)), 'minutes').format("x")){
                                                                
                            inA = 1;
                        }
                    }

                    if(inA == 1){                          
                        for(let k in bookList){
                          let bStart = moment(bookList[k].startTime).format('x');
                          let bEnd = moment(bookList[k].endTime).format('x');

                          if(bStart == timeStr || (bStart < timeStr && bEnd > timeStr)){
                              
                              inA = 0;
                          }
                        }
                    } 
                  
                    if(inA == 1){
                      
                      timeList.push(tt);
                    }  
                }
            }  
          // console.log(timeList);
            if(timeList.length == 0){
                c++;
                
                dateList[dDate].full = true;
            }else{ 
                         
                dateList[dDate].full = false;
            }
          }
      }

      let newDateList = [];
      
      for(let y in dateList){
      
          if((endDates == '' && startDates !='' && getLocalDate(startDates) == y) || (endDates == '' && startDates =='' && moment().format('YYYY-MM-DD') <= y ) || (startDates !='' && getLocalDate(startDates) == y) || (endDates != '' && getLocalDate(endDates) == y) || (endDates != '' && startDates !='' && getLocalDate(startDates) < y && getLocalDate(endDates) > y)){
              
              dateList[y].date = y;
              newDateList.push(dateList[y]);
              
          }
      }
     
      item.dates = dates;
      if(newDateList.length == c){
        
        item.allfull = true;
      }else{
        item.allfull = false;
      }
      item.dateArr = newDateList;
      item.bookingList = bookList;
      item.isData = true;
    
      return item;
  }

  useEffect(()=>{
    if (navigator.geolocation && !localStorage.getItem("jwtToken")) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          localStorage.setItem("latitude",position.coords.latitude);
          localStorage.setItem("longitude",position.coords.longitude);
        },(error) => { console.error(error);}
      );
    }
    getAppointmentPricesDetail();      
        
  },[]);

  return (
    <div>
      <MetaTags className="bg-rr">
        <title>PT List</title>
        <meta name="description" content="Recover" />
        <meta name="keywords" content="Recover" />
      </MetaTags>
      <section className="dash_section pad-reggis Pt_list_sec">
        <BreadcrumbsComp bcChanges={[{path: '/pt-list', breadcrumb: 'PT List'}]} />
        
        <div className="container">
          <div className="row">
            {(localStorage.getItem("jwtToken") && localStorage.getItem("RecoverWebStatus")) && 
              <div className="col-md-3 left_dashboard">
                <SideMenu/>
              </div>
            }
            <div className={localStorage.getItem("jwtToken")?'col-md-9 right_dashboard':'col-md-12 right_dashboard '}>
             <div className="content-setion ">
             <div className="section_title m-25"><h3>PT LIST <span className="red-Text pull-right"> &#x25A0; All slots are booked</span></h3></div>
            <div className="search-availabilty gray_boxx">
            <div className="row">
              <div className="col-md-3">
                <label>Search</label>
                <div className="to-from">
                    <input type="text" name="name" onChange={e =>setName(e.target.value)} placeholder="Type text..." value={name} />
                </div>
              </div>
              <div className="col-md-3">              
                <label>From</label>
                <div className="to-from">
                  <DatePicker
                    minDate={new Date()}
                    onChange={date =>(setFromValue(date?date:''),setToValue(toValue>=date?toValue:''))}     
                    selected={fromValue}            
                    dateFormat={appConstants.DateFormatNew}
                    placeholderText={'From date'}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label>To</label>
                <div className="to-from">
                  <DatePicker
                    minDate={fromValue?fromValue:new Date()}            
                    onChange={date =>setToValue((fromValue?(date?date:''):''),setToError(''))}  
                    selected={toValue>=fromValue?toValue:''}                 
                    dateFormat={appConstants.DateFormatNew}
                    placeholderText={'To date'} 
                  />
                  <small className="btn_sec text-center error">{toValue==''?toError:''}</small>
                </div>
              </div>
              <div className="col-md-3">
                <div className="to-from">
                    <button type="button" className="btn btn-primary" onClick={e=>getAvailabilityListData('search')}>Search</button>                    
                </div>
                <div className="to-from">
                    <button type="button" className="btn btn-primary" onClick={e=>getAvailabilityListData('reset')}>Reset</button>                    
                </div>
              </div>
              
              </div>
            </div>
              <Table responsive className="commontabl tblres">
                <thead>
                  <tr>  
                    <th>Clinic Name</th>
                    <th>Therapist Name</th>
                    <th>Available Dates</th>
                    <th className="action">Action</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody> 
                {availabilityList.map(function (item,index) {  
                      // console.log(item.dateArr);
                    //item = availableDatesDisplay(item);  
                    let dateArr = item.dateArr;    
                    if(dateArr.length>0){                  
                      return (<tr key={index}>                                                       
                        <td className="text-center"> 
                          {item.clinicName?item.clinicName:'-'}      
                        </td>
                        <td className="nameid">                   
                          {item.fullName?item.fullName:''}  
                        </td>
                        <td>                      
                          { dateArr.map(function(dd,i){
                            
                            return(<div key={i} className={dd.full === true?"red-Text":""}>{moment(dd.date, 'YYYY-MM-DD').format(appConstants.DATEFORMAT)}</div>);
                              })
                          }
                        </td>
                        <td>
                          <button type="button" onClick={e=>onAction(index, item)} className="btn btn-primary">Book an Appointment</button>                  
                        </td>
                        <td>
                          ${prices['60MinutePrice']} (60 min)

                          { prices && prices['30MinutePrice'] &&
                              <span><br/>&nbsp; ${prices['30MinutePrice']} (30 min)</span>
                          }
                        </td>
                      </tr>)
                  }})}
                { availabilityList.length === 0 &&
                    <tr><td colSpan="5" className="text-center">No data found</td></tr>
                }
                </tbody>
              </Table>
              </div>
            </div>  
            <div>
              <Modal isOpen={modal} className="Open-x add_edit_availability" size="lg" toggle={toggleModal}>
                <ModalHeader  toggle={toggleModal} className="header-pop-unit">Book an Appointment</ModalHeader>
                <ModalBody className="height-full" >
                  <container-fluid>
                    <Row>
                      <div className="col-lg-6">
                        <FormGroup>
                            <label>Appointment Date*</label><br/>                                     
                            <input type="text" className="form-control" name="selectedDate" value={selectedDate} readOnly />
                        </FormGroup> 
                        <FormGroup> 
                            <label>Select a booking slot*</label><br/>
                            <select className="form-control" name="slot" onChange={e => onChangeHandle(e)} value={slot}>
                              { prices['30MinutePrice'] &&
                                <option value="30">30 Minute</option>
                              }
                              <option value="60">1 Hour</option>
                            </select>
                        </FormGroup>  
                        <FormGroup> 
                            <label>Start Time*</label><br/>
                            <select className="form-control" name="startTime" onChange={e => onChangeHandle(e)} value={startTime}>
                              <option value="">Select</option>
                              {availabilityTime.map(function(time,index2) {
                                return <option key={index2} value={time}>{time}</option>
                              })}
                            </select>
                        </FormGroup>  
                        <FormGroup>
                            <label>End Time*</label><br/>
                            <input className="form-control" type="text" name="endTime" value={endTime} readOnly />                                            
                        </FormGroup> 
                        <FormGroup>
                            <label>Price*</label><br/>
                            ${slot==30?prices['30MinutePrice']:prices['60MinutePrice']}
                        </FormGroup> 
                      </div>
                      <div className="col-lg-6">  
                        <FormGroup>                                 
                           <Calendar
                              calendarType={"US"} 
                              name="date"
                              minDate={new Date()}
                              value={new Date(moment(selectedDate, appConstants.DATEFORMAT))}
                              onChange={e => onChangeHandle(e, 'date')}
                              //value={ (fromData.date?new Date({fromData.date?fromData.date:''}):new Date())}
                              tileClassName={({ date, view }) => addEventClass(date, view)}
                              tileDisabled={({ date}) => tileDisabled(date)}                         
                          /> 
                        </FormGroup>                                                                       
                      </div>
                    </Row>
                    
                    <FormGroup row>
                            <Col sm="12" className="btn_sec text-center error">{availabilityTime.length==0?"All slots are booked, please search for another physical therapist to book the appointment":error}</Col>
                      <Col sm="12" className="btn_sec text-center">                                  
                          <Button className="btn btn-primary" color="primary" onClick={() => handleSave()} >Book Now</Button>
                          <Button className="btn btn-primary" onClick={() => setModal(false)} >Cancel</Button>
                      </Col>
                      <Col sm="12">
                        <p>If your appointment is booked, you can check the status of the appointment on the Future Appointment tab.</p>
                      </Col>
                    </FormGroup>
                  </container-fluid>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </div>
      </section>
      <div className="spinner" style={{display:spinner?'block':'none'}}>
        <img src="static/img/spinner.gif" alt='loader'/>
      </div>
    </div>
  );
}
export default withLocalize(withRouter(PTList));
