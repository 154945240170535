import React,{useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';

const AboutUsPage = ({history}) => {

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
  },[]);

  return (
    <div>
    <MetaTags>
      <title>About Us</title>
      <meta name="description" content="To access e-INSCRIPTION’s services through a website or mobile app, you need an account on our platform. To create an account you have to provide accurate info" />
      <meta name="keywords" content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore " />
    </MetaTags>
    <section className="about_page pad-reggis inner_pagewrap">
      <BreadcrumbsComp bcChanges={[{path: '/about-us', breadcrumb: 'About-us'}]} />
      
      <div className="container">
        <div className="row white_box">
          <div className="col-lg-12 col-sm-12">
            <div className="section_title page_ttl text-center">
              <h3>About Us</h3>
              <div className="decor2"></div>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 text-left">
            <div className="about_con_wrap">
              <p>Recover is a telehealth platform that provides patients quick and easy access to health care specialists whenever or wherever they need. Providers may also use Recover to fill cancellations that may arise within their own practice. Currently, Recover is focused on physical therapy. However, in the future, Recover will expand to include occupational, speech, nutrition, and psychological therapy options.</p>
               <p>Our goal is to connect patients and providers with more ease as well as provide patients with a platform in which they can receive entire treatment plans, while simultaneously reducing the amount of missed appointments due to inconvenience.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}
export default withLocalize(AboutUsPage);
