import React,{useEffect} from 'react';
import { withLocalize } from 'react-localize-redux';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';

const FoundingStoriesPage = ({history}) => {

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
  },[]);

  return (
    <div>
    <MetaTags>
      <title>Founding Stories</title>
      <meta name="description" content="" />
      <meta name="keywords" content=" " />
    </MetaTags>
    <section className="founding_story_page pad-reggis inner_pagewrap">
      <BreadcrumbsComp bcChanges={[{path: '/founding-stories', breadcrumb: 'Founding Stories'}]} />
      
      <div className="container">
        <div className="white_box">
          <div className="col-sm-12 text-left founding_story_wrap">
            <div className="section_title page_ttl text-center">
              <h3>Founding Stories</h3>
              <div className="decor2"></div>
            </div>
            <div className="row foundig_div">
              <div className="col-sm-12 col-md-4">
                <div className="founding_imh"><img src="../static/img/img2.jpg" alt="Ericka Craft Mizuta, DPT"/></div>
              </div>
              <div className="col-sm-12 col-lg-8"> 
                <h4>Ericka Craft Mizuta, DPT</h4>
                <p>I grew up in the Appalachian Mountains in a small town called Wise located in Southwestern, VA.  </p>
                <p>My parents were both in the education field and always talked to my sister and I from an early age about our life career paths.  When I was in 7th grade, I was introduced to the world of rehabilitation/physical therapy by my uncle who was a PT.  I became interested in the field and started volunteering at a local PT clinic to learn more.  By 8th grade, I knew that I wanted to become a physical therapist. </p>
                <p>By the time I started my senior year in high school (1997) I had already been accepted by early admission into Virginia Commonwealth University’s undergraduate Biology program and into the physical graduate program by a track called guaranteed admission.  I completed three years undergraduate work at VCU and then transferred into their PT graduate program for my Doctorate Degree.  In 2003 I graduated with Honors from VCU with a doctorate degree in physical therapy. </p>
              </div>
              <div className="col-sm-12 col-lg-12">
                <p>During my time in school I traveled all over the United States living in Augusta, Georgia; Las Vegas, Nevada; Palo Alto, California; and Laguna Hills, CA.  I worked at the Medical College of Georgia, St. Rose Dominican Hospital, Stanford University, as well as a Bauer Physical Therapy.  My experiences from growing up in rural Virginia and then moving around the country gave me different perspectives on patient care, insight regarding the accessibility and affordability to healthcare.  Upon graduation I started working for Krpata Sports Therapy in Carmel, CA.  The owner Chuck Krpata increased my interest in wanting to own a private practice that provides quality healthcare and one on one treatment.  </p>
                <p>In 2009 I decided that the time was right to open my own outpatient clinic.  I had been living in San Diego since 2006 and we were right in the middle of a recession.  I had faith though that no matter the economic state, there was a need for quality healthcare.  Mizuta & Associates Physical Therapy was formed and has grown and evolved over the last 10 years.  Our mission is to provide quality one on one care for one hour with a physical therapist not utilizing aides or techs.  This is a concept that our patients have sought and embraced.  Mizuta & Associates Physical Therapy has evolved over the years and is now a mobile orthopedic practice.  We have therapists all over San Diego County providing quality care and I provide treatment out of a residential studio. </p>
                <p>Over the years I have listened closely to patients in regard to their struggles for optimal health.  Many patients would put off therapy because they were too busy at work or had duties with children or aging parents at home. Financial hardship or lack of medical insurance is another problem that millions of Americans have and a leading cause of not seeking medical advice. </p>
                <p>Many of my clients would start their treatment with “google” not seeking out the advice of a licensed practitioner.  Some would come across legitimate helpful information on the internet but more often than not, they would be confused about what they found or have sources of non-licensedmedical professionals.  This led me to a greater idea that if care was more accessible and a patient would not have to leave their house or work for treatment that more people would address their issues.  </p>
                <p>Mary and I started planning a telehealth business back in 2017 and have been working diligently on what that healthcare model will offer potential patients.  We have come up with a model that will allow a patient to have a quality one on one direct telehealth visit with a healthcare practitioner.  </p>
                <p>We look forward to working with you and bring quality, accessible, and affordable health care to you on demand wherever you may be so that you can live life as healthy as possible. </p>
              </div>
            </div>
            <div className="row foundig_div">
              <div className="col-sm-12 col-lg-4">
                <div className="founding_imh">
                  <img src="../static/img/img1.jpg" alt="Mary McLendon"/>
                </div>
              </div>
              <div className="col-sm-12 col-lg-8">
                <h4>Mary McLendon</h4>
                <p>I grew up on the Gulf Coast of Mississippi in a small town call Ocean Springs. I became interested in the healthcare field in high school.  I volunteered at the local hospital as a Candy Striper – delivering ice and juice to patients in their hospital rooms.  I took allied health courses in high school which allowed me to make rounds at different health care facilities and observe medical providers in their place of work.  I was accepted to Clemson University in 2005 in the pre pharmacy program.  After working at a hospital pharmacy for a couple of years, I realized that I needed more human interaction and switched my major to psychology with a focus on health.  I graduated Clemson in 2010 with a Bachelor of Science in Psychology.  </p>
                <p>In 2012 I started working at Mizuta & Associates Physical Therapy and did the marketing for Ericka.  I believed in promoting health and wellness.  I also did administrative duties and would interact with patients at the clinic.  This allowed me to connect with patients in a way that was fulfilling. </p>
                <p>While working at Mizuta PT, I would notice sometimes our patients would go on vacation during the course of their treatment.   Before any vacation, the patient was left with a good home exercise program and given the therapist’s contact information in case there were any questions or concerns during the absence of PT.  Ericka and I thought it would be a great idea to have a platform where PTs could face time patients for a session while they were out of town. </p>
              </div>
              <div className="col-sm-12 col-md-12">
                <p>This idea expanded when realizing so many potential patients call with an orthopedic problem and want to know if physical therapy can help their issue. The front office at our clinic did not have the knowledge to answer specific PT related questions; the patient needed talk to a physical therapist. We also had patients who could not find the time to drive to physical therapy who would prefer to have a session in the convenience of their office or home.  In addition, we had post-operative patients who progressed with PT at the clinic, but they would still need a follow up visit to make sure they were still progressing and meeting goals.  By creating Recover, this would allow the patients to have the follow up visit without the commute and all patients to have physical therapy session in the patient’s place of choice. </p>
                <p>In March 2020, Mizuta & Associates Physical Therapy began treating more patients through telehealth due to the COVID-19 Pandemic.  Our patients realized they could get the same results doing a physical therapy visit virtually.  Ericka and I began to work on Recover, pushing forward with convenient, accessibility healthcare solutions.  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}
export default withLocalize(FoundingStoriesPage);
