import React from 'react';
import { withRouter } from 'react-router-dom';
import PatientProfileComponent from './PatientProfilePage';
import PtProfileComponent from './PtProfilePage';
import ClinicProfileComponent from './ClinicProfilePage';
import BreadcrumbsComp from '../../../../_Common/Breadcrumbs';
import MetaTags from 'react-meta-tags';

const ProfilePage = ({history}) => {

	return (
	<div>
    <MetaTags>
      <title>My Profile</title>
      <meta name="description" content="Recover" />
      <meta name="keywords" content="Recover" />
    </MetaTags>

    <section className="dash_section pad-reggis">
      <BreadcrumbsComp bcChanges={[{path: '/profile', breadcrumb: 'My Profile'}]} />
      
      <div className="container">
        {localStorage.getItem("userType")==='PATIENT' &&
          <PatientProfileComponent history={history}/>
        }              
        {localStorage.getItem("userType")==='PT' &&
          <PtProfileComponent history={history}/>
        }              
        {localStorage.getItem("userType")==='CLINIC' &&
          <ClinicProfileComponent history={history}/>
        }
      </div>
    </section>
	</div>
	);
}
export default withRouter(ProfilePage);
