import React,{useState, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import {getSinglePTDate, appointmentStatusAction, appointmentStartEndAction, getTwilioToken} from '../../Actions/User.action';
import { appSocket } from '_Constants/socket.js';
import { appConstants, checkExtFunction, toUTC} from '_Constants/app.constants';
import moment from 'moment';
import PatientMedicalHistory from '../../Components/DashboardPage/Profile/PatientMedicalHistory';
import NotesTemplatePage from './NotesTemplate';
import PreviousNotesPage from './PreviousNotes';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import { connect } from 'twilio-video';
import $ from 'jquery';
const { isMobile } = require('./browser');

var isAvailabile = false;
var isDevicesStatus = true;
var callEndStatus = false;
var callStatus = false;
var callDisconnect = false;
var stream = [];

const Session = (props) => {	
	const [token, setToken] = useState('');
	props.history.listen(event => {
		//console.log('listen', event.pathname);
		//alert('do note refresh');
		window.location = event.pathname;
		return false;
	});
	if(props.location.state && props.location.state._id){
		
	}else{		
		window.location = "future-appointments";		
	}

	let $leave = $('#leave-room');
	let $muteAudio = $('#audioMute');
	let $muteVideo = $('#videoMute');
	let $unmuteAudio = $('#unmuteAudio');
	let $unmuteVideo = $('#unmuteVideo');
	let $room = $('#sessionvideo_sec');
	let $activeParticipant = $('div#active-participant > div.participant.main', $room);
	let $activeVideo = $('video', $activeParticipant);
	let $participants = $('div#participants', $room);
	let $myWebCamScreen = $('div#myWebCam', $room);
	let audioStatus = 'mute';
	let videoStatus = 'mute';

	// The current active Participant in the Room.
	let activeParticipant = null;
	// Whether the user has selected the active Participant by clicking on
	// one of the video thumbnails.
	let isActiveParticipantPinned = false;

	const displayDetail=(data)=>{	
		 
		document.getElementById("name").innerText = localStorage.getItem("userType")==='PT'?data.paName:data.ptName;
		document.getElementById("appointmentDate").innerText = moment(data.date, 'YYYY-MM-DD').format(appConstants.DATEFORMAT);
		document.getElementById("slot").innerHTML = data.startTime+" - "+data.endTime; 		
		
		if(data.status == 'FINISHED'){
			document.getElementById('sessionvideo_sec').style.display = "none";
		}
  	}

	/**
 * Set the active Participant's video.
 * @param participant - the active Participant
 */
function setActiveParticipant(participant) {
	const { identity, sid } = participant;
	//console.log(identity);
	if (activeParticipant) {
	  const $activeParticipant = $(`div#${activeParticipant.sid}`, $participants);
	  $activeParticipant.removeClass('active');
	  $activeParticipant.removeClass('pinned');
  
	  // Detach any existing VideoTrack of the active Participant.
	  const { track: activeTrack } = Array.from(activeParticipant.videoTracks.values())[0] || {};
	  if (activeTrack) {
		activeTrack.detach($activeVideo.get(0));
		$activeVideo.css('opacity', '0');
	  }
	}
  
	// Set the new active Participant.
	activeParticipant = participant;
	
	const $participant = $(`div#${sid}`, $participants);
  
	$participant.addClass('active');
	if (isActiveParticipantPinned) {
	  $participant.addClass('pinned');
	}
  
	// Attach the new active Participant's video.
	const { track } = Array.from(participant.videoTracks.values())[0] || {};
	if (track) {
	  track.attach($activeVideo.get(0));
	  $activeVideo.css('opacity', '');
	}
  
	// Set the new active Participant's identity
	$activeParticipant.attr('data-identity', identity);
  }
  
  /**
   * Set the current active Participant in the Room.
   * @param room - the Room which contains the current active Participant
   */
  function setCurrentActiveParticipant(room) {
	  //console.log(room);
	const { dominantSpeaker, localParticipant } = room;
	setActiveParticipant(dominantSpeaker || localParticipant);
  }
  
  /**
   * Set up the Participant's media container.
   * @param participant - the Participant whose media container is to be set up
   * @param room - the Room that the Participant joined
   */
  function setupParticipantContainer(participant, room) {
	const { identity, sid } = participant;
  
	// Add a container for the Participant's media.
	const $container = $(`<div class="participant mywebCam" data-identity="${identity}" id="${sid}">
	  <audio autoplay ${participant === room.localParticipant ? 'muted' : ''} style="opacity: 0"></audio>
	  <video autoplay muted playsinline style="opacity: 0"></video>
	</div>`);
  
	// Toggle the pinning of the active Participant's video.
	/*$container.on('click', () => {
		
	  if (activeParticipant === participant && isActiveParticipantPinned) {
		// Unpin the RemoteParticipant and update the current active Participant.
		setVideoPriority(participant, null);
		isActiveParticipantPinned = false;
		setCurrentActiveParticipant(room);
	  } else { 
		// Pin the RemoteParticipant as the active Participant.
		if (isActiveParticipantPinned) {
		  setVideoPriority(activeParticipant, null);
		  
		}
		setVideoPriority(participant, 'high');
		isActiveParticipantPinned = true;
		setActiveParticipant(participant);
	  }
	});*/
	//console.log('bhim singh goud');
	//console.log($container);
	// Add the Participant's container to the DOM.
	$participants.append($container);
  }

  function setupParticipantContainerLocal(participant, room) {
	const { identity, sid } = participant;
  
	// Add a container for the Participant's media.
	const $container = $(`<div>
	  <audio autoplay muted style="opacity: 0"></audio>
	  <video autoplay muted playsinline></video>
	</div>`);
  
	// Toggle the pinning of the active Participant's video.
	$container.on('click', () => {
		//console.log('up');
	  /*if (activeParticipant === participant && isActiveParticipantPinned) {
		// Unpin the RemoteParticipant and update the current active Participant.
		setVideoPriority(participant, null);
		isActiveParticipantPinned = false;
		setCurrentActiveParticipant(room);
	  } else { 
		// Pin the RemoteParticipant as the active Participant.
		if (isActiveParticipantPinned) {
		  setVideoPriority(activeParticipant, null);
		  
		}
		setVideoPriority(participant, 'high');
		isActiveParticipantPinned = true;
		setActiveParticipant(participant);
	  }*/
	});
	//console.log('bhim singh goud');
	//console.log($container);
	// Add the Participant's container to the DOM.
	$myWebCamScreen.append($container);
  }
  
  /**
   * Set the VideoTrack priority for the given RemoteParticipant. This has no
   * effect in Peer-to-Peer Rooms.
   * @param participant - the RemoteParticipant whose VideoTrack priority is to be set
   * @param priority - null | 'low' | 'standard' | 'high'
   */
  function setVideoPriority(participant, priority) {
	participant.videoTracks.forEach(publication => {
	  const track = publication.track;
	  if (track && track.setPriority) {
		track.setPriority(priority);
	  }
	});
  }
  
  /**
   * Attach a Track to the DOM.
   * @param track - the Track to attach
   * @param participant - the Participant which published the Track
   */
  function attachTrack(track, participant) {
	// Attach the Participant's Track to the thumbnail.
	const $media = $(`div#${participant.sid} > ${track.kind}`, $participants);
	$media.css('opacity', '');
	track.attach($media.get(0));
  
	// If the attached Track is a VideoTrack that is published by the active
	// Participant, then attach it to the main video as well.
	if (track.kind === 'video' && participant === activeParticipant) {
	  track.attach($activeVideo.get(0));
	  $activeVideo.css('opacity', '');
	}
  }
  
  /**
   * Detach a Track from the DOM.
   * @param track - the Track to be detached
   * @param participant - the Participant that is publishing the Track
   */
  function detachTrack(track, participant) {
	// Detach the Participant's Track from the thumbnail.
	const $media = $(`div#${participant.sid} > ${track.kind}`, $participants);
	$media.css('opacity', '0');
	track.detach($media.get(0));
  
	// If the detached Track is a VideoTrack that is published by the active
	// Participant, then detach it from the main video as well.
	if (track.kind === 'video' && participant === activeParticipant) {
	  track.detach($activeVideo.get(0));
	  $activeVideo.css('opacity', '0');
	}
  }
  
  /**
   * Handle the Participant's media.
   * @param participant - the Participant
   * @param room - the Room that the Participant joined
   */
  function participantConnected(participant, room) {
	const { identity, sid } = participant;
	let name = localStorage.getItem("userType")==='PT'?props.location.state.paEmail:props.location.state.ptEmail;
	if(identity == name){ 
		document.getElementById("audioMute").style.display = "inline-block";
		document.getElementById("videoMute").style.display = "inline-block";
		document.getElementById("full-screen").style.display = "inline-block";
		document.getElementById("timer").style.display = "inline-block";
		document.getElementById('wait-msg').innerHTML = "";	
		
		if(sessionStorage.videoStatus == 'un-mute'){
			
			setTimeout(() => {
				
				$muteVideo.trigger('click');
			},2000);
		}
		if(sessionStorage.audioStatus == 'un-mute'){
			
			setTimeout(() => {
				
				$muteAudio.trigger('click');
			},2000);
		}
		//console.log(room);
	
	}
	// Set up the Participant's media container.
	setupParticipantContainer(participant, room);
	
	// Handle the TrackPublications already published by the Participant.
	participant.tracks.forEach(publication => {
	trackPublished(publication, participant);
	});

	// Handle theTrackPublications that will be published by the Participant later.
	participant.on('trackPublished', publication => {
	trackPublished(publication, participant);
	});
  }
  
  /**
   * Handle a disconnected Participant.
   * @param participant - the disconnected Participant
   * @param room - the Room that the Participant disconnected from
   */
  function participantDisconnected(participant, room) { //console.log('disconnect');
	// If the disconnected Participant was pinned as the active Participant, then
	// unpin it so that the active Participant can be updated.
	if (activeParticipant === participant && isActiveParticipantPinned) { 
		//console.log('participantDisconnected');
	  isActiveParticipantPinned = false;
	  //setCurrentActiveParticipant(room);
	}
  
	// Remove the Participant's media container.
	$(`div#${participant.sid}`, $participants).remove();
  }
  
  /**
   * Handle to the TrackPublication's media.
   * @param publication - the TrackPublication
   * @param participant - the publishing Participant
   */
  function trackPublished(publication, participant) {
	// If the TrackPublication is already subscribed to, then attach the Track to the DOM.
	if (publication.track) {
	  attachTrack(publication.track, participant);
	}
  
	// Once the TrackPublication is subscribed to, attach the Track to the DOM.
	publication.on('subscribed', track => {
	  attachTrack(track, participant);
	});
  
	// Once the TrackPublication is unsubscribed from, detach the Track from the DOM.
	publication.on('unsubscribed', track => {
	  detachTrack(track, participant);
	});
  }
  
  /**
   * Join a Room.
   * @param token - the AccessToken used to join a Room
   * @param connectOptions - the ConnectOptions used to join a Room
   */
  async function joinRoom(token, connectOptions) {
	
	// Join to the Room with the given AccessToken and ConnectOptions.
	
	const room = await connect(token, connectOptions);
	
	// Save the LocalVideoTrack.
	let localVideoTrack = Array.from(room.localParticipant.videoTracks.values())[0].track;
  
	// Make the Room available in the JavaScript console for debugging.
	window.room = room;
	
	// Handle the LocalParticipant's media.
	participantConnected(room.localParticipant, room);
	//setupParticipantContainerLocal(room.localParticipant, room);
	
	
	// Subscribe to the media published by RemoteParticipants already in the Room.
	room.participants.forEach(participant => {
		
	  participantConnected(participant, room);
	  	setVideoPriority(participant, 'high');
		isActiveParticipantPinned = true;
		setActiveParticipant(participant);
	});
  
	// Subscribe to the media published by RemoteParticipants joining the Room later.
	room.on('participantConnected', participant => {
		
	  participantConnected(participant, room);
	  setVideoPriority(participant, 'high');
		isActiveParticipantPinned = true;
		setActiveParticipant(participant);
	  
	});
  
	// Handle a disconnected RemoteParticipant.
	room.on('participantDisconnected', participant => {
		//console.log('in4');
	  participantDisconnected(participant, room);
	 
	});

	room.on('reconnecting', error => {
		//room.equal(window.room.state, 'reconnecting');
		if (error.code === 53001) {
		  //console.log('Reconnecting your signaling connection!', error.message);
		} else if (error.code === 53405) {
		  //console.log('Reconnecting your media connection!', error.message);
		}else{
			//console.log(error);
		}
		/* Update the application UI here */
	  });
	room.on('reconnected', () => {
		//room.equal(window.room.state, 'connected');
		//console.log('Reconnected your signaling and media connections!');
		/* Update the application UI here */
	  });
  
	// Set the current active Participant.
	//setCurrentActiveParticipant(room);
	
	// Update the active Participant when changed, only if the user has not
	// pinned any particular Participant as the active Participant.
	room.on('dominantSpeakerChanged', () => {
		
	  if (!isActiveParticipantPinned) {
		setCurrentActiveParticipant(room);
	  }
	});
  
	// Leave the Room when the "Leave Room" button is clicked.
	$leave.click(function onLeave() {
	  $leave.off('click', onLeave);
	  //.log('onLeave');
	  room.disconnect();
	});


	
/*const audioMute = ()=>{

	if(stream){		
	
		if(audioStatus=='mute'){
			stream.mute({
				audio: true,
				video: true
			});
			audioStatus = 'un-mute';
			sessionStorage.audioStatus = audioStatus;
			document.getElementById("audio-un-mute").style.display = "none";
			document.getElementById("audio-mute").style.display = "inline-block";
		}else{
			stream.unmute({
				audio: true,
				video: true
			});
			audioStatus = 'mute';
			sessionStorage.audioStatus = audioStatus;
			document.getElementById("audio-un-mute").style.display = "inline-block";
			document.getElementById("audio-mute").style.display = "none";
		}
	}

}


const videoMute = ()=>{
	
	for(var e=stream.outgoingStream.getVideoTracks(),t=0;t<e.length;t++)
	{
		if(videoStatus == 'mute'){
			videoStatus = 'un-mute';
			sessionStorage.videoStatus = videoStatus;
			e[t].enabled=!1;
			document.getElementById("video-un-mute").style.display = "none";
			document.getElementById("video-mute").style.display = "inline-block";
		}else{
			videoStatus = 'mute';
			sessionStorage.videoStatus = videoStatus;
			e[t].enabled=!0;
			document.getElementById("video-un-mute").style.display = "inline-block";
			document.getElementById("video-mute").style.display = "none";
		}
	}
}*/

	
	
  
	return new Promise((resolve, reject) => { console.log('in7');
	  // Leave the Room when the "beforeunload" event is fired.
	  window.onbeforeunload = () => { //console.log('onbeforeunload');
		room.disconnect();
	  };
  
	  if (isMobile) {
		// TODO(mmalavalli): investigate why "pagehide" is not working in iOS Safari.
		// In iOS Safari, "beforeunload" is not fired, so use "pagehide" instead.
		window.onpagehide = () => { //console.log('onpagehide');
		  room.disconnect();
		};
  
		// On mobile browsers, use "visibilitychange" event to determine when
		// the app is backgrounded or foregrounded.
		document.onvisibilitychange = async () => { //console.log('in');
		  if (document.visibilityState === 'hidden') {
			// When the app is backgrounded, your app can no longer capture
			// video frames. So, stop and unpublish the LocalVideoTrack.
			//localVideoTrack.stop();
			//room.localParticipant.unpublishTrack(localVideoTrack);
		  } else {
			// When the app is foregrounded, your app can now continue to
			// capture video frames. So, publish a new LocalVideoTrack.
			//localVideoTrack = await createLocalVideoTrack(connectOptions.video);
			//await room.localParticipant.publishTrack(localVideoTrack);
		  }
		};
	  }
  
	  room.once('disconnected', (room, error) => { //console.log('disconnected');
	 // console.log(room);
		if(navigator.onLine){
			  callDisconnect = false;
			  callTo('reconnect');
		}else{
			callDisconnect = true;
		}
		  //console.log('disconnected');
		  //console.log(room);
		  //console.log(error);
		// Clear the event handlers on document and window..
		window.onbeforeunload = null;
		if (isMobile) {
		  window.onpagehide = null;
		  document.onvisibilitychange = null;
		}
  
		// Stop the LocalVideoTrack.
		//localVideoTrack.stop();
  
		// Handle the disconnected LocalParticipant.
		//participantDisconnected(room.localParticipant, room);
  
		// Handle the disconnected RemoteParticipants.
		room.participants.forEach(participant => {
		 // participantDisconnected(participant, room);
		});
  
		// Clear the active Participant's video.
		//$activeVideo.get(0).srcObject = null;
  
		// Clear the Room reference used for debugging from the JavaScript console.
		//window.room = null;
  
		if (error) {
		  // Reject the Promise with the TwilioError so that the Room selection
		  // modal (plus the TwilioError message) can be displayed.
		  //reject(error);
		} else {
		  // Resolve the Promise so that the Room selection modal can be
		  // displayed.
		  //resolve();
		}
	  });
	});
  }

  //Mute Audio
	$muteAudio.click(function () {
		console.log('audio');
		if(audioStatus=='mute'){ console.log('audio1');
			audioStatus = 'un-mute';
			sessionStorage.audioStatus = audioStatus;
			document.getElementById("audio-un-mute").style.display = "none";
			document.getElementById("audio-mute").style.display = "inline-block";
			window.room.localParticipant.audioTracks.forEach(publication => {
				publication.track.disable();
			});
		}else{ console.log('audio2');
			audioStatus = 'mute';
			sessionStorage.audioStatus = audioStatus;
			document.getElementById("audio-un-mute").style.display = "inline-block";
			document.getElementById("audio-mute").style.display = "none";
			window.room.localParticipant.audioTracks.forEach(publication => {
				publication.track.enable();
			  });

		}
	});
	
	  // Mute video
	$muteVideo.click(function () {
		console.log('video');
		if(videoStatus=='mute'){ console.log('video1');
			videoStatus = 'un-mute';
			sessionStorage.videoStatus = videoStatus;
			document.getElementById("video-un-mute").style.display = "none";
			document.getElementById("video-mute").style.display = "inline-block";
			window.room.localParticipant.videoTracks.forEach(publication => {
				publication.track.disable();
			});
		}else{ console.log('video2');
			videoStatus = 'mute';
			sessionStorage.videoStatus = videoStatus;
			document.getElementById("video-un-mute").style.display = "inline-block";
			document.getElementById("video-mute").style.display = "none";
			window.room.localParticipant.videoTracks.forEach(publication => {
				publication.track.enable();
			});
		}
	});

const callTo=async(str='')=>{	
	dragElement(document.getElementById("participants"));
	document.getElementById("participants").innerHTML = "";
	$leave = $('#leave-room');
	$muteAudio = $('#audioMute');
	$muteVideo = $('#videoMute');
	$unmuteAudio = $('#unmuteAudio');
	$unmuteVideo = $('#unmuteVideo');
	$room = $('#sessionvideo_sec');
	sessionStorage.callStarted = 'true';
	if(localStorage.getItem("userType") == 'PT'){
		if(str == ''){
			
			appSocket.socket.emit('session',{userId:props.location.state.paId, status:'start'});
			let dateTime = moment().format('YYYY-MM-DD hh:mm A');
			appointmentStartEndAction({
				"appointmentId":props.location.state._id,
				"status": "start",
				"date":dateTime
			});
			
		}		
		let callBtn = document.getElementById("callBtn");
		callBtn.style.display = "none";	
	}	
	localStorage.callStartTime = moment().format('YYYY-MM-DD HH:mm:ss');
	$activeParticipant = $('div#active-participant > div.participant.main', $room);
	$activeVideo = $('video', $activeParticipant);
	$participants = $('div#participants', $room);
	$myWebCamScreen = $('div#myWebCam', $room);
		console.log(props.location.state);
		let name = localStorage.getItem("userType")==='PT'?props.location.state.ptEmail:props.location.state.paEmail;
		let room = props.location.state._id;
		let data = await getTwilioToken({identity:name, roomName:room});
		setToken(data);  
		joinRoom(data,{ name:room, audio: true, video: { width: 640 } });
		/*connect(data, { name:room, audio: true, video: { width: 640 } }).then(room => {
			console.log(`Successfully joined a Room: ${room}`);

			room.on('participantConnected', participant => {
				participant.tracks.forEach(publication => {
					if (publication.isSubscribed) {
					  const track = publication.track;
					  document.getElementById('sessionvideo').appendChild(track.attach());
					}
				  });
				
				  participant.on('trackSubscribed', track => {
					document.getElementById('sessionvideo').appendChild(track.attach());
				  });
			});

			room.on('participantDisconnected', participant => {
				console.log(`Participant disconnected: ${participant.identity}`);
			  });
			  
		  }, error => {
			console.error(`Unable to connect to Room: ${error.message}`);
		  }); */
	}

	const getPtDetails = async(status='')=>{
		let date = toUTC(moment(props.location.state.date+' '+props.location.state.startTime, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD HH:mm:ss"));
		let PTDetail = await getSinglePTDate({paId:props.location.state.paId,ptId:props.location.state.ptId,appointmentId:props.location.state._id, date:date});
		let today = moment().format("YYYY-MM-DD");
		let appointmentData = PTDetail.data.appointmentData;
		
		appointmentData.date = moment(appointmentData.startTime).format('YYYY-MM-DD');
		appointmentData.startTime = moment(appointmentData.startTime).format('hh:mm A');		
		appointmentData.endTime = moment(appointmentData.endTime).format('hh:mm A');
		
		sessionStorage.appointment = JSON.stringify(appointmentData);
		displayDetail(appointmentData);	
		
		if(PTDetail.data.time && status != 'FINISHED' && appointmentData.status != 'FINISHED'){

			if(appointmentData.noShowStatus == "true"){
				window.location = "appointment-history";
			}
			let userActionData = appointmentData.userAction;
	
			let callStartStatus = userActionData.some(function(item){
				return item.userAction == 'start';
			});


	
			if(callStartStatus){
				sessionStorage.callStarted = 'true';
				let callStartDateTime = userActionData.map(function(item){
					if(item.userAction == 'start'){
						return item.userActionDate;
					}
				});
				//console.log(callStartDateTime[callStartDateTime.length-1]);
				localStorage.callStartTime = moment(callStartDateTime[callStartDateTime.length-1]).format('YYYY-MM-DD HH:mm:ss');
	
			}else{
				sessionStorage.callStarted = 'false';
			}

			let timeList = PTDetail.data.time;			
			let appDate = moment(appointmentData.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
			let bookingData = PTDetail.data.bookingData?PTDetail.data.bookingData:[];
			let endTimeStr = moment(today+" "+appointmentData.endTime, 'YYYY-MM-DD hh:mm A').format("x");
			let extentStrTime = moment(today+" "+appointmentData.endTime, 'YYYY-MM-DD hh:mm A').add(30, 'minutes').format("x");
			

			if(moment().format('x') >= moment(appDate+" "+appointmentData.startTime, 'YYYY-MM-DD hh:mm A').format('x') && moment().add(1, 'minutes').format('x') <= moment(appDate+" "+appointmentData.endTime, 'YYYY-MM-DD hh:mm A').format('x') && localStorage.getItem("userType") !== 'CLINIC'){ 
				//console.log('callTo123');
				//console.log(callStartStatus);
				if(localStorage.getItem("userType")==='PT' && callStartStatus === false && isDevicesStatus === true && isAvailabile === true){	
					document.getElementById('callBtn').style.display = "inline-block";
					document.getElementById('wait-msg').innerHTML = "";
				}else if(callStartStatus === false){
				
					document.getElementById('wait-msg').innerHTML = "Please Wait....";
				}else if(callStartStatus === true && status == 'load'){
					//console.log('callTo');
					callTo(callStartStatus);
				}
			}else if(callStartStatus === false){
				
				document.getElementById('wait-msg').innerHTML = "Please Wait....";
			}
			
			if(appointmentData.extentSessionStatus==='REQUESTED' && localStorage.getItem("userType") === 'PT' && appointmentData.status != 'FINISHED'){
				sessionStorage.extentSession = 'true';
				document.getElementById('Extend-Request').style.display = "inline-block";
			}			

			if(appointmentData.extentSessionStatus==='' && appointmentData.duration == 30){ 
				for(let i in timeList){
					
					if(moment(timeList[i].startTime).format('x') < extentStrTime && moment(timeList[i].endTime).format('x')>=extentStrTime){ 
						if(bookingData){ 
							let bookStatus = bookingData.some(function(item){
								return endTimeStr == moment(item.startTime).format("x");
							});
							
							if(!bookStatus){ 
								sessionStorage.extentSession = 'true';
								//setExtentSession(true);
								if(moment().add(1, 'minutes').format('x') < moment(appDate+" "+appointmentData.endTime, 'YYYY-MM-DD hh:mm A').format('x') && moment().add(10, 'minutes').format('x') >= moment(appDate+" "+appointmentData.endTime, 'YYYY-MM-DD hh:mm A').format('x') && localStorage.getItem("userType") === 'PATIENT' && sessionStorage.callStarted === 'true'){
									
									document.getElementById('btn-extend').style.display = "inline-block";
										
								}											
							}else{
								sessionStorage.extentSession = 'false';
							}
							
						}
					}
				}
			}			
		}else if(appointmentData.status != 'FINISHED'){
			window.location = "";
		}

		if(PTDetail.data && PTDetail.data.appointmentData && PTDetail.data.appointmentData.paDocs){
			var patDocs = PTDetail.data.appointmentData.paDocs;
			var textValue = '';
			if(patDocs && patDocs.length > 0){
				for (var i = 0; i < patDocs.length; i++) {
				    textValue+='<div class="col-lg-2" key="'+i+'"><div class="document_single">';
				    textValue+='<div class="document_single_img">';
				    textValue+= patDocs[i]['imgPath']?(checkExtFunction(patDocs[i]['imgPath'])):''
				    textValue+='</div>';
				    textValue+='<label>'+patDocs[i]['title']?patDocs[i]['title']:'-'+'</label>';
				    textValue+="</div></div>";
				}
			}else{
				textValue+= '<p>No documents saved</p>';
			}
			if(document.getElementById('patientDocs')){
				document.getElementById('patientDocs').innerHTML = textValue;
			}			
		}
	}

	const gotDevices = async()=>{
	
		isDevicesStatus = true;
	  }
	  const errorDevices = async(err)=>{
		isDevicesStatus = true;
		//console.log(err);
		//document.getElementById('wait-msg').innerHTML = "Error, Audio/Video devices not working. Please check audio/video devices proper connect or allow permission.";
		
	  }
	
	const noShow = async(typ,apId)=>{
		if(typ == 'patient'){
		var parm = {appointmentId:apId, status:'CANCEL', noShowStatus:"true"};
		}else{
		var parm =  {appointmentId:apId, status:'FINISHED', noShowStatus:"true"}
		}
	
		await appointmentStatusAction(parm); 
		
		let receiveId = localStorage.getItem("userType") === 'PATIENT'?props.location.state.ptId:props.location.state.paId;	
		appSocket.socket.emit('session',{userId:receiveId, status:'NO SHOW'});
		window.location = "appointment-history";
	}

	const callHangup=async()=>{
		//room.disconnect();
		if(callEndStatus === false){
			sessionStorage.callStarted = 'false';
			let appData = JSON.parse(sessionStorage.appointment);
			//stream && stream.hangup();
			appSocket.socket.emit('session',{ptId:appData.ptId, userId:appData.paId, status:'FINISHED'});
			let reponse = await appointmentStatusAction({appointmentId:appData._id, status:'FINISHED'}); 
			callEndStatus = true;
			getPtDetails('FINISHED');	
		}		
	}	
	  
 
	const App = (onstart, onload) => {
		onstart();  
		//console.log('App');
		const img = document.createElement("img");
		img.src = "https://picsum.photos/640/480?v="+moment().format('x');
		img.className = "hide";
		img.onload = () => {
		  img.className = "";
		  onload();
		  //console.log('onload');
		};
	}
	  
	let isSlow;
	let loaded;
	
	const loadApp = () => {
	App(
		() => {
		loaded = false;
		isSlow = setTimeout(() => {
			// Show "Slow Internet Connection." message.
			document.getElementById('net-msg').innerHTML = 'Slow Internet Connection.';
			//console.log("slow");
		},1500); // Intentionally a low number for testing. Try some different values here, or play with different Internet Throttling setting.
		},
		() => {
		loaded = true;
		clearTimeout(isSlow);
		//console.log("loaded");
	});
	};
	 
	  // We are "offline".
	window.addEventListener("offline", () => {
		// Show "No Internet Connection." message.
		sessionStorage.online = 'false';
		callStatus = false;
	
		document.getElementById('net-msg').innerHTML = 'No Internet Connection.';
		console.log("offline");
		if(window.room){
			window.room.disconnect();
		}
	  });
	  
	  
	// When we back "online".
	window.addEventListener("online", () => {
		console.log('online');
		document.getElementById('net-msg').innerHTML = '';
		if(window.room){
			window.room.disconnect();
		}
		window.location.reload();	
		if(callDisconnect == true){
			
			callTo('reconnect');
			
			let receiveId = localStorage.getItem("userType") === 'PATIENT'?props.location.state.ptId:props.location.state.paId;
			appSocket.socket.emit('session',{userId:receiveId, status:'refresh'});
		}
		// Show "Back online" message.
	
		
		//window.location.reload();		
	});
	
useEffect(()=>{
		loadApp();

	//get_TwilioToken();
	//console.log(sessionStorage);
	//console.log(props.location.state);
	if(navigator.onLine){
		sessionStorage.online = 'true';
	}else{
		sessionStorage.online = 'false';
	}
	sessionStorage.counter = 0;
	
	if(sessionStorage.appointmentId && props.location.state && sessionStorage.appointmentId == props.location.state._id){
		
	}else{
		sessionStorage.appointment = '';
		sessionStorage.callStarted = 'false';
		sessionStorage.extentSession = 'false';
		sessionStorage.appointmentId = props.location.state._id;
		sessionStorage.timeer = 0;
	}

	var apptDetails = sessionStorage.appointment?JSON.parse(sessionStorage.appointment):'';

	if((sessionStorage.appointment != '' && apptDetails && apptDetails._id == props.location.state._id &&  apptDetails.status == 'FINISHED') || (props.location.state && props.location.state.status == 'FINISHED')){

	}else{
	
		navigator.getUserMedia = navigator.getUserMedia ||
							navigator.webkitGetUserMedia ||
							navigator.mozGetUserMedia;
		if (navigator.getUserMedia) {						 
			navigator.getUserMedia({ audio: true, video: true },gotDevices, errorDevices);
		}else{
			isDevicesStatus = true;
		}
	}	
	
	getPtDetails('load');
	
	let receiveId = localStorage.getItem("userType") === 'PATIENT'?props.location.state.ptId:props.location.state.paId;	

	appSocket.socket.emit('addUser', {userId:localStorage.getItem('_id'), receiveId:receiveId, 'status':'addUser'});

	let interval = setInterval(() => {
		
		//console.log(sessionStorage.timeer);
	
		if(!isAvailabile && navigator.onLine){
			appSocket.socket.emit('addUser', {userId:localStorage.getItem('_id'), receiveId:receiveId, 'status':'addUser'});
		}

		let appData = sessionStorage.appointment!=''?JSON.parse(sessionStorage.appointment):[];
		var appDate = appData.date?moment(appData.date, 'YYYY-MM-DD').format('YYYY-MM-DD'):'';
			
			if(sessionStorage.appointment != '' && appData && appData._id == props.location.state._id  && isDevicesStatus === true && appData.status != 'FINISHED'){								
				
				if(moment().format('x') >= moment(appDate+" "+appData.startTime, 'YYYY-MM-DD hh:mm A').format('x') && moment().add(1, 'minutes').format('x') <= moment(appDate+" "+appData.endTime, 'YYYY-MM-DD hh:mm A').format('x') && localStorage.getItem("userType") !== 'CLINIC'){
					var userType = localStorage.getItem("userType")==='PT'?'Patient':'Therapist';
					if(sessionStorage.callStarted === 'false'){
						if(localStorage.getItem("userType")==='PT' && isAvailabile===true){
							//sessionStorage.timeer = sessionStorage.timeer?parseInt(sessionStorage.timeer):0; 
						}else{
							if(navigator.onLine){
								sessionStorage.timeer = sessionStorage.timeer?parseInt(sessionStorage.timeer)+1:0; 
							}
						}
					}else{
						sessionStorage.timeer = 0;
						if(callStatus === false){												
							//console.log('rejoin');
							//callTo('rejoin');
						}	
					}
					
					if(localStorage.getItem("userType") === 'PT' && document.getElementById('wait-msg').innerHTML == 'Please Wait....'){
						document.getElementById('wait-msg').innerHTML = '';
					}
					
					if(localStorage.getItem("userType")==='PT' && sessionStorage.callStarted === 'false' && isAvailabile === true){ 

						document.getElementById('callBtn').style.display = "inline-block";

						if(document.getElementById('wait-msg').innerHTML === userType+" is not available. Please Wait...."){
							document.getElementById('wait-msg').innerHTML = '';
						}
						
					}else if(sessionStorage.callStarted === 'false' && isAvailabile === false){
						
						document.getElementById('wait-msg').innerHTML = userType+" is not available. Please Wait....";
						if(localStorage.getItem("userType")==='PT'){
							document.getElementById('callBtn').style.display = "none";
						}					
					}else if(localStorage.getItem("userType")==='PATIENT' && isAvailabile === true){
						if(document.getElementById('wait-msg').innerHTML === userType+" is not available. Please Wait...."){
							document.getElementById('wait-msg').innerHTML = '';
						}
					}
					
					if(localStorage.getItem("userType")==='PT' && moment().format('x') > moment(appDate+" "+appData.endTime, 'YYYY-MM-DD hh:mm A').add(-5, 'minutes').format('x') && sessionStorage.callStarted === 'true'){
						let hangupBtn = document.getElementById("hangupBtn");
						hangupBtn.style.display = "inline-block";
					}else if(localStorage.getItem("userType")==='PT'){
						let hangupBtn = document.getElementById("hangupBtn");
						hangupBtn.style.display = "none";
					}
										
					//No Show by pt side
					if(localStorage.getItem("userType")==='PATIENT' && moment().format('x') > moment(appDate+" "+appData.startTime, 'YYYY-MM-DD hh:mm A').add(5, 'minutes').format('x') && sessionStorage.callStarted === 'false' && sessionStorage.timeer >= 30){
						
						let userActionData = appData.userAction;
						let NoShowStatus = userActionData.some(function(item){
							return item.userAction == 'start';
						});
						//console.log(NoShowStatus);
						if(NoShowStatus == false){
							console.log('No Show');
							noShow('patient', appData._id);
						}
					}
					//No Show by patient side
					//console.log(sessionStorage.callStarted);
					
					if(localStorage.getItem("userType")==='PT' && moment().format('x') > moment(appDate+" "+appData.startTime, 'YYYY-MM-DD hh:mm A').add(15, 'minutes').format('x') && sessionStorage.callStarted === 'false' && isAvailabile === false && sessionStorage.timeer >= 90){
					
						let userActionData = appData.userAction;
						let NoShowStatus = userActionData.some(function(item){
							return item.userAction == 'start';
						});
						//console.log(NoShowStatus);
						if(NoShowStatus == false){
							//console.log('No Show');
							noShow('PT', appData._id);							
						}
					}
				}else{
					if(localStorage.getItem("userType")==='PT' && sessionStorage.callStarted === 'true'){ 
						document.getElementById('callBtn').style.display = "none";
					}
				}			
						
				if(moment().add(1, 'minutes').format('x') < moment(appDate+" "+appData.endTime, 'YYYY-MM-DD hh:mm A').format('x') && moment().add(10, 'minutes').format('x') >= moment(appDate+" "+appData.endTime, 'YYYY-MM-DD hh:mm A').format('x') && localStorage.getItem("userType") !== 'CLINIC' && appData.duration == 30){
					
					if(localStorage.getItem("userType")==='PATIENT' && sessionStorage.extentSession == 'true' && appData.extentSessionStatus=='' && sessionStorage.callStarted === 'true'){
						document.getElementById('btn-extend').style.display = "inline-block";
					}		
				}else{
					if(localStorage.getItem("userType")==='PATIENT' && sessionStorage.extentSession == 'true' && appData.extentSessionStatus==''){
						document.getElementById('btn-extend').style.display = "none";
					}
				}
				
				if(moment().format('x') > moment(appDate+" "+appData.endTime, 'YYYY-MM-DD hh:mm A').format('x') && localStorage.getItem("userType") !== 'CLINIC' && callEndStatus === false){
			
					let userActionData = appData.userAction;
					let NoShowStatus = userActionData.some(function(item){
						return item.userAction == 'start';
					});
					
					if(NoShowStatus == false && sessionStorage.callStarted === 'false'){
						
						if(isAvailabile === false && localStorage.getItem("userType") === 'PT'){
							
							noShow('PT', appData._id);
						}else if(localStorage.getItem("userType") === 'PATIENT'){

							
							noShow('patient', appData._id);
						}
					}else{
						callHangup();	
					}
												
				}				
			}
			
			if(appData.status != 'FINISHED'){
				if(isDevicesStatus === false){
					document.getElementById('wait-msg').innerHTML = "Error, Your audio/video devices not working. Please check audio/video devices proper connect or allow permission.";
				}else if(document.getElementById('wait-msg').innerHTML == "Error, Your audio/video devices not working. Please check audio/video devices proper connect or allow permission."){
					document.getElementById('wait-msg').innerHTML = '';
				}
			}			
	
	  }, 10000);
	  setInterval(() => {
		/*if(navigator.onLine){
			if(document.getElementById('wait-msg').innerHTML == "No Internet Connection"){
				document.getElementById('wait-msg').innerHTML = "";
			}
		}else{
			document.getElementById('wait-msg').innerHTML = "No Internet Connection";
		}*/
		let appData2 = sessionStorage.appointment != ''?JSON.parse(sessionStorage.appointment):'';

		if(sessionStorage.appointment != '' && appData2 && appData2._id == props.location.state._id && isDevicesStatus === true){
			let appData = JSON.parse(sessionStorage.appointment);
			var appDate = moment(appData.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
			if(sessionStorage.callStarted == 'true'){
				if(document.getElementById('videoincoming1')){
					document.getElementById('videoincoming1').style.display = "block";
				}
				if(localStorage.getItem("callStartTime") && localStorage.getItem("callStartTime")!=''){
				
					var startDateTime = localStorage.getItem("callStartTime");
					var seconds = Math.ceil((moment().format('x') - moment(startDateTime,'YYYY-MM-DD HH:mm:ss').format('x')) / 1000);
					var minutes = Math.floor(seconds/60);
					var second = seconds%60;					
					document.getElementById('timer').innerHTML = ((minutes<10?'0':'')+minutes)+':'+((second<10?'0':'')+second);					
				}
			}else{
				if(document.getElementById('videoincoming1')){
					//document.getElementById('videoincoming1').style.display = "none";
				}				
			}
		}else{
			if(document.getElementById('videoincoming1')){
				//document.getElementById('videoincoming1').style.display = "none";
			}
		}

	  },1000);
	return () => clearInterval(interval);
	
},[]);


const extentSessionRequest = async()=>{
	sessionStorage.extentSession = 'false';
	//setExtentSession(false);
	let appData = JSON.parse(sessionStorage.appointment);
	document.getElementById('btn-extend').style.display = "none";
	appSocket.socket.emit('extentSession',{ptId:appData.ptId, paId:appData.paId, _id:appData._id});
	document.getElementById('extend-msg').innerHTML = "Requested to extend the session. Waiting for the Physical Therapist!";
}

const extentSessionStatus = async(status)=>{
	document.getElementById('Extend-Request').style.display = "none";
	sessionStorage.extentSession = 'false';
	let appData = JSON.parse(sessionStorage.appointment);
	let date = toUTC(moment(appData.date+' '+appData.startTime, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss'));
	let startTime = toUTC(moment(appData.date+' '+appData.startTime, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD HH:mm:ss"));
	let endTime = toUTC(moment(appData.date+' '+appData.endTime, 'YYYY-MM-DD hh:mm A').add(30, 'minutes').format("YYYY-MM-DD HH:mm:ss"));

	let data = {ptId:appData.ptId, paId:appData.paId, _id:appData._id, date:date, startTime:startTime, endTime:endTime,  status:status, duration:60, amount:appData.amount+appData.amount};
	
	appSocket.socket.emit('extentSessionAction',data);
	if(status == 'ACCEPTED'){
		getPtDetails();
	}
}


const fullScreen = (input='')=>{
	//console.log(input);
	if(input == ''){
		var elem = document.getElementById("videoincoming1");
	}else{
		var elem = $("#participants #PA8a9cf6db0eba81a8a6446a1a4e128f29 video")
	}	
	//console.log(elem);
	if (elem.requestFullscreen) {
		elem.requestFullscreen();
	} else if (elem.mozRequestFullScreen) { /* Firefox */
	elem.mozRequestFullScreen();
	} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
	elem.webkitRequestFullscreen();
	} else if (elem.msRequestFullscreen) { /* IE/Edge */
	elem.msRequestFullscreen();
	}
}

//sockets
appSocket.socket.on('session', function(data){
	
	if(data.status === 'FINISHED'){
		window.location = "appointment-history";
		//props.history.push('/future-appointments');
	}else if(data.status === 'start'){
		callTo();
		localStorage.callStartTime = moment().format('YYYY-MM-DD HH:mm:ss');;
	}else if(data.status === 'addUser'){
		//console.log(data);
		isAvailabile = true;
		let receiveId = localStorage.getItem("userType") === 'PATIENT'?props.location.state.ptId:props.location.state.paId;
		appSocket.socket.emit('session',{userId:receiveId, status:'isUser'});
	}else if(data.status === 'disconnect'){
		//console.log(data);
		isAvailabile = false;
		
	}else if(data.status === 'isUser'){
		//console.log(data);
		isAvailabile = true;
	}else if(data.status === 'NO SHOW'){
		window.location = "appointment-history";
	}else if(data.status === 'refresh'){
		window.location.reload();
	}
});

appSocket.socket.on('extentSession', function(data){
	sessionStorage.extentSession = 'true';
	document.getElementById('Extend-Request').style.display = "inline-block";
});

appSocket.socket.on('extentSessionAction', function(data){
	if(data.status == 'ACCEPTED'){
		getPtDetails();
		document.getElementById('extend-msg').innerHTML = "PT accepted a new time for the appointment, let's continue the session.";
		
	}else{
		document.getElementById('extend-msg').innerHTML = 'Sorry! Your request cannot be accepted due to PT unavailability.';
		
	}
});



function dragElement(elmnt) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    /* if present, the header is where you move the DIV from:*/
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

const openModal = (id)=>{
	document.getElementById(id).style.display = 'block';
}
const closeModal = (id)=>{
	document.getElementById(id).style.display = 'none';
}
	return (	    
	<section className="pad-reggis session_page">
		<BreadcrumbsComp bcNewItems={[
		  { path: '/', breadcrumb: "Home" },
		  { path: '/future-appointments', breadcrumb: 'Future Appointments'},
		  { path: '/session', breadcrumb: 'Session'},
		]} />
      <div className="container-fluid">
	  
        <div className="row session_wrap">
            <div className="col-lg-12 session_top">
                <div className="session_topinner row">
                 <div className="col-lg-4">
                   <label>{localStorage.getItem("userType")==='PT'?'Patient Name':'PT Name'}</label>
                   <p id="name"></p>
                </div>
                <div className="col-lg-4">
					<label>Appoinment Date</label>
					<p id="appointmentDate"></p>
                </div>
                <div className="col-lg-4">
                   <label>Session Duration</label>
                   <p id="slot"></p>
                </div>                 
              </div>
            </div>
            <div className="col-lg-8 session_left">
             
              <div className="sessionvideo_sec" id="sessionvideo_sec">
                <div className="sessionvideo">
				<div id="participants" onClick={e=>fullScreen('web')}></div>
				<div id="active-participant"> 
					<div className="participant main">
						<div id="myWebCam"></div>
						<video className='live-video' id="videoincoming1" autoPlay playsInline muted></video>
					</div>
				</div>
                  
				<div className="videocontrols">
					<button id="timer" className="btn btn-primary" style={{display:"none"}}>9:30</button>

					<button id="audioMute" className="btn btn-primary btn-round" style={{display:"none"}}>
						<img id="audio-un-mute" src="static/img/unmute.png" alt="Un-Mute"/>
						<img id="audio-mute" style={{display:"none"}} src="static/img/mute.png" alt="Mute"/>
					</button>
					<button id="videoMute" className="btn btn-primary btn-round" style={{display:"none"}}>
						<img id="video-un-mute" src="static/img/video-player.png" alt="Un-Mute"/>
						<img id="video-mute" style={{display:"none"}} src="static/img/videomute-player.png" alt="Mute"/>
					</button>
					
					{ localStorage.getItem("userType")==='PT' &&
							<button id="hangupBtn" className="btn btn-primary" style={{display:"none"}} onClick={e=>callHangup()}>End Session</button>   
						}               
						<button id="full-screen" className="btn btn-primary btn-round" style={{display:"none"}} onClick={e=>fullScreen()}>
						<img id="video-un-mute" src="static/img/full-screen.png" alt="Full Screen"/>	
					</button>
				</div>
                </div>
                
              </div>          
            </div>
            <div className="col-lg-4 session_right">
				<div className="video_btn_session">	
					<div id="net-msg"></div>
					<div id="wait-msg"></div>
					{localStorage.getItem("userType")==='PT' &&
						<span>						
							<button id="callBtn" className="btn btn-primary" style={{display:"none"}} onClick={e=>callTo()}>Start Session</button>
							
							<span id="Extend-Request" style={{display:"none"}}>
								<button className="btn btn-primary" onClick={e=>extentSessionStatus('ACCEPTED')}>Accept to continue next session</button>
								<button className="btn btn-primary" onClick={e=>extentSessionStatus('REJECT')}>Reject</button>
							</span>
							
						</span>
						}
												
					{localStorage.getItem("userType")==='PATIENT' && 
						<span>												
							<button className="btn btn-primary" id="btn-extend" style={{display:"none"}} onClick={e=>extentSessionRequest()}>Extend Session Request</button>
							{/*<button className="btn btn-primary" id="answerBtn" style={{display:"none"}} onClick={e=>callAnswer()}>Join Session</button>	*/}
							<span id="extend-msg"></span>											
							</span>
					}
				</div>
               <div className="session_rightinner">
                    <div className="Session_btns">
						{localStorage.getItem("userType")==='PT' && 
						<div className="Session_btns">
							<button className="btn btn-primary" onClick={e=>openModal('patientdocumentmodl')}>Patient Documents</button>
						</div>
						}
							<div className="Session_btns">
							<button className="btn btn-primary" onClick={e=>openModal('PreviousTherapistNotes')}>Previous Therapist Notes</button>
						</div>
						{localStorage.getItem("userType")==='PT' && 
							<div className="Session_btns">
							<button className="btn btn-primary" onClick={e=>openModal('submitnotes')}>Submit Notes</button>
						</div>
						}
						{localStorage.getItem("userType")==='PATIENT' && 
							<div className="Session_btns">
								<button className="btn btn-primary" onClick={e=>openModal('PhysicalTherapyNotes')}>Physical Therapy Notes</button>
							</div>
						}
					
					{localStorage.getItem("userType")==='PT' && 
                         <div className="popup" id="patientdocumentmodl">
                            <div className="modal-dialog">
                              <div className="modal-content">
						
                                <div className="modal-header">
                                  <h4 className="modal-title">Patient Documents</h4>
                                  <button type="button" className="close" onClick={e=>closeModal('patientdocumentmodl')} data-dismiss="modal">&times;</button>
                                </div>

                                <div className="modal-body">
									<div className="document_sec">
											<h5> Patient Documents </h5>
												<div className="row" id='patientDocs'>
													{/*append html patientDocs data*/}
												</div>
										</div>
											
										<div className="row medicalhistory">
										<h5> Medical History</h5>
										<PatientMedicalHistory history={props.history} patId={props.location.state.paId}  patFirstName={props.location.state.paFirstName?props.location.state.paFirstName:''} />
										</div>
									</div>
                                </div>
                                
                              </div>
                            </div>
					}
                    
						
						<div className="popup" id="PreviousTherapistNotes">
							<div className="modal-dialog">
								<div className="modal-content">									
									<div className="modal-header">
									<h4 className="modal-title">Previous Therapist Notes</h4>
									<button type="button" className="close" onClick={e=>closeModal('PreviousTherapistNotes')} data-dismiss="modal">&times;</button>
									</div>

									<div className="modal-body">
									<PreviousNotesPage history={props.history} paId={props.location.state.paId} />
									</div>										
								</div>
							</div>
						</div>

						{localStorage.getItem("userType")==='PATIENT' && 
                           <div className="popup" id="PhysicalTherapyNotes">
								<div className="modal-dialog">
									<div className="modal-content">									
										<div className="modal-header">
										<h4 className="modal-title">Physical Therapy Notes</h4>
										<button type="button" className="close" onClick={e=>closeModal('PhysicalTherapyNotes')} data-dismiss="modal">&times;</button>
										</div>

										<div className="modal-body">
										{props.location.state.sopNoteSaveType === 'SUBMIT' ?
											  <NotesTemplatePage history={props.history} appId={props.location.state._id} />
										  :
										  ' No Therapy Notes available!'
										}
										</div>										
									</div>
								</div>
							</div>
						}

                           <div className="popup" id="submitnotes">
                            <div className="modal-dialog">
                              <div className="modal-content">
                              
                                <div className="modal-header">
                                  	<h4 className="modal-title">Submit Notes</h4>
                                  	<button type="button" className="close" onClick={e=>closeModal('submitnotes')} data-dismiss="modal">&times;</button>
                                </div>

                                <div className="modal-body">
                                  	<div className="session_note_wrap">
									  <NotesTemplatePage history={props.history} appId={props.location.state._id} fType={false} />
                                	</div>
                                </div>

                              </div>
                            </div>
                          </div>
                   </div>
              </div>
            </div>
        </div>
      </div>
    </section>
	);
}
export default withLocalize(withRouter(Session));
