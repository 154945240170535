import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import PlacesAutocomplete, { geocodeByAddress, getLatLng} from 'react-places-autocomplete';

var componentForm = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name',
  lat:"lat",
  lng:"lng"
};

const GeoAddress = (props) => {
  const [address, setAddress] = useState('');
  var plaseHolder = 'Search & Select Address Here...';
  if(props.formType==='clinic'){
    plaseHolder = 'Search & Select Clinic Address Here...';
  }

  useEffect(() => {
    if(props.valueReset === false){
      setAddress('');
    }
  }, [props])

  const handleChange=(address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    let formDataState= [];
    geocodeByAddress(address)
      .then(results => {
        for (var i = 0; i < results[0].address_components.length; i++) {
          var addressType = results[0].address_components[i].types[0];
          if (componentForm[addressType]) {
            var val = results[0].address_components[i][componentForm[addressType]];
            if(addressType==='street_number'){
              setAddress(val);
            }
            formDataState[props.geoAddress[addressType]] = val;
          }
        }
        setAddress(address); //Over all address
        getLatLng(results[0])
        .then(latLng => {
          formDataState[props.geoAddress['lat']] = latLng.lat;
          formDataState[props.geoAddress['lng']] = latLng.lng;
          props.parentCallback(formDataState);
        })
      })
      .catch(error => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={e=>handleChange(e)}
      onSelect={handleSelect} >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading, index }) => (
        <div className='autocomplete-dropdown-container-main'>
          <div className="row">
              <div className="col-sm-12">
                   <input id={props.geoAddress["street_number"]}
                    {...getInputProps({
                      placeholder:plaseHolder,
                      className: 'form-control',
                    })}
                  />
              </div>
          </div>
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div key={index}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}>
                  <div>{suggestion.description}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
export default withLocalize(withRouter(GeoAddress));
