import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { Dialog } from "@blueprintjs/core";
import {getCookie, setCookie} from "../../../_Constants/app.constants";

const Authentication = (props) => {
    
  const [isAuth,setIsAuth] = useState(getCookie("login")=='true'?false:true);
  const [user,setUsername] = useState('');
  const [pass,setPassword] = useState('');
  const [error,setError] = useState('');

  
  const login = async()=>{
        if(user == `${process.env.REACT_APP_USER_NAME}` && pass == 'recover@healthcare$'){
          setCookie('login','true',1);
            setIsAuth(false);
            setError("");
        }else{
            setError('Invalid username & password');
        }
  }

  const inputHandler = (e)=>{
    
      if(e.target.name == 'username'){
        setUsername(e.target.value);
      }else{
        setPassword(e.target.value);
      }  
  }

  useEffect(()=>{

  },[]);


  return (
    <Dialog className="form_designpage authentication-box" title="" canOutsideClickClose={false} isOpen={isAuth}>
           <div className="text-center box">     
                <h4>Authentication</h4>            
                <div className="form-group">                    
                    <input type="text" name="username" id="username" onChange={e=>inputHandler(e)} autoComplete="off" className="form-control" placeholder="User Name" value={user} />                    
               </div>
               <div className="form-group">                 
                    <input type="password" name="password" onChange={e=>inputHandler(e)} autoComplete="off" className="form-control" placeholder="Password" value={pass} />                    
               </div>
               <div className="form-group">
                    <button className="nbtn" onClick={e=>login()}>Login</button>
               </div>
               <div className="form-group">
                   <p className="error">{error}</p>
               </div>
           </div>           
      </Dialog>
  );
}
export default withLocalize(withRouter(Authentication));