import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import {getFeedbackListAction} from '../../Actions/User.action';
import { Dialog } from "@blueprintjs/core";
import MetaTags from 'react-meta-tags';
import LoginPageComponent from './Login/LoginPage.js';
import SignUp from '../SignUp/SignUp';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const testimonialState= {
    responsive:{
        0: {
            items: 1,
        },
        769: {
            items: 2,
        },
    },
}

const HomePage = ({history}) => {
  const dispatch = useDispatch();
  const [isSignupForm,setIsSignupForm] = useState(false);
  const [feedbackList,setFeedbackList] = useState({});

  const getFeedbackListFunction = async()=>{
    let feedbackData = await getFeedbackListAction();   
    setFeedbackList(feedbackData);
  }

  useEffect(()=>{
    getFeedbackListFunction();
  },[]);

  return (
    <div className="slide-er">
      <MetaTags>
        <title>Recover</title>
        <meta name="description" content="Recover"/>
        <meta name="keywords" content="Recover"/>
      </MetaTags>
      
      <section className="masthead" id="home">
        <div className="container d-flex h-100 align-items-center">
          <div className="mx-auto text-center">
            <h1 className="mx-auto my-0">
             You’re only a couple of clicks away from receiving state of the art physical therapy
            </h1>
            <h2 className="text-white-80 mx-auto mt-2 mb-5">
             Recover is a health care platform focused on connecting patients with world-class physical therapists. Recover ensures patients no longer have to miss an opportunity of physical therapy treatment due to conflicts in their schedule. The platform also ensures physical therapists’ fewer gaps in their schedule, therefor reducing loss of revenue.
            </h2>
            {(!localStorage.getItem("RecoverWebStatus")) &&
              <Link to='#' className="btn btn-primary regis" onClick={e=>setIsSignupForm(true)}>Register Now</Link>
            }
          </div>
        </div>
      </section>
   
      <section className="about-section text-center" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-left">
              <div className="section_title">
                <h3>Welcome To Recover</h3>
                <div className="decor2"></div>
              </div>
              <p>
                Recover is a telehealth platform that provides patients quick and easy access to health care specialists whenever or wherever they need. Providers may also use Recover to fill cancellations that may arise within their own practice. Currently, Recover is focused on physical therapy. However, in the future, Recover will expand to include occupational, speech, nutrition, and psychological therapy options.
              </p>
              <p>
                Our goal is to connect patients and providers with more ease as well as provide patients with a platform in which they can receive entire treatment plans, while simultaneously reducing the amount of missed appointments due to inconvenience.
              </p>
              <Link className="btn btn-primary js-scroll-trigger" to="/about-us">About Us</Link>
            </div>
            <div className="col-lg-6 mx-auto">
              {(!localStorage.getItem("RecoverWebStatus"))?
                (<LoginPageComponent history={history}/>):
                (<img src="static/img/about.jpg" alt="about"/>)
              }
            </div>
          </div>
        </div>
      </section>

      <section className="how-section bg-light" id="how">
        <div className="container">
          <div className="row">
            <div className="col section_title text-center">
              <h3>How recover works</h3>
              <div className="decor"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="hs text-center pb-5">
                <img src="static/img/patient.svg" alt="" />
                <h5>Patients</h5>
                <p>
                 Recover allows you to receive physical therapy virtually in the location your choice. 
                 The calendar tool allows .....
                </p>
              </div>
              <div className="hs text-center pt-5">
                <img src="static/img/Patient_PT.svg" alt="" />
                <h5>Independent Physical Therapists</h5>
                <p>
                  Recover is hiring licensed qualified physical therapists to treat patients through our telehealth platform. 
                  If you are an independent .....
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mHero text-center mt-5">
                <img src="static/img/phone.png" className="w-100 img-fluid" alt="" />
              </div>
            </div> 
            <div className="col-md-4 mb-4">
              <div className="hs text-center pb-5">
                <img src="static/img/clinic.svg" alt="" />
                <h5>Clinics</h5>
                <p>
                  Recover offers a solution for clinics when they receive last minute cancellations from patients. 
                  Recover allows clinics ....
                </p>
              </div>
              <div className="hs text-center pt-5">
                <img src="static/img/clinic PT.svg" alt="" />
                <h5>Clinic Physical Therapists</h5>
                <p>
                  Recover will help fill your cancellations during work hours and allows you to treat patients via telehealth. 
                  Your clinic administrator ...
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
               <div className="btn_section text-center">
                <Link className="btn btn-primary js-scroll-trigger" to="/how-recover-works">View more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-area">
        <div className="container">
          <div className="row">
            <div className="col section_title text-center">
              <h3>CUSTOMER FEEDBACK</h3>
              <div className="decor"></div>
            </div>
          </div>
          <div className="row">
            {feedbackList && feedbackList.data && feedbackList.data.length > 0 && (
              <OwlCarousel className="owl-theme" 
                loop={true} margin={10} 
                dots={false} autoplay={true}
                autoplayTimeout={6000} items={feedbackList.data.length===1?1:2} //nav={true}
                responsive={feedbackList.data.length===1?testimonialState.responsive[0]:testimonialState.responsive}>

                {feedbackList.data.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <div className="single-testimonial-box style2">
                        <div className="inner-box">
                          <div className="image-holder">
                            <img
                              src={`${process.env.REACT_APP_STATIC_S3_URL+item.feedbackDoc}`}
                              alt="example"
                              onError={(e) => {
                                e.target.src = "static/img/icon-profiles.png"
                                e.target.style = ''
                              }}
                            />
                          </div>
                          <div className="title-holder">
                            <h4>{item.name?item.name:'-'}</h4>
                            <p className="ption">{item.title?item.title:'-'}</p>
                            <p className="ption">{item.subject?item.subject:'-'}</p>
                          </div>
                        </div>
                        <div className="text-holder">
                          <div className="icon-holder">
                            <span className="icon-interface-1">
                              <i className="fas fa-quote-left"></i></span>
                          </div>
                          <div className="text">
                            <p>
                              {item.description && item.description.length > 160 &&
                                <span>
                                  {item.description.substr(0, 160).replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ')+"... "}
                                  <Link to={{pathname:"feedback-detail", state:{id:item} }} className="js-scroll-trigger">Read More</Link> 
                                </span>
                              }
                              {item.description && item.description.length <= 160 ?
                                item.description.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' '):''
                              }
                            </p>
                          </div>
                        </div>
                      </div>    
                    </div> 
                  );
                })}
              </OwlCarousel>
            )}
          </div>
        </div>
       </section>

      <Dialog
        className="form_designpage" onClose={e=>setIsSignupForm(false)}
        title="" canOutsideClickClose={false} isOpen={isSignupForm}>
          <SignUp setIsSignupForm={setIsSignupForm} />
      </Dialog>
   </div>
  );
}
export default withLocalize(withRouter(HomePage));
