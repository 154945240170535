import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import $ from 'jquery';
import LocationSearchInput from '../_Common/GeoAddress';
import {FormError,ShowFormErrors} from "../_Common/formError";
import { useForm } from "react-hook-form";
import {timezones} from '../_Constants/timezones';

var componentFormData = {
  street_number: 'address1',
  route: 'address2',
  locality: 'city',
  administrative_area_level_1: 'state',
  country: 'country',
  postal_code: 'zipCode',
  lat: 'latitude',
  lng: 'longitude',
  stateDataField: "leadFormData"
};

const GeoAddressManage = ({history, formData, signupErrors, register, errors, inputHandler, valueReset,tzStatus}) => {
  const [signupForm, setSignupForm] = useState(formData); 
  
  var formType = '';
  if(formData.formType==='clinic'){
    formType = 'clinic';
  }else if(signupForm.clinicId && signupForm.clinicId!==''){
    formType = 'clinic';
  }

  setTimeout(function(){ 
    setSignupForm(formData)
  }, 1000);

  const addressChangedHandler=(data)=> {
    let signupFormData = formData;
    var Address = data.address1?data.address1+' ':'';
    Address = Address+data.address2
    if(Address && Address!=='' && Address!=='undefined'){
      signupFormData.address = Address;
      $('input[name="address"]').val(Address);
      $('input[name="address"]').removeClass("requiredClass");
      $('input[name="address"]').prop('disabled', true);
    }else{ 
      signupFormData.address = '';
      $('input[name="address"]').val('');
      $('input[name="address"]').prop('disabled', false);
    }    
    if(data.city && data.city!=='undefined'){
      signupFormData.city = data.city;
      $('input[name="city"]').val(data.city);
      $('input[name="city"]').removeClass("requiredClass");
      $('input[name="city"]').prop('disabled', true);
    }else{ 
      signupFormData.city = '';
      $('input[name="city"]').val('');
      $('input[name="city"]').prop('disabled', false);
    }    
    if(data.state && data.state!=='undefined'){
      signupFormData.state = data.state;
      $('input[name="state"]').val(data.state);
      $('input[name="state"]').removeClass("requiredClass");
      $('input[name="state"]').prop('disabled', true);
    }else{ 
      signupFormData.state = '';
      $('input[name="state"]').val('');
      $('input[name="state"]').prop('disabled', false);
    }    
    if(data.zipCode && data.zipCode!=='undefined'){
      signupFormData.zipCode = data.zipCode;
      $('input[name="zipCode"]').val(data.zipCode);
      $('input[name="zipCode"]').removeClass("requiredClass");
      $('input[name="zipCode"]').prop('disabled', true);
    }else{ 
      signupFormData.zipCode = '';
      $('input[name="zipCode"]').val('');
      $('input[name="zipCode"]').prop('disabled', false);
    }
    signupFormData.latitude = data.latitude?data.latitude:'';
    signupFormData.longitude = data.longitude?data.longitude:'';
    setSignupForm({...formData,...signupFormData});
  }

  const inputHandlerAddress = (e,field)=>{
    let signupFormData = formData;
    if(field == 'time_zone'){
      let timezoneValue = timezones.filter(
        function(timezones){return timezones.text == e.target.value}
      );      
      signupFormData['timeZone'] = JSON.stringify(timezoneValue[0]);
    }    
    signupFormData[field] = e.target.value;
    setSignupForm({...formData,...signupFormData});
  }

  return (
    <div className="col-lg-12">
      <div className="row">
        <div className="col-lg-12">
          {formType==='clinic'?
            <label>Search & Select Clinic Address</label>
          :<label>Search & Select Address</label>
          }
          <div className="form-group">
            <LocationSearchInput className="form-control sp-list" 
              parentCallback={e=>addressChangedHandler(e)} 
              geoAddress={componentFormData} state={signupForm} 
              formDataState="signupForm" valueReset={valueReset} formType={formType}/> 
          </div>
        </div>
        <div className="col-lg-8">
          <label>Address*</label>
          <div className="form-group">
            <input onChange={e=>inputHandlerAddress(e,'address')} name="address" type="text" 
              className="form-control" placeholder={formType==='clinic'?'Clinic Address':'Home Address'}
              defaultValue={signupForm && signupForm.address?signupForm.address:''} 
              ref={register({ required: 'Required' })} />
              {!signupForm.address &&
                (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"address")}</div>)
              }
          </div>
        </div>               
        <div className="col-lg-4">
          <label>Apt/Unit</label>
          <div className="form-group">
            <input onChange={e=>inputHandlerAddress(e,'aptStreet')} name="aptStreet" type="text" 
              className="form-control" placeholder="Apt/Unit"
              defaultValue={signupForm && signupForm.aptStreet?signupForm.aptStreet:''} 
              />
          </div>
        </div>
        <div className="col-lg-4">
          <label>City*</label>
          <div className="form-group">
            <input onChange={e=>inputHandlerAddress(e,'city')} name="city" type="text"
            className="form-control" placeholder="City"
            defaultValue={signupForm && signupForm.city?signupForm.city:''}
            ref={register({ required: 'Required' })}/>
            {!signupForm.city &&
              (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"city")}</div>)
            }
          </div>
        </div>
        <div className="col-lg-4">
          <label>State*</label>
          <div className="form-group">
            <input onChange={e=>inputHandlerAddress(e,'state')} name="state" type="text" 
            className="form-control" placeholder="State"
            defaultValue={signupForm && signupForm.state?signupForm.state:''}
            ref={register({ required: 'Required' })}/>
            {!signupForm.state &&
              (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"state")}</div>)
            }
          </div>
        </div>
        <div className="col-lg-4">
          <label>Zip Code*</label>
           <div className="form-group">
           <input onChange={e=>inputHandlerAddress(e,'zipCode')} name="zipCode" type="text"
            className="form-control" placeholder="Zip Code"
            defaultValue={signupForm && signupForm.zipCode?signupForm.zipCode:''}
            ref={register({ required: 'Required' })} />
            {!signupForm.zipCode &&
              (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"zipCode")}</div>)
            }
          </div>
        </div>
        {tzStatus!='NO' && 
          <div className="col-lg-12">
            <label>Time Zone*</label>
            <div className="form-group">
              <select className="form-control" name="time_zone" onChange={e => inputHandlerAddress(e,'time_zone')} value={signupForm.time_zone?signupForm.time_zone:''}  ref={register({ required: 'Required' })}>                        
                  <option value="">Select Time Zone</option>
                  {timezones.map(function(item,index) {
                    return <option key={index} value={item.text}>{item.text}</option>
                  })}
               </select>
              {!signupForm.time_zone &&
                (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"time_zone")}</div>)
              }
            </div>
          </div>
        }
      </div>    
    </div>    
  );
}
export default withLocalize(withRouter(GeoAddressManage));
