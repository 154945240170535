import React,{useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { withLocalize } from 'react-localize-redux';
import toastr from "../../../_Common/toaster";
import {checkCardImg} from '../../../_Constants/app.constants';
import {getPayCartListAction,addPaymentCardAction,saveBookingAction} from '../../Actions/User.action';
import {useStripe, useElements, 
  CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

/*const createOptions = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};*/

const SplitFieldsForm = ({history, bookingArr}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [formData,setFormData] = useState(
    {'cardHolderName': localStorage.getItem("fullName"),'cardSave':false}
  );
  const { handleSubmit } = useForm();
  const [cardNumberError,setCardNumberError] = useState(false);
  const [cardExpiryError,setCardExpiryError] = useState(false);
  const [cardCvcError,setCardCvcError] = useState(false);
  const [cardBrand,setCardBrand] = useState(false);
  const [errorMessage,setErrorMessage] = useState();
  const [payCartList,setPayCartList] = useState(false);
  const [payButtonStatus,setPayButtonStatus] = useState(false);
  const [spinner,setSpinner] = useState(false);

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
    getPayCartListFunction();
  },[]);

  const getPayCartListFunction = async()=>{
    let cartList = await getPayCartListAction();
    if(cartList.success===true){
      setPayCartList(cartList.data);
    }
  }

  const saveBookingFunction = async(e, data, cardType)=>{
    if(bookingArr && bookingArr!=='' && data!==''){
      bookingArr['userPaymentId'] = data;
      setSpinner(true);
      let reponse = await saveBookingAction(bookingArr);
      if(reponse.success){
        reponse.data['cardType'] = cardType?cardType:'';
        history.push('/invoice', { bookingDetail: reponse.data })
        toastr.show({message: "Appointment booked successfully",intent: "success"}); 
      }else{
        history.push('/pt-list');
        if(reponse.err){
          toastr.show({message: reponse.err._message,intent: "danger"}); 
        }else{
          toastr.show({message: reponse.msg,intent: "danger"}); 
        }
      }
    }
  }  

  const paymentMethodFunction = async(result)=>{
    let request = {paymentMethodNonce:result, 
      'is_default': JSON.stringify(formData.cardSave),
      'is_future': JSON.stringify(formData.cardSave),
      'name': formData.cardHolderName
    }
    if(request){
      let res = await addPaymentCardAction(request);
      if(res.success){
        saveBookingFunction(res, res.data._id, cardBrand);
      }else if(res.success===false){
        setSpinner(false);
        toastr.show({message: res.msg,intent: "danger"});
      }
    }
  }

  const onFormSubmit = async (event) => {
    setSpinner(true);
    //const cardElement = elements.getElement(CardElement);
    const cardElement = elements.getElement(CardNumberElement,CardExpiryElement,CardCvcElement);
    stripe.createToken(cardElement).then(function(result) {
      if(result.error){
        setSpinner(false);
        setCardNumberError(formData.cardNumber!==true?formData.cardNumber:'');
        setCardExpiryError(formData.cardExpiry!==true?formData.cardExpiry:'');
        setCardCvcError(formData.cardCvc!==true?formData.cardCvc:'');
      }else{
        if(result && result.token && result.token.id){  
          paymentMethodFunction(result.token.id);
        }else{
          setSpinner(false);
          setErrorMessage('Something wrong');
        }
      }
    });
  };

  const handleChange = (e) => {
    let formValue = formData;
    if(e.elementType === 'cardNumber'){
      setCardBrand(e.brand && e.brand!=='unknown'?e.brand:false)
    }
    let field = e && e.elementType;
    let msg = e && e.error && e.error.message?e.error.message:false;
    if(field && msg && e.complete!==true){
      formValue[field] = msg;
    }else{
      formValue[field] = true;
    }
    setCardNumberError(field==='cardNumber'?msg:'');
    setCardExpiryError(field==='cardExpiry'?msg:'');
    setCardCvcError(field==='cardCvc'?msg:'');

    if(e.target && e.target.name === 'cardSave'){
      formValue[e.target.name] = e.target.checked===true?true:false;
    }else if(e.target && e.target.name === 'cardHolderName'){
      formValue[e.target.name] = e.target.value;
    }
    setFormData({...formData,...formValue});
    setErrorMessage('');
  };

  return (
    <div className="row">
    <div className="col-lg-12 col-sm-12">
      <div className="white_box">
        <div className="col-lg-12 col-sm-12">
          <div className="section_title page_ttl text-center">
            <h3>Checkout</h3>
            <div className="decor2"></div>
          </div>
        </div>
        <div className="col-sm-12 text-left">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-sm-12">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <form onSubmit={handleSubmit(onFormSubmit)}>
                    {/*<CardElement onChange={handleChange} options={createOptions}/>*/}
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <label>Card Number*</label>
                        <div className="form-group">
                          <div className="card_no_div"> 
                            <CardNumberElement className="form-control" id='cardNumber' onChange={(e) => handleChange(e)}/>
                            <span><img src={checkCardImg(cardBrand)} alt={cardBrand}/></span>
                          </div>
                          <div className="text-danger">{cardNumberError?cardNumberError:''}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label>Expiration Date*</label>
                        <div className="form-group">
                          <CardExpiryElement className="form-control" onChange={(e) => handleChange(e)}/>
                          <div className="text-danger">{cardExpiryError?cardExpiryError:''}</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label>CVC*</label>
                        <div className="form-group">
                          <CardCvcElement className="form-control" onChange={(e) => handleChange(e)}/>
                          <div className="text-danger">{cardCvcError?cardCvcError:''}</div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <label htmlFor="name"> Card Holder Name </label>
                        <div className="form-group">
                          <div className="input-group">
                            <input type="text" className="form-control" name="cardHolderName" 
                              placeholder="Card Holder Name" size="50"
                              onChange={(e) => handleChange(e)}
                              value={formData.cardHolderName}
                            />
                          </div>
                        </div>
                      </div> 
                      <div className="col-sm-12">
                        <div className="form-group future_tran_check">
                          <input type="checkbox" name="cardSave" id="cardSave" 
                            onChange={(e) => handleChange(e)}
                            value={formData.cardSave} 
                          />
                          <label htmlFor="cardSave" className="m-top"> Save this card for future transaction </label>
                        </div>
                      </div>                 
                      <div className="col-sm-12">
                        <div className="form-group">   
                          <button type="submit" className="btn btn-primary" disabled={!stripe}> Pay </button>
                          <a href="/pt-list" className="btn btn-primary"> Cancel Transaction</a>
                        </div>  
                      </div>
                      <div className="col-lg-12 error" role="alert">
                       {errorMessage}
                      </div>
                    </div>                        
                  </form>
                </div>


                <div className="col-lg-6 col-sm-12">
                  <div className="daved_card_sec">
                    <h2>Saved Cards:</h2>
                    {payCartList.length > 0 ? (payCartList.map((val, ind)=>{ 
                      let brandName = (val && val.responseData && val.responseData[0].brand ? 
                        val.responseData[0].brand.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()):false);
                      return (<div className="row credit_card_box_row" key={ind}>
                          <div className="col-lg-8">
                            <label htmlFor="PrimaryCreditCard" className="form-check-label">
                            <input type="radio" className="clas-radio" name="PrimaryCreditCard"
                              defaultChecked={val.is_default==='true'?true:false}
                              onChange={e=>setPayButtonStatus(val._id)}
                            />{val.braintreeMaskedNumber?'XXXX-XXXX-XXXX-'+val.braintreeMaskedNumber:''}</label>
                          </div>     

                          {brandName &&
                            <div className="fileDocs">
                              <img src={checkCardImg(brandName)} alt={brandName}/>
                            </div>
                          }

                          <div className="cvv-hide">
                            {/*<label> CVV </label>
                            <input type="password" name="cvv_no" id="cvvtxt_1"/> */}
                            {!payButtonStatus && val.is_default==='true' &&
                              <button type="button" onClick={e=>saveBookingFunction(e, val._id, brandName)} className="btn btn-success" id="btn_1"> Pay Now </button>
                            }
                            {payButtonStatus===val._id &&
                              <button type="button" onClick={e=>saveBookingFunction(e, val._id, brandName)} className="btn btn-success" id="btn_1"> Pay Now </button>
                            }
                          </div>
                        </div>)
                      })
                    ) : (
                      <div className="row credit_card_box_row" >
                        <div className="col-lg-12">
                          <label>No card details saved</label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="spinner" style={{display:spinner?'block':'none'}}>
      <img src="static/img/spinner.gif" alt='loader'/>
    </div>
    </div>
  );
};
export default withLocalize(SplitFieldsForm);
