import React,{useEffect} from 'react';
import { withLocalize } from 'react-localize-redux';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import {siteDetail} from '../../../_Constants/app.constants';

const InformedConsentPage = ({history}) => {

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
  },[]);

  return (
    <div>
    <MetaTags>
      <title> Informed Consent </title>
      <meta name="description" content="To access e-INSCRIPTION’s services through a website or mobile app, you need an account on our platform. To create an account you have to provide accurate info" />
      <meta name="keywords" content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore " />
    </MetaTags>
    <section className="informed_page pad-reggis inner_pagewrap">
      <BreadcrumbsComp bcChanges={[{path: '/informed-consent', breadcrumb: 'Informed Consent'}]} />
      
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-left">
            <div className="informed_wrap white_box">
              <div className="section_title page_ttl text-center">
                 <h3>INFORMED CONSENT AND PATIENT RIGHTS</h3>
                 <div className="decor2"></div>
              </div>
              <p>The following statement of your rights and responsibilities as a patient receiving treatment through Recover is given to you with the hope that your awareness and understanding of these rights and responsibilities and your informed participation will contribute to better care.</p>
              <p><b>As a patient receiving treatment through Recover, you have the right to:</b></p>
              <ul>
                <li>Receive clinically appropriate care and treatment that is suited to your needs.  Care and treatment that is safely and humanely provided and with full respect for your dignity and personal integrity</li>
                <li>Object to a plan of treatment or any portion of it and retain the right to receive treatment, unless your objection makes continued participation in the program clinically inappropriate or medically non-beneficial or would endanger your safety or the safety of others</li>
                <li>Receive services without discrimination and with respect to your cultural environment</li>
                <li>A reasonable degree of privacy consistent with effective delivery of services</li>
                <li>Initiate any question, complaint in accord with the procedure described below</li>
              </ul>
              <p><b>As a patient receiving treatment through Recover, you have the responsibility to:</b></p>
              <ul>
                <li>Follow the treatment plan that you and your physical therapist agreed upon</li>
                <li>To inform Recover at least 24 hours in advance or as soon as possible if you cannot meet your scheduled appointment time</li>
                <li>To be on time for your appointment with your physical therapist</li>
                <li>Agree to act in a professional way at all times with your physical therapist </li>
              </ul>
              <p>If you feel you are not being treated fairly or that your rights are not being respected, we urge you to discuss your concern with your Recover physical therapist and Recover admin.   Please contact Recover by email, phone, or mail.</p> 
              <p>Recover<br/> 
                {siteDetail.address}<br/>
                <a href={'tel:+1'+siteDetail.phone}>{siteDetail.phone}</a><br/>
                <a href={'mailto:'+siteDetail.email}>{siteDetail.email}</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}
export default withLocalize(InformedConsentPage);
