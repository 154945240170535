import React,{useState, useEffect} from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import {FormError,ShowFormErrors} from "../../../../_Common/formError";
import toastr from "../../../../_Common/toaster";
import $ from 'jquery';
import SideMenu from '../SideMenu';
import {getusDate} from '../../../../_Constants/app.constants';
import {getMedicalHistoryAction, addMedicalHistoryAction} from '../../../Actions/User.action';

const medicalFieldArr = [
  {id: '1', title: 'Asthma, Bronchitis, or Emphysema', value: ''},
  {id: '2', title: 'Severe or Frequent Headache', value: ''},
  {id: '3', title: 'Coronary Heart Disease of Angina', value: ''},
  {id: '4', title: 'Vision or Hearing Difficulties', value: ''},
  {id: '5', title: 'Shortness of Breath/Chest Pain', value: ''},
  {id: '6', title: 'Numbness or Tingling', value: ''},
  {id: '7', title: 'High Blood Pressure', value: ''},
  {id: '8', title: 'Dizziness of Fainting', value: ''},
  {id: '9', title: 'Heart Attack or Heart Surgery', value: ''},
  {id: '10', title: 'Bowel or Bladder Problems', value: ''},
  {id: '11', title: 'Stroke/TIA', value: ''},
  {id: '12', title: 'Weakness', value: ''},
  {id: '13', title: 'Congestive Heart Failure', value: ''},
  {id: '14', title: 'Hernia', value: ''},
  {id: '15', title: 'Blood Clot/Emboli', value: ''},
  {id: '16', title: 'Weight Loss/Energy Loss', value: ''},
  {id: '17', title: 'Epilepsy/Seizures', value: ''},
  {id: '18', title: 'Varicose Veins', value: ''},
  {id: '19', title: 'Thyroid Disease', value: ''},
  {id: '20', title: 'Allergies', value: ''},
  {id: '21', title: 'Anemia', value: ''},
  {id: '22', title: 'Pins or Metal Implants', value: ''},
  {id: '23', title: 'Infectious Disease', value: ''},
  {id: '24', title: 'Joint Replacement Surgery', value: ''},
  {id: '25', title: 'Diabetes', value: ''},
  {id: '26', title: 'Neck Injury/Surgery', value: ''},
  {id: '27', title: 'Cancer or Chemo/Radiation', value: ''},
  {id: '28', title: 'Shoulder Injury/Surgery', value: ''},
  {id: '29', title: 'Arthritis', value: ''},
  {id: '30', title: 'Elbow/Hand Injury/Surgery', value: ''},
  {id: '31', title: 'Osteoporosis', value: ''},
  {id: '32', title: 'Knee Injury/Surgery', value: ''},
  {id: '33', title: 'Sleeping Problems', value: ''},
  {id: '34', title: 'Back Injury/Surgery', value: ''},
  {id: '35', title: 'Gout', value: ''},
  {id: '36', title: 'Ankle/Foot Injury/Surgery', value: ''},
  {id: '37', title: 'Emotional/psychological Problems', value: ''},
  {id: '38', title: 'Are you pregnant?', value: ''},
  {id: '39', title: 'Do you have a pacemaker?', value: ''},
  {id: '40', title: 'Do you use tobacco?', value: ''},
]

const PatientMedicalHistory = ({history, signupForm, patId, patFirstName=''}) => {
  var patName = signupForm && signupForm.firstName?signupForm.firstName:patFirstName;
  const [formData,setFormData] = useState({medicalHistory:medicalFieldArr});
  const [formDataErrors,setFormDataErrors] = useState({});
  const { register, handleSubmit, setValue, errors, setError } = useForm();
  const [EditStatus, setEditStatus] = useState(false);
  const [spinner,setSpinner] = useState(false);
  const [medicalOption,setMedicalOption] = useState(medicalFieldArr);
  const [currentDate, setCurrentDate] = useState(new Date());

  const onEditClickNew = (e, status)=>{
    e.preventDefault();
    setEditStatus(!status)
    $('.medicalHistory_wrapinner input, select, textarea').prop('disabled', status);
  }

  useEffect(()=>{
    getMedicalHistoryFunction();
    $('.medicalHistory_wrapinner input, select, textarea').prop('disabled', true);
  },[]);

  const getMedicalHistoryFunction = async()=>{
    let res = await getMedicalHistoryAction({userId:patId?patId:signupForm._id});
    if(res.success && res.data && res.data.medicationDosage){
      let saveFormData = formData;
      saveFormData['currentDate'] = res.data.currentDate?res.data.currentDate:'';
      saveFormData['otherComment'] = res.data.otherComment?res.data.otherComment:'';
      saveFormData['medicationDosage'] = res.data.medicationDosage?res.data.medicationDosage:'';
      saveFormData['patientSignature'] = res.data.patientSignature?res.data.patientSignature:'';
      saveFormData['medicalHistory'] = res.data.medicalHistory.length > 0?res.data.medicalHistory:medicalFieldArr;
      setFormData({...formData,...saveFormData});
    }
  }

  const onSaveAction = async()=>{
    setSpinner(true);
    formData['currentDate'] = currentDate;
    formData['patientSignature'] = patName;
    if(formData.medicationDosage && formData.medicationDosage!==undefined && formData.medicationDosage!==''){
      let res = await addMedicalHistoryAction(formData);
      if(res.success){
        getMedicalHistoryFunction();
        setEditStatus(false);
        setSpinner(false);   
        toastr.show({message: res.msg,intent: "success"});
        $('.medicalHistory_wrapinner input, select, textarea').prop('disabled', true);
      }else if(res.success===false){
        setSpinner(false);
        toastr.show({message: res.msg,intent: "danger"});
      }
    }else{
      let error = "Required";
      document.getElementById("errorid").innerHTML = error;      
      setSpinner(false);   
      return false;
    }
  }

  const radioHandler = (e,index,field)=>{
    let saveFormData = formData.medicalHistory;
    saveFormData[index]['value'] = e.target.value;
    saveFormData['medicalHistory'] = saveFormData;
    setFormData({...formData,...saveFormData});
  }

  const inputHandler = (e,field)=>{
    let saveFormData = formData;
    saveFormData[e.target.name] = e.target.value;
    setFormData({...formData,...saveFormData});
  }

//console.log('formData.medicalHistory', formData.medicalHistory);  

  return (
    <div className="card-body medicalHistory_wrapinner">
      <div className="checkwrap_sec">
        <div className="row">

          {formData && formData.medicalHistory && formData.medicalHistory.map((val, index)=>
            <div className="col-lg-6 col-md-12" key={index}>
              <div className="checkrow">
                <span>{val && val.title ? val.title :''}</span>
                <div className="checkrowcol">
                  <label><input type="radio" onChange={e=>radioHandler(e, index, val.id)} name={'demo_'+val.id} checked={val.value === 'yes'?'checked':''} value="yes"/><span>Yes</span></label>
                  
                  <label><input type="radio" onChange={e=>radioHandler(e, index, val.id)} name={'demo_'+val.id} checked={val.value === 'no'?'checked':''} value="no"/><span>No</span></label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row medica_btm_sec">
        <div className="col-md-12">
          <div className="form-group">
            <label>Medication with Dosage and Frequency</label>
            <textarea rows="4" className="form-control" onChange={e=>inputHandler(e)} name="medicationDosage" defaultValue={formData.medicationDosage?formData.medicationDosage:''}></textarea>
            {(!formData.medicationDosage || formData.medicationDosage==='') &&
              <div className="text-danger" id="errorid" ></div>
            }
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Any Other Comment</label>
            <textarea rows="4" className="form-control" onChange={e=>inputHandler(e)} name="otherComment" defaultValue={formData.otherComment?formData.otherComment:''}></textarea>
          </div>
        </div>
      </div>
      {(EditStatus || patId)&& 
        <div className='row'>
          <div className="col-md-6 Patient_sign_wrap">
            <div className="form-group">
              <label>Patient/Guardian Signature </label>
              <div className="Patient_sign">{formData.patientSignature?formData.patientSignature:patName}</div>
            </div>
          </div>
          <div className="col-md-6 Patient_sign_wrap">
            <div className="form-group">
              <label>Date</label>
              <div className="sub_date">
                {getusDate(formData.currentDate?formData.currentDate:currentDate)}
              </div>
            </div>
          </div>
        </div>
      }
      {!patId &&
        <div className="row">
          <div className="col-lg-12 t-pp">
            <Link to="#" className="btn btn-primary rt" onClick={e=>onEditClickNew(e, EditStatus)}>{EditStatus?'Cancel':'Edit'}</Link>
            <input type="submit" className="btn btn-primary" onClick={e=>onSaveAction(e)} value="Save" />
          </div>
        </div>
      }
      <div className="spinner" style={{display:spinner?'block':'none'}}>
        <img src="static/img/spinner.gif" alt='loader'/>
      </div>
    </div>
  );
}
export default withRouter(PatientMedicalHistory);