import React,{useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { withLocalize } from 'react-localize-redux';
import {Row, FormGroup, Label, Input, Button} from 'reactstrap';
import {FormError,ShowFormErrors} from "../../../_Common/formError";
import toastr from "../../../_Common/toaster";
import {userRegistrationAction} from '../../Actions/User.action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {appConstants, getLocalDate, toUTC} from '../../../_Constants/app.constants';
import {timezones} from '../../../_Constants/timezones';
import GeoAddressComponent from '../../../_Common/GeoAddressManage';
import SideMenu from '../DashboardPage/SideMenu';
import NumberFormat from 'react-number-format';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import $ from 'jquery';

const AddClinicPtPage = ({history}) => {
	const dispatch = useDispatch();
	const [signupForm,setSignupForm] = useState({formType:'clinic'});
	const [signupErrors,setSignupErrors] = useState({});
  	const [dobValue, setDobValue] = useState();
	const [insuranceDate, setInsuranceDate] = useState();
	const [licenseDate, setLicenseDate] = useState();
	const [isGender, setIsGender] = useState(false);
	const [spinner,setSpinner] = useState(false);
	const { register, handleSubmit, setValue, errors, setError, reset } = useForm();
  	const [valueReset,setValueReset] = useState(true);
  	const [adsStatus,setAdsStatus] = useState(true);

	const inputHandler = (e,field)=>{
		let signupFormData = signupForm;
	    if(field === 'phone' && e.target.value.replace(/[-]/g, '').trim().length === 10){
	      $('input[name="phone"]').removeClass("requiredClass");
		}
		if(field == 'time_zone'){
			let timezoneValue = timezones.filter(
				function(timezones){return timezones.text == e.target.value}
			);      
			signupFormData['timeZone'] = JSON.stringify(timezoneValue[0]);
		}
		signupFormData[field] = e.target.value;
    	setSignupForm({...signupForm,...signupFormData});
	}

	const checkboxHandler = (e,field)=>{
		let signupFormData = signupForm;
		signupFormData[field] = e.target.checked===true?true:false;
		setSignupForm(signupFormData);
	}

	const radioHandler = (e,field)=>{
		let signupFormData = signupForm;
		signupFormData[e.target.name] = field;
		setSignupForm(signupFormData);
		setIsGender(true);
	}

	const fileChangedHandler=(event, elename)=>{
		event.preventDefault();
		let signupFormData = signupForm;
		let file = event.target.files[0];
	    if(!file && file===undefined){
	      signupFormData[elename+'Preview'] = '';
	      signupFormData[elename] = '';
	      setSignupForm({...signupForm,...signupFormData});
	      return false;
	    }
	    var fileName = (file && file.name?file.name:'');
		let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
		if (extensions == 'bmp' || extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg'){
		    signupFormData[elename+'Preview'] = URL.createObjectURL(event.target.files[0]);
		    signupFormData[elename] = event.target.files[0];
		    setSignupForm({...signupForm,...signupFormData});
		}else if(extensions == 'docx'|| extensions == 'doc'){
		    signupFormData[elename+'Preview'] = '/static/img/docIcon.png';
		    signupFormData[elename] = event.target.files[0];
		    setSignupForm({...signupForm,...signupFormData});
		}else if(extensions == 'pdf'){
		    signupFormData[elename+'Preview'] = '/static/img/pdfIcon.png';
		    signupFormData[elename] = event.target.files[0];
		    setSignupForm({...signupForm,...signupFormData});
		}else {
		  let err = [{"msg": "Extensions doesn't match","param": elename}];
		  setSignupErrors(FormError(err));
		}
	}

	const onFormSubmit = async () =>{
		setSpinner(true);
	    signupForm['clinicId'] = localStorage.getItem("clinicId")?localStorage.getItem("clinicId"):'';
	    signupForm['userType'] = 'PT';
	    signupForm['phone'] = signupForm['phone'].replace(/\D/g,'');
	   	signupForm['dob'] = dobValue?toUTC(getLocalDate(dobValue)):'';
	    signupForm['insuranceExpDate'] = insuranceDate?toUTC(getLocalDate(insuranceDate)):'';
	    signupForm['licenseExpDate'] = licenseDate?toUTC(getLocalDate(licenseDate)):'';
		signupForm['phoneCode'] = "+1";
		signupForm['timeZone'] = signupForm['timeZone'];
		
	    let res = {};
	    let err = {};
	    let formErrors = {};
      	res = await userRegistrationAction(signupForm);
      	if(res.err && res.err.length > 0){
        	formErrors = FormError(res.err);
      	}
	    setSignupErrors(formErrors);
	    if(res.success){
			history.push('/clinic-pt');
	    	setSpinner(false);
			toastr.show({message: "PT created successfully",intent: "success"});
	    }else if(res.success===false){
	    	setSpinner(false);
	    	toastr.show({message: res.msg,intent: "danger"});
	    }
	}

	const setResetForm = (e)=>{
		reset();
		setSignupForm({});
		setIsGender(false);
		setDobValue('');
		setLicenseDate('');
		setInsuranceDate('');
		setValueReset(false);
		setTimeout(function() {
			setValueReset(true)
		}, 1000);
	}

	return (
  	<div>
		<MetaTags className="bg-rr">
			<title>Create A PT Account</title>
			<meta name="description" content="Recover" />
			<meta name="keywords" content="Recover" />
		</MetaTags>
	    <section className="dash_section pad-reggis add_clinic_pt_page">
	        <BreadcrumbsComp bcChanges={[{path: '/add-clinic-pt', breadcrumb: 'Create A PT Account'}]} />
		    
		    <div className="container">
		        <div className="row">
		            <div className="col-md-3 left_dashboard">
		              <SideMenu/>
		            </div>
		            <div className="col-md-9 right_dashboard">
			            <div className="content-setion">
		               		<div className="section_title m-25"><h3>Create a PT account</h3></div>
					      	<form onSubmit={handleSubmit(onFormSubmit)} id='resetForm'>
						        <div className="row register_patient_row2" id='someId' >
							        <div className="col-lg-9">
							            <div className="row">
							              	<div className="col-lg-6">
								              	<label>First Name*</label>
								              	<div className="form-group">
													<input onChange={e=>inputHandler(e,'firstName')} name="firstName" type="text" 
														className="form-control" placeholder="First Name"
														ref={register({
															required: 'Required'
														})}
													/>
													<div className="text-danger">{ShowFormErrors(errors,signupErrors,"firstName")}</div>
								              	</div>
							              	</div>
							              	<div className="col-lg-6">
							              		<label>Last Name*</label>
							              		<div className="form-group">
									                <input onChange={e=>inputHandler(e,'lastName')} name="lastName" type="text" 
														className="form-control" placeholder="Last Name"
														ref={register({
															required: 'Required'
														})}
									                />
							              			<div className="text-danger">{ShowFormErrors(errors,signupErrors,"lastName")}</div>
							              		</div>
							              	</div>
											<div className="col-lg-6">
												<label>Date of Birth*</label>
												<div className="form-group">                      
													<DatePicker
														name='dob'
								                        selected={dobValue}
								                        maxDate={new Date()}
								                        className="form-control"
								                        dateFormat={appConstants.DateFormatNew}
								                        onChange={date => { setDobValue(date); setValue("dob", date);}}
								                        placeholderText="Click to select a Date of Birth"
					                    				ref={register({name: "dob"}, {required: 'Required'} )}
												        showMonthDropdown
									                    useShortMonthInDropdown
									                    showYearDropdown
									                    scrollableYearDropdown
									                    yearDropdownItemNumber={5}
								                    />
								                    {!dobValue &&
								                        (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"dob")}</div>)
								                    }
												</div>
											</div>
											<div className="col-lg-6">
												<label>Phone Number*</label>
												<div className="form-group phone_field">
													<select className="form-control" name="phoneCode">
														<option value="+1" defaultValue>+1</option>
													</select>
								                    <NumberFormat
								                      format="###-###-####"
								                      name="phone"
								                      //pattern="[0-9,-]{12}"
								                      className="form-control"
								                      placeholder="Phone Number Here"
								                      value={signupForm.phone?signupForm.phone:''}
								                      onChange={e =>{ inputHandler(e, 'phone'); setValue("phone", e.target.value);}}
								                      ref={register(!signupForm.phone?{name: "phone"}:'', 
								                        {required: 'Required',
								                        pattern: {value: /^(?=.*\d)[\d-]{12}$/, message: "Phone number should be 10 digit number."}
								                      } )}
								                    />
								                    {!signupForm.phone &&
								                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"phone")}</div>
								                    }
								                    {signupForm.phone && signupForm.phone.replace(/[-]/g, '').trim().length!==10 && 
								                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"phone")}</div>
								                    }
												</div>
											</div>
							              	<div className="col-lg-6">
							              		<label>Social Security Number*</label>
							              		<div className="form-group">
							                        <input type="text" onChange={e=>inputHandler(e,'socialSecurityNumber')} name="socialSecurityNumber" 
														className="form-control" placeholder="Social Security Number"
														ref={register({
															required: 'Required'
														})}
							                        />
							                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"socialSecurityNumber")}</div>
							                    </div>
							              	</div>
							              	<div className="col-lg-6">
							                    <label>Gender*</label>
							                    <div className="radio-bb">
							              			<FormGroup check>
							                			<Label check htmlFor='forMale'><input type="radio" className="clas-radio" id="forMale" 
							                				name="gender" onClick={e=>radioHandler(e,'male')}
							                           		ref={register({
							                  					required: isGender===false?'Required':'',
							                            	})}
							                			/> Male </Label>
							              			</FormGroup>
													<FormGroup check>
														<Label check htmlFor='forFemale'><Input type="radio" className="clas-radio" id="forFemale" 
															name="gender" onClick={e=>radioHandler(e,'female')}
															ref={register({
																required: isGender===false?'Required':'',
															})}
														/> Female </Label>
													</FormGroup>
													<FormGroup check>
														<Label check htmlFor='forOther'><Input type="radio" className="clas-radio" id="forOther" 
															name="gender" onClick={e=>radioHandler(e,'other')}
															ref={register({
																required: isGender===false?'Required':'',
															})}
														/> Other </Label>
													</FormGroup>
								                    {isGender===false &&
								                    	(<div className="text-danger">{ShowFormErrors(errors,signupErrors,"gender")}</div>)
								                    }
							                    </div>
							              	</div>
							              	<div className="col-lg-6">
							              		<label>Email*</label>
							              		<div className="form-group">
							                        <input onChange={e=>inputHandler(e,'email')} name="email" type="email" className="form-control" placeholder="Email"
							                            ref={register({
							                                required: 'Required',
							                                pattern: {
							                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
							                                  message: "Please enter a valid email address."
							                                }
							                            })}
							                        />
							                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"email")}</div>
							              		</div>
							              	</div>
							              	<div className="col-lg-6">
												<label>Password*</label>
												<div className="form-group">
							                        <input type="password" onChange={e=>inputHandler(e,'password')} name="password" 
							                          	className="form-control" placeholder="Password"
							                            ref={register({
							                              	required: 'Required',
									                        pattern: {
	                          									value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,15}$/,
					                            				message: "Minimum 6 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
									                        }
							                            })}
							                        />
							                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"password")}</div>
							              		</div>
							              	</div>
							              	<div className="col-lg-6">
							              		<label>Confirm Password*</label>
							              		<div className="form-group">
													<input type="password" onChange={e=>inputHandler(e,'cPassword')} name="cPassword" 
													  	className="form-control" placeholder="Confirm Password"
													  	ref={register({ 
													  		required: 'Required',
													  		validate: (value) => value === signupForm.password || "Passwords don't match.",
													  	})}
													  />
													<div className="text-danger">{ShowFormErrors(errors,signupErrors,"cPassword")}</div>
							              		</div>
							              	</div>
							              	{/*<div className="col-lg-12 docheading"> 
							                    <h4>Article of Incorporation</h4> 
							                </div>  
							              	<div className="col-lg-12 uploadfilesec">
							              		<label>Upload Article of Incorporation Doc*</label>
							                    <div className="form-group">
							                        <input type="file" name="npiDoc" accept=".jpg,.png,.jpeg,.pdf,.docx,.doc" 
							                            onChange={(e)=>fileChangedHandler(e, "npiDoc")}
							                            ref={register({
							                                required: 'Required'
							                            })} 
						                          	/>
						                          	<div className="text-danger">{ShowFormErrors(errors,signupErrors,"npiDoc")}</div>
							                    </div>
							                    { signupForm.npiDocPreview && 
								                   <div className="fileDocs"><img src={signupForm.npiDocPreview} alt="img"/></div>
								                }
							              	</div>
							              	<div className="col-lg-12 docheading"> 
							                    <h4>W9 with TIN</h4> 
							                </div>  
							              	<div className="col-lg-6">
												<label>Tax Identification Number*</label>
												<div className="form-group">
							                        <input type="text" onChange={e=>inputHandler(e,'w9Form')} name="w9Form" 
							                          className="form-control" placeholder="Tax Identification Number"
							                            ref={register({
							                              required: 'Required'
							                            })}
							                        />
							                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"w9Form")}</div>
							              		</div>
							              	</div>
											<div className="col-lg-6 uploadfilesec">
												<label>Upload W9 Doc*</label>
												<div className="form-group">
							                        <input type="file" name="w9FormDoc" accept=".jpg,.png,.jpeg,.pdf,.docx,.doc" 
							                            onChange={(e)=>fileChangedHandler(e, "w9FormDoc")}
							                            ref={register({
							                                required: 'Required'
							                            })} 
													/>
													<div className="text-danger">{ShowFormErrors(errors,signupErrors,"w9FormDoc")}</div>
							                    </div>
							                    { signupForm.w9FormDocPreview && 
								                   <div className="fileDocs"><img src={signupForm.w9FormDocPreview} alt="img"/></div>
								                }
							              	</div>*/}
							                <div className="col-lg-12 docheading"> 
							                    <h4>Liability Insurance (Clinic or Individual)</h4> 
							               	</div>          
											<div className="col-lg-4">
												<label>Policy Number*</label>
												<div className="form-group">
							                        <input type="text" onChange={e=>inputHandler(e,'insuranceNumber')} 
							                          name="insuranceNumber" className="form-control" placeholder="Policy Number"
							                            ref={register({
							                              required: 'Required'
							                            })}
							                        />
							                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"insuranceNumber")}</div>
							              		</div>
							              	</div>
								            <div className="col-lg-4">
								            	<label>Expiration Date*</label>
								              	<div className="form-group">
													<DatePicker
								                        minDate={new Date()}
														name='insuranceExpDate'
								                        className="form-control"
								                        selected={insuranceDate}
								                        dateFormat={appConstants.DateFormatNew}
									                    onChange={date => { setInsuranceDate(date); setValue("insuranceExpDate", date);}}
								                        placeholderText="Click to select Expiration Date"
									                    ref={register({name: "insuranceExpDate"}, {required: 'Required'} )}
								                        showMonthDropdown
									                    useShortMonthInDropdown
									                    showYearDropdown
								                    />
								                    {!insuranceDate &&
								                        (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"insuranceExpDate")}</div>)
								                    }
							              		</div>
							              	</div>
							              	<div className="col-lg-4 uploadfilesec">
							              		<label>Upload Insurance Doc*</label>
							              		<div className="form-group">
							                        <input type="file" name="insuranceDoc" accept=".jpg,.png,.jpeg,.pdf,.docx,.doc" 
							                            onChange={(e)=>fileChangedHandler(e, "insuranceDoc")}
							                            ref={register({
							                                required: 'Required'
							                            })} 
							                        />
							                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"insuranceDoc")}</div>
							                    </div>
							                    { signupForm.insuranceDocPreview && 
								                   <div className="fileDocs"><img src={signupForm.insuranceDocPreview} alt="img"/></div>
								                }
							              	</div>
											<div className="col-lg-12 docheading"> 
												<h4>Physical Therapy License</h4> 
											</div>          
							              	<div className="col-lg-4">
							              		<label>License Number*</label>
							              		<div className="form-group">
							                        <input type="text" onChange={e=>inputHandler(e,'licenseNumber')} 
							                          name="licenseNumber" className="form-control" placeholder="License Number"
							                            ref={register({
							                              required: 'Required'
							                            })}
							                        />
							                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"licenseNumber")}</div>
							              		</div>
							              	</div>
							              	<div className="col-lg-4">
							              		<label>Expiration Date*</label>
							              		<div className="form-group">
													<DatePicker
								                        minDate={new Date()}
														name='licenseExpDate'
								                        selected={licenseDate}
								                        className="form-control"
								                        dateFormat={appConstants.DateFormatNew}
								                        placeholderText="Click to select Expiration Date"
								                        onChange={date => { setLicenseDate(date); setValue("licenseExpDate", date);}}
					                    				ref={register({name: "licenseExpDate"}, {required: 'Required'} )}
								                        showMonthDropdown
									                    useShortMonthInDropdown
									                    showYearDropdown
								                    />
								                    {!licenseDate &&
								                        (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"licenseExpDate")}</div>)
								                    }
							              		</div>
							              	</div>
							              	<div className="col-lg-4 uploadfilesec">
							              		<label>Upload License Doc*</label>
							              		<div className="form-group">
							                        <input type="file" name="licenseDoc" accept=".jpg,.png,.jpeg,.pdf,.docx,.doc" 
							                            onChange={(e)=>fileChangedHandler(e, "licenseDoc")}
							                            ref={register({
							                                required: 'Required'
							                            })} 
							                        />
							                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"licenseDoc")}</div>
							                    </div>
							                    {signupForm.licenseDocPreview && 
								                   <div className="fileDocs"><img src={signupForm.licenseDocPreview} alt="img"/></div>
								                }
							              	</div>

							                <GeoAddressComponent
							                  history={history}
							                  register={register}
							                  errors={errors}
							                  formData={signupForm}
							                  inputHandler={inputHandler}
							                  signupErrors={signupErrors}
							                  valueReset={valueReset}
							                />

											<div className="col-lg-12">
												<div className="checkbbox">
													<label htmlFor="termsConditions">
														<input onChange={e=>checkboxHandler(e,'termsConditions')} id="termsConditions" type="checkbox" defaultChecked=""name='termsConditions'
															ref={register({ required: 'Required' })}/>
														<p>Terms & Conditions (<a href="/terms-condition" target="_blank">Click here</a> to read)</p>
													</label>
													<div className="text-danger">{ShowFormErrors(errors,signupErrors,"termsConditions")}</div>
												</div>
												<div className="checkbbox">
													<label htmlFor="informedConsent">
														<input onChange={e=>checkboxHandler(e,'informedConsent')} id="informedConsent" type="checkbox" defaultChecked=""name='informedConsent'
															ref={register({ required: 'Required' })}/>
														<p>Informed Consent (<a href="/informed-consent" target="_blank">Click here </a> to read)</p>
													</label>
													<div className="text-danger">{ShowFormErrors(errors,signupErrors,"informedConsent")}</div>
												</div>
												<div className="checkbbox">
													<label htmlFor="privacyPolicy">
														<input onChange={e=>checkboxHandler(e,'privacyPolicy')} id="privacyPolicy" type="checkbox" defaultChecked="" name='privacyPolicy'
															ref={register({ required: 'Required' })}/>
														<p>Privacy Policy (<a href="/privacy-policy" target="_blank">Click here </a> to read)</p>
													</label>
													<div className="text-danger">{ShowFormErrors(errors,signupErrors,"privacyPolicy")}</div>
												</div>              
								              	<div className="checkbbox">
									              	<label htmlFor="medBridgeAccount">
									                	<input onChange={e=>checkboxHandler(e,'medBridgeAccount')} id="medBridgeAccount" type="checkbox" defaultChecked="" name='medBridgeAccount'
															ref={register({ required: 'Required' })}/>
									                	<p>MedBridge Account (<a href="https://www.medbridgeeducation.com" target="_blank">Click here </a> to read)</p>
									              	</label>
									              	<div className="text-danger">{ShowFormErrors(errors,signupErrors,"medBridgeAccount")}</div>
							              		</div>
							              	</div>
							              	<div className="col-lg-6 t-pp">
							              		<input type="reset" onClick={e=>setResetForm(e)} className="btn btn-primary rt" value="Clear" />
							              		<input type="submit" className="btn btn-primary" value="Submit" />
							              	</div>
							            </div>
							        </div>
						          	<div className="col-lg-3 text-center">
							            <div className="bg-cirpatient">
								            <div className="prof_section">
								                <label >
													{signupForm.profileImgPreview && signupForm.profileImgPreview !=='' &&
														<img src={signupForm.profileImgPreview} alt="img"/>
													}
													{signupForm.profileImg && signupForm.profileImg !=='' && !signupForm.profileImgPreview &&
														<img src={`${process.env.REACT_APP_STATIC_S3_URL+signupForm.profileImg}`} alt="img"/>
													}
													{ !signupForm.profileImg && !signupForm.profileImgPreview &&
														<img src="static/img/icon-profiles.png" alt="img"/>
													}
													<input type="file" name="profileImg" accept=".jpg,.png,.jpeg" onChange={(e)=>fileChangedHandler(e, "profileImg")} style={{display: 'none'}} ref={register({ required: 'Required' })}/>
								                </label>
								                <br/><br/>
								            </div>
								            <div className="text-danger">{ShowFormErrors(errors,signupErrors,"profileImg")}</div>
							              	
							              	<div className="userid">
							                	<p>Clinic Code: {localStorage.getItem("clinicId")?localStorage.getItem("clinicId"):''}</p>
							              	</div>
							            </div>
						          	</div>
						        </div>
					      	</form>
			    		</div>
			    	</div>
		    	</div>
		    </div>
	    </section>
    	<div className="spinner" style={{display:spinner?'block':'none'}}>
        	<img src="static/img/spinner.gif" alt='loader'/>
      	</div>
   	</div>
	);
}
export default withLocalize(AddClinicPtPage);