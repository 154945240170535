import React,{useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import ClinicPageComponent from './ClinicPage';
import PatientPageComponent from './PatientPage';
import PtPageComponent from './PtPage';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';

const Register = ({history}) => {
  if(history.location && history.location.state===undefined || history.location.state===''){
      history.push('/');
  }
  useEffect(()=>{
      document.documentElement.scrollTop = 0;
  },[]);

  return (
    <div className="slide-er">
      <MetaTags className="bg-rr">
        <title>Registration</title>
        <meta name="description" content="Recover" />
        <meta name="keywords" content="Recover" />
      </MetaTags> 

      <section className="pad-reggis">
        <BreadcrumbsComp bcChanges={[{path: '/register', breadcrumb:'Registration' }]} />
        
        <div className="container">
          {history.location && history.location.state && history.location.state.registerType==='Clinic' &&
            <ClinicPageComponent history={history}/>
          }      

          {history.location && history.location.state && history.location.state.registerType==='Patient' &&
            <PatientPageComponent history={history}/>
          }      

          {history.location && history.location.state && history.location.state.registerType==='PT' &&
            <PtPageComponent history={history}/>
          }
        </div>
      </section>
   </div>
  );
}
export default withLocalize(withRouter(Register));
