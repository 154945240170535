import React,{useEffect} from 'react';
import { Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import moment from 'moment';
import {appConstants} from '../_Constants/app.constants';


const BreadcrumbsPage = ({history, bcChanges, bcNewItems}) => {
  const breadcrumbs = useBreadcrumbs(bcChanges && bcChanges.length > 0 ? bcChanges:'');

  var notificationMsg = '';
  var licenseExpiration = '';
  var insuranceExpiration = '';
  if(localStorage.getItem("RecoverWebStatus")==='true' && (localStorage.getItem("userType")==='PT' || localStorage.getItem("userType")==='CLINIC')){
    let currentDateStr = new Date();
    if(localStorage.getItem("licenseExpDate") && localStorage.getItem("licenseExpDate")!==''){
      licenseExpiration = new Date(localStorage.getItem("licenseExpDate"));
      licenseExpiration = licenseExpiration.setMonth(licenseExpiration.getMonth() -1);
      licenseExpiration = (licenseExpiration < currentDateStr ? licenseExpiration :'');
    }    
    if(localStorage.getItem("insuranceExpDate") && localStorage.getItem("insuranceExpDate")!==''){
      insuranceExpiration = new Date(localStorage.getItem("insuranceExpDate"));
      insuranceExpiration = insuranceExpiration.setMonth(insuranceExpiration.getMonth() -1);
      insuranceExpiration = (insuranceExpiration < currentDateStr ? insuranceExpiration :'');
    }

    if(licenseExpiration){
      let msgStatus = (new Date(localStorage.getItem("licenseExpDate")) < currentDateStr ? 'was expired':'will expire');
      let licDate = moment(localStorage.getItem("licenseExpDate"), "YYYY-MM-DD").format(appConstants.DATEFORMAT);
      notificationMsg = "Your Physical Therapy License Policy "+msgStatus+" on "+licDate+", please renew the Insurance soon for better convenience.";
    }if(insuranceExpiration){
      let msgStatus = (new Date(localStorage.getItem("insuranceExpDate")) < currentDateStr ? 'was expired':'will expire');
      let insDate = moment(localStorage.getItem("insuranceExpDate"), "YYYY-MM-DD").format(appConstants.DATEFORMAT);
      notificationMsg = "Your Liability Insurance "+msgStatus+" on "+insDate+", please renew the Insurance soon for better convenience.";
    }if(insuranceExpiration && licenseExpiration){
      let msgStatus1 = (new Date(localStorage.getItem("licenseExpDate")) < currentDateStr ? 'was expired':'will expire');
      let msgStatus2 = (new Date(localStorage.getItem("insuranceExpDate")) < currentDateStr ? 'was expired':'will expire');
      let licDate = moment(localStorage.getItem("licenseExpDate"), "YYYY-MM-DD").format(appConstants.DATEFORMAT);
      let insDate = moment(localStorage.getItem("insuranceExpDate"), "YYYY-MM-DD").format(appConstants.DATEFORMAT);
      notificationMsg = "Your Physical Therapy License Policy "+msgStatus1+" on "+licDate+", please renew the Insurance soon for better convenience.";
      notificationMsg+= "  |  Your Liability Insurance "+msgStatus2+" on "+insDate+", please renew the Insurance soon for better convenience.";
    }
  }
  useEffect(()=>{      
      setInterval(()=>{
        if(document.getElementById('dateTime')){
            
            document.getElementById('dateTime').innerText = moment().format('MMMM Do, YYYY h:mm:ss A')+(localStorage.getItem("abbr")?'('+localStorage.getItem("abbr")+')':'');            
        }        
      },1000);
  },[]);

  return (
    <div className='topBarSection'>
      {notificationMsg &&
        <div className="Notificationbar">
          <marquee>{notificationMsg}</marquee>
        </div>
      }

      {breadcrumbs &&
        <div className="container">
          <div className="breadcrumbs">
            {bcNewItems && bcNewItems.map((val, index)=>{
              return(<span key={index}>
                {bcNewItems && bcNewItems.length > index+1 ?
                  (<span><Link to={val.path}>{val.breadcrumb}</Link> &gt; </span>):
                  (<span>{val.breadcrumb}</span>)
                }
              </span>)
            })}

            {!bcNewItems && breadcrumbs && breadcrumbs.map(({match,breadcrumb}, index) => (
              <span key={index}>
                {breadcrumbs && breadcrumbs.length > index+1 ?
                  (<span><Link to={match.url}>{breadcrumb}</Link> &gt; </span>):
                  (<span>{breadcrumb}</span>)
                }
              </span>
            ))}
            <span className="pull-right" id="dateTime"></span>
          </div>
        </div>
      }
      
    </div>
  );
}
export default withLocalize(BreadcrumbsPage);
